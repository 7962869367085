import _ from 'lodash';
import React from 'react';
import {
  getAuth,
} from 'firebase/auth';
import {
  getDatabase,
  ref,
  onValue,
  update,
  push,
  child,
  query as dbQuery,
  orderByChild,
  startAt,
  endAt,
  equalTo,
  serverTimestamp as dbServerTimestamp,
  get,
} from 'firebase/database';
import {
  getFirestore,
  doc,
  setDoc,
  getDocsFromServer,
  collection,
  deleteDoc,
  updateDoc,
  serverTimestamp as fsServerTimestamp,
} from 'firebase/firestore';
import {
  getAnalytics,
  logEvent,
} from 'firebase/analytics';
import moment from 'moment-timezone';
import { eventChannel, channel } from 'redux-saga';
import axios from 'axios';
import {
  all,
  takeEvery,
  takeLatest,
  fork,
  put,
  take,
  call,
  select,
  spawn,
} from 'redux-saga/effects';
import { Button, notification as notificationAntd } from 'antd';
import {
  datetime,
  RRule,
  rrulestr,
} from 'rrule';
import { notification } from '../../components';
import actions from './actions';
import appActions from '../app/actions';
import profileActions from '../profile/actions';
import calendarActions from '../calendar/actions';
import contactActions from '../contacts/actions';
import gapiActions from '../gapi/actions';
import waitListActions from '../waitList/actions';
import normalizeData from '../../helpers/buildFreeSchedule';
import { sanitizeStringForRrule, PORTUGUESE, tokensPT } from '../../helpers/utility';

const ROOT_URL = process.env.REACT_APP_CLOUD_FUNCTIONS_ROOT_URL;

const defaultAgendaLabels = {
  id0: {
    name: 'Agendado',
    color: '#989896',
    default: true,
  },
  id1: {
    name: 'Confirmado',
    color: '#5789FB',
    default: true,
  },
  id2: {
    name: 'Sala de espera',
    color: '#ffa200',
    default: true,
  },
  id3: {
    name: 'Atendendo',
    color: '#00C7FF',
    default: true,
  },
  id4: {
    name: 'Finalizado',
    color: '#7fbf00',
    default: true,
  },
  id5: {
    name: 'Faltou',
    color: '#ff3333',
    default: true,
  },
  id6: {
    name: 'Cirurgia',
    color: '#ae61a0',
    default: true,
  },
};

const gettextRrule = (id) => tokensPT[id] || id;

function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

function generateAppointmentsNumber(rules, time, mode = []) {
  const newRules = _.cloneDeep(rules);
  if (time) {
    const momentTime = moment(time, 'HH:mm');
    newRules.forEach((rule, index) => {
      let nAppointments = 0;
      const { morningBegin, morningEnd } = rule;
      const durationMin = momentTime.diff(moment('00:00', 'HH:mm'), 'minutes');
      if (morningBegin.length > 0 && morningEnd.length > 0) {
        const momentBegin = moment(morningBegin, 'HH:mm');
        const momentEnd = moment(morningEnd, 'HH:mm');
        const diff = momentEnd.diff(momentBegin, 'minutes');
        nAppointments += Math.floor(diff / durationMin);
      }
      const { afternoonBegin, afternoonEnd } = rule;
      if (afternoonBegin.length > 0 && afternoonEnd.length > 0) {
        const momentBegin = moment(afternoonBegin, 'HH:mm');
        const momentEnd = moment(afternoonEnd, 'HH:mm');
        const diff = momentEnd.diff(momentBegin, 'minutes');
        nAppointments += Math.floor(diff / durationMin);
      }
      newRules[index].appointmentsTotal = nAppointments;
      if (mode.length === 2) {
        newRules[index].appointmentsRemaining = nAppointments;
        newRules[index].private = 0;
        newRules[index].plan = 0;
      } else if (mode.length === 1) {
        newRules[index].appointmentsRemaining = 0;
        if (mode[0] === 'private') {
          newRules[index].private = nAppointments;
          newRules[index].plan = 0;
        } else {
          newRules[index].plan = nAppointments;
          newRules[index].private = 0;
        }
      } else {
        // Caso seja -2,-1,0 ou 3,4,5...
        newRules[index].appointmentsRemaining = nAppointments;
        newRules[index].private = 0;
        newRules[index].plan = 0;
      }
    });
  }
  return newRules;
}

const getUnifiedTokenStore = (state) => state.Auth.unified;

const getMainUserFromStore = (state) => state.Auth.mainUser;

const getProfessionalFromStore = (state) => state.Auth.professional;

const getConfirmedFromStore = (state) => state.Agenda.confirmed;

const getPastConfirmedFromStore = (state) => state.Agenda.pastConfirmed;

const getCanceledFromStore = (state) => state.Agenda.canceled;

const getPastCanceledFromStore = (state) => state.Agenda.pastCanceled;

const getRequestsFromStore = (state) => state.Agenda.pending;

const getPastAgendaListenerParamsFromStore = (state) => state.Agenda.pastAgendaListenerParams;

const getAllPendingRequestsFromStore = (state) => state.Agenda.allPendings;

const getAgendasArrFromStore = (state) => state.Agenda.agendasArr;

const getSelectedAgendaFromStore = (state) => state.Agenda.selectedAgenda;

const getSelectedAddressFromStore = (state) => state.App.selectedAddress;

const getProfileFromStore = (state) => state.Profile.profile;

const getPatientsFromStore = (state) => state.Contacts.patients;

const getSelectedDataFromStore = (state) => state.Calendar.selectedData;

const getCustomUsersFromStore = (state) => state.CustomUsers.customUsers;

const getGisUserProfileFromStore = (state) => state.Gapi.gisUserProfile;

const getWaitListFromStore = (state) => state.WaitList.waitList;

const getWaitListSlotArrFromStore = (state) => state.WaitList.waitListSlotArr;

const getCurrentAgendaStartDateListenerFromStore = (state) => state.Agenda.currentAgendaStartDateListener;

const getBatchedStartEndDatesFromStore = (state) => state.Agenda.batchedStartEndDates;

const getBatchedAppointmentsFromStore = (state) => state.Agenda.batchedAppointments;

const getFullBatchedAppointmentsArrFromStore = (state) => state.Agenda.fullBatchedAppointmentsArr;

const getPersistedBatchedAppointmentsFromStore = (state) => state.Agenda.persistedBatchedAppointments;

const getAppoitmentHistoryFromStore = (state) => state.Contacts.appoitmentHistory;

function accessTokenResult() {
  const auth = getAuth();
  const { currentUser } = auth;
  return currentUser.getIdTokenResult();
}

const getFullMessagesSettingsFromStore = (state) => state.Messages.fullMessagesSettings;

const googleAuthRequestChannel = channel();

function* watchGoogleAuthRequestChannel() {
  while (true) {
    const action = yield take(googleAuthRequestChannel);
    yield put(action);
  }
}

const waitListChannel = channel();

function* watchWaitListChannel() {
  while (true) {
    const action = yield take(waitListChannel);
    yield put(action);
  }
}

export function* buildFreeSchedule() {
  yield takeLatest([
    actions.CONFIRMED_FETCH_SUCCESS,
    actions.PENDING_FETCH_SUCCESS,
    actions.SET_FULL_BATCHED_APPOINTMENTS_ARR,
    actions.FETCH_AGENDAS_FROM_FIRESTORE_SUCCESS,
    actions.SELECT_AGENDA,
    waitListActions.REQUEST_WAIT_LIST_SUCCED,
  ], function* () {
    try {
      notificationAntd.destroy('waitList-notification');
      let confirmed = yield select(getConfirmedFromStore);
      if (!confirmed) {
        yield take(actions.CONFIRMED_FETCH_SUCCESS);
        confirmed = yield select(getConfirmedFromStore);
      }
      let pending = yield select(getRequestsFromStore);
      if (!pending) {
        yield take(actions.PENDING_FETCH_SUCCESS);
        pending = yield select(getRequestsFromStore);
      }
      let agendasArr = yield select(getAgendasArrFromStore);
      while (!agendasArr) {
        yield take(actions.FETCH_AGENDAS_FROM_FIRESTORE_SUCCESS);
        agendasArr = yield select(getAgendasArrFromStore);
      }
      let selectedAgenda = yield select(getSelectedAgendaFromStore);
      while (!selectedAgenda) {
        yield take(actions.SELECT_AGENDA);
        selectedAgenda = yield select(getSelectedAgendaFromStore);
      }
      const fullBatchedAppointmentsArr = yield select(getFullBatchedAppointmentsArrFromStore);
      const foundAgendaSettings = agendasArr.find((el) => el.id === selectedAgenda);
      if (foundAgendaSettings?.scheduleSettings) {
        const {
          firstDay,
          fullSchedule,
          finalSchedule,
          busySchedule,
          finalModeAvailable,
          gapSchedule,
        } = normalizeData(
          confirmed,
          pending,
          fullBatchedAppointmentsArr,
          foundAgendaSettings.scheduleSettings.rules,
          moment().tz('America/Sao_Paulo').format('YYYY-MM-DD'),
          foundAgendaSettings.scheduleSettings.duration,
          foundAgendaSettings.scheduleSettings.mode,
        );
        const waitList = yield select(getWaitListFromStore);
        const waitListSlotArrFromStore = yield select(getWaitListSlotArrFromStore);
        // let waitListSlot = null;
        const waitListSlotArr = [];
        if (waitList?.[selectedAgenda]?.patients) {
          const checkWeekdays = (preferences, date) => {
            if (preferences.weekdays?.length > 0) {
              const weekDay = moment(date, 'YYYY-MM-DD').format('ddd');
              // if (weekDay === 'sáb') {
              //   weekDay = 'sab';
              // }
              return preferences.weekdays.some((x) => x === weekDay);
            }
            return true;
          };
          const orderedArr = _.orderBy(waitList[selectedAgenda].patients, ['date']);
          const currentDate = moment().tz('America/Sao_Paulo').format('YYYY-MM-DD');
          orderedArr.forEach((item) => {
            let waitListSlot = null;
            if (!item.preferences && item.useAutomation) {
              Object.keys(finalSchedule).some((date) => {
                if (date >= currentDate) {
                  if (finalSchedule[date].length > 0) {
                    waitListSlot = {
                      start: moment(`${finalSchedule[date][0].day} ${finalSchedule[date][0].time}`, 'YYYY-MM-DD HH:mm').toDate(),
                      end: moment(`${finalSchedule[date][0].day} ${finalSchedule[date][0].end}`, 'YYYY-MM-DD HH:mm').toDate(),
                      day: date,
                      waitListSlot: true,
                      waitListInfo: { ...item },
                    };
                  } else if (gapSchedule[date]?.length > 0) {
                    waitListSlot = {
                      start: moment(`${date} ${gapSchedule[date][0].time}`, 'YYYY-MM-DD HH:mm').toDate(),
                      end: moment(`${date} ${gapSchedule[date][0].time}`, 'YYYY-MM-DD HH:mm').add(gapSchedule[date][0].duration, 'm').toDate(),
                      day: date,
                      waitListSlot: true,
                      waitListInfo: { ...item },
                    };
                  }
                }
                if (waitListSlot) {
                  waitListSlotArr.push({ ...waitListSlot });
                  return true;
                }
                return false;
              });
            } else if (item.useAutomation) {
              // There is preferences set
              const preferences = { ...item.preferences };
              Object.keys(finalSchedule).some((date) => {
                if (date >= currentDate) {
                  if (finalSchedule[date].length > 0) {
                    finalSchedule[date].some((slot) => {
                      if (date >= preferences.startAtDay && date <= preferences.stopAtDay && checkWeekdays(preferences, date)) {
                        if (slot.time <= '12:59') {
                          if (preferences.period?.includes('morning')) {
                            waitListSlot = {
                              start: moment(`${date} ${slot.time}`, 'YYYY-MM-DD HH:mm').toDate(),
                              end: moment(`${date} ${slot.end}`, 'YYYY-MM-DD HH:mm').toDate(),
                              day: date,
                              waitListSlot: true,
                              waitListInfo: { ...item },
                            };
                          }
                        }
                        if (slot.time >= '13:00') {
                          if (preferences.period?.includes('afternoon')) {
                            waitListSlot = {
                              start: moment(`${date} ${slot.time}`, 'YYYY-MM-DD HH:mm').toDate(),
                              end: moment(`${date} ${slot.end}`, 'YYYY-MM-DD HH:mm').toDate(),
                              day: date,
                              waitListSlot: true,
                              waitListInfo: { ...item },
                            };
                          }
                        }
                      }
                      if (waitListSlot) {
                        return true;
                      }
                      return false;
                    });
                  } else if (gapSchedule[date]?.length > 0) {
                    gapSchedule[date].some((slot) => {
                      if (preferences.startAtDay >= date && checkWeekdays(preferences, date)) {
                        if (slot.time <= '12:59') {
                          if (preferences.period?.includes('morning')) {
                            waitListSlot = {
                              start: moment(`${date} ${slot.time}`, 'YYYY-MM-DD HH:mm').toDate(),
                              end: moment(`${date} ${slot.time}`, 'YYYY-MM-DD HH:mm').add(slot.duration, 'm').toDate(),
                              day: date,
                              waitListSlot: true,
                              waitListInfo: { ...item },
                            };
                          }
                        }
                        if (slot.time >= '13:00') {
                          if (preferences.period?.includes('afternoon')) {
                            waitListSlot = {
                              start: moment(`${date} ${slot.time}`, 'YYYY-MM-DD HH:mm').toDate(),
                              end: moment(`${date} ${slot.time}`, 'YYYY-MM-DD HH:mm').add(slot.duration, 'm').toDate(),
                              day: date,
                              waitListSlot: true,
                              waitListInfo: { ...item },
                            };
                          }
                        }
                      }
                      if (waitListSlot) {
                        return true;
                      }
                      return false;
                    });
                  }
                }
                if (waitListSlot) {
                  waitListSlotArr.push({ ...waitListSlot });
                  return true;
                }
                return false;
              });
            }
          });
        }
        if (waitListSlotArr.length > 0 && !_.isEqual(waitListSlotArrFromStore, waitListSlotArr)) {
          notificationAntd.info({
            message: 'Há um ou mais pacientes elegíveis para agendamento na Lista de Espera',
            description: (
              <span>
                <Button
                  type="link"
                  style={{ padding: 0, height: '21px' }}
                  onClick={() => {
                    waitListChannel.put({
                      type: waitListActions.SET_OPEN_WAIT_LIST_COMPONENT,
                      payload: { value: true },
                    });
                    notificationAntd.destroy('waitList-notification');
                  }}
                >
                  Clique aqui para abrir a Lista de Espera
                </Button>
              </span>
            ),
            // duration: null,
            key: 'waitList-notification',
          });
        }
        yield put({
          type: waitListActions.SET_WAIT_LIST_SLOT,
          payload: {
            // slot: waitListSlot,
            slotArr: waitListSlotArr,
          },
        });
        yield put({
          type: actions.BUILD_FREE_SCHEDULE_SUCCESS,
          payload: {
            firstDay,
            freeSchedule: fullSchedule, // Shows all default schedules (ignore busy timeslots)
            finalSchedule, // Shows all default available schedules (disconsider busy timeslots)
            onlyBusySchedule: busySchedule,
            modesAvailable: finalModeAvailable,
            gapSchedule,
          },
        });
      }
    } catch (error) {
      console.warn(error);
    }
  });
}

export function* setSelectedAgendaOnLocalStorage() {
  yield takeEvery(actions.SELECT_AGENDA, function* (action) {
    try {
      // yield localStorage.removeItem('selected_agenda');
      yield localStorage.setItem('selected_agenda', action.payload);
    } catch (error) {
      console.warn(error);
    }
  });
}

function getOldRulesFromDB(addressKey, mainUser) {
  const db = getDatabase();
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  return new Promise((resolve) => {
    onValue(ref(db, `rules/${uid}/${addressKey}`), resolve, { onlyOnce: true });
  });
}

function fetchAgendasFromDB(professional, addressId, mainUser) {
  const fs = getFirestore();
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  if (professional && addressId) {
    const colRef = collection(
      fs,
      'professionals',
      uid,
      'addresses',
      addressId,
      'agendas',
    );
    return getDocsFromServer(colRef);
  }
  return false;
}

function saveAgendaOnFirestore(agendaInfo, addressId, agendaKey = null, mainUser, itIsUpdate = false) {
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const fs = getFirestore();
  let agendaRef;
  const colRef = collection(
    fs,
    'professionals',
    uid,
    'addresses',
    addressId,
    'agendas',
  );
  if (agendaKey) {
    agendaRef = doc(colRef, agendaKey);
  } else {
    agendaRef = doc(colRef);
  }
  if (itIsUpdate) {
    return updateDoc(agendaRef, agendaInfo);
  }
  return setDoc(
    agendaRef,
    {
      ...agendaInfo,
      timestamp: fsServerTimestamp(),
    },
    { merge: true },
  );
}

export function* fetchAgendasRequest() {
  yield takeLatest([
    appActions.SELECT_ADDRESS,
    actions.FETCH_AGENDAS_FROM_FIRESTORE_REQUEST,
  ], function* (action) {
    try {
      yield put({ type: actions.FETCHING_AGENDAS });
      const professional = yield select(getProfessionalFromStore);
      const mainUser = yield select(getMainUserFromStore);
      const addressUid = yield select(getSelectedAddressFromStore);
      const agendas = yield call(fetchAgendasFromDB, professional, addressUid, mainUser);
      if (agendas.empty) {
        // No agenda set yet.
        const agendaInfo = {
          name: 'Agenda Padrão',
          active: true,
          default: true,
        };
        const rulesFromDB = yield call(getOldRulesFromDB, addressUid, mainUser);
        if (rulesFromDB.val()) {
          agendaInfo.scheduleSettings = { ...rulesFromDB.val() };
        }
        // Saving current address id as the default agenda doc.
        yield call(
          saveAgendaOnFirestore,
          agendaInfo,
          addressUid,
          addressUid, // agendaKey
          mainUser,
        );
        yield put({
          type: actions.FETCH_AGENDAS_FROM_FIRESTORE_REQUEST,
        });
      } else {
        const agendasArr = [];
        agendas.forEach((document) => {
          const data = document.data();
          const agendaObj = {
            ...data,
            id: document.id,
          };
          if (!data.labels) {
            agendaObj.labels = { ...defaultAgendaLabels };
          }
          if (agendaObj.scheduleSettings && !agendaObj.scheduleSettings.mode) {
            agendaObj.scheduleSettings.mode = {};
          }
          if (agendaObj.scheduleSettings && (!agendaObj.scheduleSettings.duration || agendaObj.scheduleSettings.duration === '00:00')) {
            agendaObj.scheduleSettings.duration = '00:15';
          }
          if (agendaObj.scheduleSettings && !agendaObj.scheduleSettings.onlineSchedulesBlocked) {
            agendaObj.scheduleSettings.onlineSchedulesBlocked = {};
          }
          if (agendaObj.scheduleSettings && !agendaObj.scheduleSettings.onlinePlans) {
            agendaObj.scheduleSettings.onlinePlans = [];
          }
          agendasArr.push(agendaObj);
        });
        yield put({
          type: actions.FETCH_AGENDAS_FROM_FIRESTORE_SUCCESS,
          payload: agendasArr,
        });
        const agendaId = yield select(getSelectedAgendaFromStore);
        const auth = getAuth();
        const { currentUser } = auth;
        // const defaultAgenda = agendasArr.find((el) => el.default);
        let defaultAgenda = agendasArr.find((el) => {
          if (el.users?.length === 1 && el.users[0].id === currentUser.uid) {
            return true;
          }
          return el.default;
        });
        const selectedAgendaLocalStorage = yield localStorage.getItem('selected_agenda');
        if (selectedAgendaLocalStorage) {
          const foundSelectedAgendaStorage = agendasArr.find((el) => el.id === selectedAgendaLocalStorage);
          if (foundSelectedAgendaStorage) {
            defaultAgenda = foundSelectedAgendaStorage;
          }
        }
        if (agendaId && agendaId === defaultAgenda) {
          yield put({
            type: actions.RESET_AGENDA,
          });
        }
        const selectedAgenda = yield select(getSelectedAgendaFromStore);
        if (action.type === 'SELECT_ADDRESS' && defaultAgenda) {
          yield put({
            type: actions.SELECT_AGENDA,
            payload: defaultAgenda.id,
          });
        } else if (defaultAgenda && !selectedAgenda) {
          yield put({
            type: actions.SELECT_AGENDA,
            payload: defaultAgenda.id,
          });
        }
      }
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.FETCH_AGENDAS_FROM_FIRESTORE_ERROR,
      });
    }
  });
}

function normalizeRules(rules, duration, mode, itIsUpdate = false) {
  let filteredRules = rules.filter((rule) => {
    if (!rule.morningBegin
      && !rule.morningEnd
      && !rule.afternoonBegin
      && !rule.afternoonEnd) {
      return false;
    }
    return true;
  });
  if (duration && mode.length > 0 && !itIsUpdate) {
    // Updating the new rules with duration/appointment mode data
    const newRules = generateAppointmentsNumber(filteredRules, duration, mode);
    filteredRules = [...newRules];
  }
  const editedRules = filteredRules.reduce((acc, { id, ...obj }) => {
    acc[id] = obj;
    return acc;
  }, {});
  return editedRules;
}

function normalizeAppointmentMode(mode) {
  const editedAppointmentMode = mode.reduce((acc, v) => {
    acc[v] = true;
    return acc;
  }, {});
  return editedAppointmentMode;
}

export function* saveAgendaSettings() {
  yield takeEvery(actions.SAVE_AGENDA_SETTINGS_REQUEST, function* (action) {
    try {
      yield put({ type: actions.SAVING_AGENDA_SETTINGS });
      const idTokenResult = yield call(accessTokenResult);
      const mainUser = idTokenResult.claims.mainUser
        ? idTokenResult.claims.mainUser
        : null;
      const addressUid = yield select(getSelectedAddressFromStore);
      const agendasArr = yield select(getAgendasArrFromStore);
      const {
        agendaKey,
        agendaObj,
      } = action.payload;
      const foundAgenda = agendasArr.find((el) => el.id === agendaKey);
      if (foundAgenda) {
        delete foundAgenda.id;
      }
      const normalizedRules = yield call(
        normalizeRules,
        agendaObj.rules,
        agendaObj.duration,
        agendaObj.mode,
        agendaKey || false, // itIsUpdate, if it has agendaKey -> it is a update
      );
      const normalizedMode = yield call(normalizeAppointmentMode, agendaObj.mode);
      const agendaInfo = {
        name: agendaObj.name,
        active: agendaObj.active,
        color: _.isFinite(agendaObj.color) ? agendaObj.color : '',
        users: agendaObj.users || [],
        activeOnlineAppointments: agendaObj.activeOnlineAppointments || false,
        scheduleSettings: {
          rules: normalizedRules,
          mode: normalizedMode,
          duration: agendaObj.duration,
          onlineSchedulesBlocked: agendaObj.onlineSchedulesBlocked || {},
          onlinePlans: agendaObj.onlinePlans || [],
        },
      };
      yield call(
        saveAgendaOnFirestore,
        { ...foundAgenda, ...agendaInfo },
        addressUid,
        agendaKey,
        mainUser,
        agendaKey || false, // itIsUpdate
      );
      yield put({
        type: actions.FETCH_AGENDAS_FROM_FIRESTORE_REQUEST,
      });
    } catch (error) {
      console.warn(error);
    }
  });
}

function deleteAgendaOnFirestore(addressId, agendaKey, mainUser) {
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const fs = getFirestore();
  const docRef = doc(
    fs,
    'professionals',
    uid,
    'addresses',
    addressId,
    'agendas',
    agendaKey,
  );
  return deleteDoc(docRef);
}

function deleteAgendaOnDB(agendaKey, mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  updates[`/requests/${uid}/${agendaKey}/confirmed`] = null;
  updates[`/requests/${uid}/${agendaKey}/pending`] = null;
  updates[`/requests/${uid}/${agendaKey}/rejected`] = null;
  updates[`/requests/${uid}/${agendaKey}/canceled`] = null;
  updates[`/requests/${uid}/${agendaKey}/accepted`] = null;
  return update(dbRef, updates);
}

export function* deleteAgendaRequest() {
  yield takeEvery(actions.DELETE_AGENDA_REQUEST, function* (action) {
    try {
      yield put({ type: actions.DELETING_AGENDA });
      const idTokenResult = yield call(accessTokenResult);
      const mainUser = idTokenResult.claims.mainUser
        ? idTokenResult.claims.mainUser
        : null;
      const addressUid = yield select(getSelectedAddressFromStore);
      yield call(
        deleteAgendaOnFirestore,
        addressUid,
        action.payload,
        mainUser,
      );
      yield call(
        deleteAgendaOnDB,
        action.payload,
        mainUser,
      );

      yield put({
        type: actions.FETCH_AGENDAS_FROM_FIRESTORE_REQUEST,
      });
    } catch (error) {
      console.warn(error);
      notification(
        'error',
        'Não foi possível remover a agenda',
        'Tente novamente mais tarde ou entre em contato com o suporte se o problema persistir.',
      );
    }
  });
}

function createConfirmedListener(params = {}, agendaId, mainUser) {
  const db = getDatabase();
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const currentDate = moment().tz('America/Sao_Paulo').toDate();
  let start = moment(currentDate).tz('America/Sao_Paulo').startOf('week').format('YYYY-MM-DD');
  if (params.start) {
    ({ start } = params);
  }
  const startToUse = moment(start)
    .tz('America/Sao_Paulo')
    // .subtract(1, 'weeks')
    .format('YYYY-MM-DD');
  const listener = eventChannel((emit) => {
    const databaseRef = ref(db, `requests/${uid}/${agendaId}/confirmed`);
    const queryRef = dbQuery(
      databaseRef,
      orderByChild('time'),
      startAt(startToUse),
      // endAt(`${end}\uf8ff`),
    );
    const unsubscribe = onValue(queryRef, (req) => (
      emit(req.val() || {})
    ));
    return () => unsubscribe();
  });
  return listener;
}

export function* getConfirmed() {
  yield takeEvery([
    actions.SET_NEW_AGENDA_LISTENER,
    actions.SELECT_AGENDA,
  ], function* (action) {
    const mainUser = yield select(getMainUserFromStore);
    let agendaId = yield select(getSelectedAgendaFromStore);
    if (_.isEmpty(agendaId)) {
      yield take(actions.SELECT_AGENDA);
      agendaId = yield select(getSelectedAgendaFromStore);
    }
    const confirmedFromStore = yield select(getConfirmedFromStore);
    if (confirmedFromStore) {
      yield put({ type: actions.RESET_CONFIRMED });
    }
    const confirmedListener = yield call(createConfirmedListener, action.payload, agendaId, mainUser);
    yield takeEvery(confirmedListener, function* (confirmed) {
      const normalizedConfirmed = _.map(confirmed, (val, id) => {
        const { duration } = val;
        const startTime = moment(val.time, 'YYYY-MM-DD HH:mm');
        let endTime = moment(val.time, 'YYYY-MM-DD HH:mm').add(moment.duration(duration).asMinutes(), 'm');
        if (endTime.isAfter(startTime, 'day')) {
          endTime = moment(val.time, 'YYYY-MM-DD HH:mm');
          endTime.set({ hour: 23, minute: 59 });
        }
        return {
          ...val,
          start: startTime.toDate(),
          id,
          end: endTime.toDate(),
          allDay: false,
          blockAll: val.allDay ? val.allDay : false,
          type: 'confirmed',
        };
      });
      yield put({
        type: actions.CONFIRMED_FETCH_SUCCESS,
        payload: {
          id: 'confirmed',
          data: normalizedConfirmed,
        },
      });
      const selectedData = yield select(getSelectedDataFromStore);
      if (selectedData && selectedData.id && !selectedData.canceled) {
        if (!selectedData.batchedId) {
          const foundSelectedData = normalizedConfirmed.find((el) => el.id === selectedData.id);
          const currentDate = moment().tz('America/Sao_Paulo').toDate();
          const start = moment(currentDate).tz('America/Sao_Paulo').startOf('week').format('YYYY-MM-DD');
          if (selectedData.time >= start) {
            if (foundSelectedData) {
              if (!_.isEqual(selectedData, foundSelectedData)) {
                yield put({
                  type: calendarActions.UPDATE_SELECTED_DATA_REQUEST,
                  payload: selectedData.id,
                });
              }
            } else {
              yield put({
                type: calendarActions.CLEAR_CALENDAR_SELECTED_DATA_REQUEST,
              });
            }
          }
        }
      }
    });
    const actionTriggered = yield take([
      appActions.CANCEL_LISTENERS,
      actions.SELECT_AGENDA,
    ]);
    confirmedListener.close();
    if (actionTriggered?.type === 'SELECT_AGENDA') {
      // Agenda changed.
    } else {
      // It is the general "cancel all listeners".
      yield all([
        put({ type: appActions.CANCEL_LISTENERS_SUCCESS }),
        put({ type: actions.RESET_AGENDA }),
      ]);
    }
  });
}

function createPastConfirmedListener(params = {}, agendaId, mainUser) {
  const db = getDatabase();
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const currentDate = moment().tz('America/Sao_Paulo').subtract(1, 'weeks').toDate();
  let start = moment(currentDate).tz('America/Sao_Paulo').startOf('week').format('YYYY-MM-DD');
  let end = moment(currentDate).tz('America/Sao_Paulo').endOf('week').format('YYYY-MM-DD');
  if (params.start && params.end) {
    ({ start, end } = params);
  }
  const startToUse = moment(start)
    .tz('America/Sao_Paulo')
    .format('YYYY-MM-DD');
  const listener = eventChannel((emit) => {
    const databaseRef = ref(db, `requests/${uid}/${agendaId}/confirmed`);
    const queryRef = dbQuery(
      databaseRef,
      orderByChild('time'),
      startAt(startToUse),
      endAt(`${end}\uf8ff`),
    );
    const unsubscribe = onValue(queryRef, (req) => (
      emit(req.val() || {})
    ));
    return () => unsubscribe();
  });
  return listener;
}

export function* getPastConfirmed() {
  yield takeEvery([
    actions.SET_NEW_PAST_AGENDA_LISTENER,
    actions.SELECT_AGENDA,
  ], function* (action) {
    yield put({ type: actions.FETCHING_PAST_CONFIRMED });
    const mainUser = yield select(getMainUserFromStore);
    let agendaId = yield select(getSelectedAgendaFromStore);
    if (_.isEmpty(agendaId)) {
      yield take(actions.SELECT_AGENDA);
      agendaId = yield select(getSelectedAgendaFromStore);
    }
    const pastConfirmedFromStore = yield select(getPastConfirmedFromStore);
    if (pastConfirmedFromStore) {
      yield put({ type: actions.RESET_PAST_CONFIRMED });
    }
    const pastAgendaListenerParams = yield select(getPastAgendaListenerParamsFromStore);
    let params = {};
    if (action.type === 'SET_NEW_PAST_AGENDA_LISTENER') {
      params = { ...action.payload };
    } else if (pastAgendaListenerParams) {
      params = { ...pastAgendaListenerParams };
    }
    const confirmedListener = yield call(createPastConfirmedListener, params, agendaId, mainUser);
    yield takeEvery(confirmedListener, function* (confirmed) {
      const normalizedConfirmed = _.map(confirmed, (val, id) => {
        const { duration } = val;
        const startTime = moment(val.time, 'YYYY-MM-DD HH:mm');
        let endTime = moment(val.time, 'YYYY-MM-DD HH:mm').add(moment.duration(duration).asMinutes(), 'm');
        if (endTime.isAfter(startTime, 'day')) {
          endTime = moment(val.time, 'YYYY-MM-DD HH:mm');
          endTime.set({ hour: 23, minute: 59 });
        }
        return {
          ...val,
          start: startTime.toDate(),
          id,
          end: endTime.toDate(),
          allDay: false,
          blockAll: val.allDay ? val.allDay : false,
          type: 'confirmed',
        };
      });
      yield put({
        type: actions.PAST_CONFIRMED_FETCH_SUCCESS,
        payload: {
          id: 'confirmed',
          data: normalizedConfirmed,
        },
      });
      const selectedData = yield select(getSelectedDataFromStore);
      if (selectedData && selectedData.id && !selectedData.canceled) {
        if (!selectedData.batchedId) {
          const foundSelectedData = normalizedConfirmed.find((el) => el.id === selectedData.id);
          const currentDate = moment().tz('America/Sao_Paulo').toDate();
          const start = moment(currentDate).tz('America/Sao_Paulo').startOf('week').format('YYYY-MM-DD');
          if (selectedData.time < start) {
            if (foundSelectedData) {
              if (!_.isEqual(selectedData, foundSelectedData)) {
                yield put({
                  type: calendarActions.UPDATE_SELECTED_DATA_REQUEST,
                  payload: selectedData.id,
                });
              }
            } else {
              yield put({
                type: calendarActions.CLEAR_CALENDAR_SELECTED_DATA_REQUEST,
              });
            }
          }
        }
      }
    });
    const actionTriggered = yield take([
      appActions.CANCEL_LISTENERS,
      actions.CHANGE_PAST_LISTENER_PARAM,
      actions.SELECT_AGENDA,
    ]);
    confirmedListener.close();
    if (actionTriggered?.type === 'CHANGE_PAST_LISTENER_PARAM') {
      // Reset agenda's listener to new params.
      yield put({
        type: actions.SET_NEW_PAST_AGENDA_LISTENER,
        payload: actionTriggered.payload,
      });
    } else if (actionTriggered?.type === 'SELECT_AGENDA') {
      // Agenda changed.
    } else {
      // It is the general "cancel all listeners".
      yield all([
        put({ type: appActions.CANCEL_LISTENERS_SUCCESS }),
        put({ type: actions.RESET_AGENDA }),
      ]);
    }
  });
}

function createPendingListener(agendaId, mainUser) {
  const db = getDatabase();
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const listener = eventChannel((emit) => {
    const pendingRef = ref(db, `requests/${uid}/${agendaId}/pending`);
    const unsubscribe = onValue(pendingRef, (req) => (
      emit(req.val() || {})
    ));
    return () => unsubscribe();
  });
  return listener;
}

export function* getPending() {
  yield takeLatest([
    actions.FETCH_AGENDAS_FROM_FIRESTORE_SUCCESS,
  ], function* () {
    const mainUser = yield select(getMainUserFromStore);
    let agendaId = yield select(getSelectedAgendaFromStore);
    if (_.isEmpty(agendaId)) {
      yield take(actions.SELECT_AGENDA);
      agendaId = yield select(getSelectedAgendaFromStore);
    }
    let agendasArr = yield select(getAgendasArrFromStore);
    while (!agendasArr || _.isEmpty(agendasArr)) {
      yield take(actions.FETCH_AGENDAS_FROM_FIRESTORE_SUCCESS);
      agendasArr = yield select(getAgendasArrFromStore);
    }
    const pendingListenerArr = yield all(agendasArr.map((agenda) => call(createPendingListener, agenda.id, mainUser)));
    yield all(pendingListenerArr.map((pendingListener, listenerIndex) => takeEvery(pendingListener, function* (pending) {
      const normalizedPending = _.map(pending, (val, id) => (
        {
          ...val,
          id,
          pending: true,
          type: 'pending',
        }
      ));
      yield put({
        type: actions.ALL_PENDING_FETCH_SUCCESS,
        payload: {
          id: agendasArr[listenerIndex].id,
          data: normalizedPending,
        },
      });
      if (agendasArr[listenerIndex].id === agendaId) {
        yield put({
          type: actions.PENDING_FETCH_SUCCESS,
          payload: {
            id: 'pending',
            data: normalizedPending,
          },
        });
      }
    })));
    const actionTriggered = yield take([
      appActions.CANCEL_LISTENERS,
      // actions.SELECT_AGENDA,
    ]);
    pendingListenerArr.forEach((el) => {
      el.close();
    });
    if (actionTriggered?.type === 'SELECT_AGENDA') {
      // Agenda changed.
    } else {
      // It is the general "cancel all listeners".
      yield all([
        put({ type: appActions.CANCEL_LISTENERS_SUCCESS }),
        put({ type: actions.RESET_AGENDA }),
      ]);
    }
  });
}

function createRejectedListener(agendaId, mainUser) {
  const db = getDatabase();
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const listener = eventChannel((emit) => {
    const rejectedRef = ref(db, `requests/${uid}/${agendaId}/rejected`);
    const unsubscribe = onValue(rejectedRef, (req) => (
      emit(req.val() || {})
    ));
    return () => unsubscribe();
  });
  return listener;
}

export function* getRejected() {
  yield takeLatest([
    actions.FETCH_AGENDAS_FROM_FIRESTORE_SUCCESS,
  ], function* () {
    const mainUser = yield select(getMainUserFromStore);
    let agendasArr = yield select(getAgendasArrFromStore);
    while (!agendasArr || _.isEmpty(agendasArr)) {
      yield take(actions.FETCH_AGENDAS_FROM_FIRESTORE_SUCCESS);
      agendasArr = yield select(getAgendasArrFromStore);
    }
    const rejectedListenerArr = yield all(agendasArr.map((agenda) => call(createRejectedListener, agenda.id, mainUser)));
    yield all(rejectedListenerArr.map((rejectedListener, listenerIndex) => takeEvery(rejectedListener, function* (rejected) {
      const normalizedRejected = _.map(rejected, (val, id) => (
        {
          ...val,
          id,
          rejected: true,
          type: 'rejected',
        }
      ));
      yield put({
        type: actions.ALL_REJECTED_FETCH_SUCCESS,
        payload: {
          id: agendasArr[listenerIndex].id,
          data: normalizedRejected,
        },
      });
    })));
    const actionTriggered = yield take([
      appActions.CANCEL_LISTENERS,
      // actions.SELECT_AGENDA,
    ]);
    rejectedListenerArr.forEach((el) => {
      el.close();
    });
    if (actionTriggered?.type === 'SELECT_AGENDA') {
      // Agenda changed.
    } else {
      // It is the general "cancel all listeners".
      yield all([
        put({ type: appActions.CANCEL_LISTENERS_SUCCESS }),
        put({ type: actions.RESET_AGENDA }),
      ]);
    }
  });
}

function createCanceledListener(params = {}, agendaId, mainUser) {
  const db = getDatabase();
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const currentDate = moment().tz('America/Sao_Paulo').toDate();
  let start = moment(currentDate).tz('America/Sao_Paulo').startOf('week').format('YYYY-MM-DD');
  if (params.start) {
    ({ start } = params);
  }
  const startToUse = moment(start)
    .tz('America/Sao_Paulo')
    // .subtract(1, 'weeks')
    .format('YYYY-MM-DD');
  const listener = eventChannel((emit) => {
    const databaseRef = ref(db, `requests/${uid}/${agendaId}/canceled`);
    const queryRef = dbQuery(
      databaseRef,
      orderByChild('time'),
      startAt(startToUse),
      // endAt(`${end}\uf8ff`),
    );
    const unsubscribe = onValue(queryRef, (req) => (
      emit(req.val() || {})
    ));
    return () => unsubscribe();
  });
  return listener;
}

export function* getCanceled() {
  yield takeEvery([
    actions.SET_NEW_AGENDA_CANCELED_LISTENER,
    actions.SELECT_AGENDA,
  ], function* (action) {
    const mainUser = yield select(getMainUserFromStore);
    let agendaId = yield select(getSelectedAgendaFromStore);
    if (_.isEmpty(agendaId)) {
      yield take(actions.SELECT_AGENDA);
      agendaId = yield select(getSelectedAgendaFromStore);
    }
    const canceledFromStore = yield select(getCanceledFromStore);
    if (canceledFromStore) {
      yield put({ type: actions.RESET_CANCELED });
    }
    const canceledListener = yield call(createCanceledListener, action.payload, agendaId, mainUser);
    yield takeEvery(canceledListener, function* (canceled) {
      const normalizedCanceled = _.map(canceled, (val, id) => {
        const { duration } = val;
        return {
          ...val,
          start: moment(val.time, 'YYYY-MM-DD HH:mm').toDate(),
          id,
          end: moment(val.time, 'YYYY-MM-DD HH:mm').add(moment.duration(duration).asMinutes(), 'm').toDate(),
          allDay: false,
          blockAll: val.allDay ? val.allDay : false,
          canceled: val.canceled ? val.canceled : true,
          type: 'canceled',
        };
      });
      yield put({
        type: actions.CANCELED_FETCH_SUCCESS,
        payload: {
          id: 'canceled',
          data: normalizedCanceled,
        },
      });
    });
    const actionTriggered = yield take([
      appActions.CANCEL_LISTENERS,
      // actions.CHANGE_LISTENER_PARAM,
      actions.SELECT_AGENDA,
    ]);
    canceledListener.close();
    if (actionTriggered?.type === 'SELECT_AGENDA') {
      // Agenda changed.
    } else {
      // It is the general "cancel all listeners".
      yield all([
        put({ type: appActions.CANCEL_LISTENERS_SUCCESS }),
        put({ type: actions.RESET_AGENDA }),
      ]);
    }
  });
}

function createPastCanceledListener(params = {}, agendaId, mainUser) {
  const db = getDatabase();
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const currentDate = moment().tz('America/Sao_Paulo').subtract(1, 'weeks').toDate();
  let start = moment(currentDate).tz('America/Sao_Paulo').startOf('week').format('YYYY-MM-DD');
  let end = moment(currentDate).tz('America/Sao_Paulo').endOf('week').format('YYYY-MM-DD');
  if (params.start && params.end) {
    ({ start, end } = params);
  }
  const startToUse = moment(start)
    .tz('America/Sao_Paulo')
    .format('YYYY-MM-DD');
  const listener = eventChannel((emit) => {
    const databaseRef = ref(db, `requests/${uid}/${agendaId}/canceled`);
    const queryRef = dbQuery(
      databaseRef,
      orderByChild('time'),
      startAt(startToUse),
      endAt(`${end}\uf8ff`),
    );
    const unsubscribe = onValue(queryRef, (req) => (
      emit(req.val() || {})
    ));
    return () => unsubscribe();
  });
  return listener;
}

export function* getPastCanceled() {
  yield takeEvery([
    actions.SET_NEW_PAST_AGENDA_CANCELED_LISTENER,
    actions.SELECT_AGENDA,
  ], function* (action) {
    const mainUser = yield select(getMainUserFromStore);
    let agendaId = yield select(getSelectedAgendaFromStore);
    if (_.isEmpty(agendaId)) {
      yield take(actions.SELECT_AGENDA);
      agendaId = yield select(getSelectedAgendaFromStore);
    }
    const pastCanceledFromStore = yield select(getPastCanceledFromStore);
    if (pastCanceledFromStore) {
      yield put({ type: actions.RESET_PAST_CANCELED });
    }
    const pastAgendaListenerParams = yield select(getPastAgendaListenerParamsFromStore);
    let params = {};
    if (action.type === 'SET_NEW_PAST_AGENDA_CANCELED_LISTENER') {
      params = { ...action.payload };
    } else if (pastAgendaListenerParams) {
      params = { ...pastAgendaListenerParams };
    }
    const canceledListener = yield call(createPastCanceledListener, params, agendaId, mainUser);
    yield takeEvery(canceledListener, function* (canceled) {
      const normalizedCanceled = _.map(canceled, (val, id) => {
        const { duration } = val;
        return {
          ...val,
          start: moment(val.time, 'YYYY-MM-DD HH:mm').toDate(),
          id,
          end: moment(val.time, 'YYYY-MM-DD HH:mm').add(moment.duration(duration).asMinutes(), 'm').toDate(),
          allDay: false,
          blockAll: val.allDay ? val.allDay : false,
          canceled: val.canceled ? val.canceled : true,
          type: 'canceled',
        };
      });
      yield put({
        type: actions.PAST_CANCELED_FETCH_SUCCESS,
        payload: {
          id: 'canceled',
          data: normalizedCanceled,
        },
      });
    });
    const actionTriggered = yield take([
      appActions.CANCEL_LISTENERS,
      actions.CHANGE_PAST_LISTENER_PARAM,
      actions.SELECT_AGENDA,
    ]);
    canceledListener.close();
    if (actionTriggered?.type === 'CHANGE_PAST_LISTENER_PARAM') {
      // Reset agenda's listener to new params.
      yield put({
        type: actions.SET_NEW_PAST_AGENDA_CANCELED_LISTENER,
        payload: actionTriggered.payload,
      });
    } else if (actionTriggered?.type === 'SELECT_AGENDA') {
      // Agenda changed.
    } else {
      // It is the general "cancel all listeners".
      yield all([
        put({ type: appActions.CANCEL_LISTENERS_SUCCESS }),
        put({ type: actions.RESET_AGENDA }),
      ]);
    }
  });
}

function createAcceptedListener(agendaId, mainUser) {
  const db = getDatabase();
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const listener = eventChannel((emit) => {
    const acceptedRef = ref(db, `requests/${uid}/${agendaId}/accepted`);
    const unsubscribe = onValue(acceptedRef, (req) => (
      emit(req.val() || {})
    ));
    return () => unsubscribe();
  });
  return listener;
}

export function* getAccepted() {
  yield takeLatest([
    actions.FETCH_AGENDAS_FROM_FIRESTORE_SUCCESS,
  ], function* () {
    const mainUser = yield select(getMainUserFromStore);
    let agendasArr = yield select(getAgendasArrFromStore);
    while (!agendasArr || _.isEmpty(agendasArr)) {
      yield take(actions.FETCH_AGENDAS_FROM_FIRESTORE_SUCCESS);
      agendasArr = yield select(getAgendasArrFromStore);
    }
    const acceptedListenerArr = yield all(agendasArr.map((agenda) => call(createAcceptedListener, agenda.id, mainUser)));
    yield all(acceptedListenerArr.map((acceptedListener, listenerIndex) => takeEvery(acceptedListener, function* (accepted) {
      const normalizedArr = _.map(accepted, (val, id) => {
        const { duration } = val;
        return {
          ...val,
          start: moment(val.time, 'YYYY-MM-DD HH:mm').toDate(),
          id,
          end: moment(val.time, 'YYYY-MM-DD HH:mm').add(moment.duration(duration).asMinutes(), 'm').toDate(),
          allDay: false,
          blockAll: val.allDay ? val.allDay : false,
          type: 'accepted',
        };
      });
      yield put({
        type: actions.ALL_ACCEPTED_FETCH_SUCCESS,
        payload: {
          id: agendasArr[listenerIndex].id,
          data: normalizedArr,
        },
      });
    })));
    const actionTriggered = yield take([
      appActions.CANCEL_LISTENERS,
      // actions.SELECT_AGENDA,
    ]);
    acceptedListenerArr.forEach((el) => {
      el.close();
    });
    if (actionTriggered?.type === 'SELECT_AGENDA') {
      // Agenda changed.
    } else {
      // It is the general "cancel all listeners".
      yield all([
        put({ type: appActions.CANCEL_LISTENERS_SUCCESS }),
        put({ type: actions.RESET_AGENDA }),
      ]);
    }
  });
}

function getIdToken() {
  const auth = getAuth();
  const { currentUser } = auth;
  return currentUser.getIdToken();
}

function sendMessages(
  key,
  patient,
  userInfo,
  queryConfirmedKey = null,
  professionalInfo,
  time,
  requestedOnline = false,
  addressInfo,
  addressUid,
  agendaId,
  idToken,
  mode,
  mainUser,
) {
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };
  const bodyParameters = {
    uid,
    addressUid,
    agendaId,
    patient,
    key,
    userInfo,
    date: time,
    professionalInfo,
    queryConfirmedKey,
    addressInfo,
    requestedOnline,
    mode,
  };
  // return true;
  return axios.post(
    `${ROOT_URL}/sendMessages`,
    bodyParameters,
    config,
  );
}

function confirmPendingRequestOnDB({
  key, pending, addressUid, agendaId, professionalInfo,
}, idToken, mainUser) {
  const auth = getAuth();
  const { currentUser } = auth;
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    ({ uid } = currentUser);
  }
  const value = pending[key];
  const appointment = _.cloneDeep(value);
  delete appointment.userInfo;
  delete appointment.user;
  const config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };
  const bodyParameters = {
    uid,
    addressUid,
    agendaId,
    patient: value.user,
    key,
    currentUser: currentUser.uid,
  };
  if (professionalInfo.mainUser) {
    bodyParameters.mainUser = professionalInfo.mainUser;
  }
  // return { status: 201 };
  return axios.post(
    `${ROOT_URL}/confirmAppointmentRequest`,
    bodyParameters,
    config,
  );
}

export function* confirmPendingRequest() {
  yield takeEvery(actions.CONFIRM_REQUEST, function* (action) {
    try {
      const mainUser = yield select(getMainUserFromStore);
      yield put({
        type: actions.START_UPDATING_REQUEST,
        payload: action.payload.requestId,
      });
      let profile = yield select(getProfileFromStore);
      if (_.isEmpty(profile)) {
        yield take(profileActions.PROFILE_INFO_SUCCESS);
        profile = yield select(getProfileFromStore);
      }
      let professionalInfo = { ...profile };
      delete professionalInfo.keywords;
      delete professionalInfo.addresses;
      const addressUid = yield select(getSelectedAddressFromStore);
      // const agendaId = yield select(getSelectedAgendaFromStore);
      const customUsers = yield select(getCustomUsersFromStore);
      const addressInfo = profile.addresses.find((address) => address.id === addressUid);
      const idToken = yield call(getIdToken);
      const pending = yield select(getAllPendingRequestsFromStore);
      const editedPending = pending[action.payload.agendaId].reduce((acc, { id, ...obj }) => {
        acc[id] = { ...obj, id };
        return acc;
      }, {});
      const foundPending = pending[action.payload.agendaId].find((el) => el.id === action.payload.requestId);
      if (foundPending?.professional && foundPending.professional !== professionalInfo.id) {
        const foundProfessional = customUsers.find((el) => foundPending.professional === el.id);
        professionalInfo = { ...foundProfessional };
      }
      yield put({
        type: contactActions.FIND_POSSIBLE_MERGE_PATIENTS_REQUEST,
        payload: {
          key: action.payload.requestId,
          pending: editedPending,
        },
      });
      const mergeFinished = yield take(contactActions.FINISHED_MERGE_PATIENT_PROCESS);
      if (mergeFinished.payload) {
        const { status } = yield call(confirmPendingRequestOnDB, {
          key: action.payload.requestId,
          pending: editedPending,
          addressUid,
          agendaId: action.payload.agendaId,
          professionalInfo,
        }, idToken, mainUser);
        if (status === 201) {
          yield all([
            put({ type: actions.CONFIRM_REQUEST_SUCCESS }),
          ]);
        }
        const selectedData = editedPending[action.payload.requestId];
        if (selectedData) {
          // const automaticMessage = yield select(getActiveMessageBot);
          const fullMessagesSettings = yield select(getFullMessagesSettingsFromStore);
          const manuallySendMessage = !fullMessagesSettings?.[professionalInfo.id]?.sendAutomaticMessages;
          if (manuallySendMessage) {
            yield all([
              put({
                type: actions.CONFIRM_MANUAL_CONFIRMATION_REQUEST_SUCCESS,
                payload: {
                  modalVisible: 'onlineAppointmentMessagesModel',
                  selectedData: foundPending,
                },
              }),
            ]);
          } else {
            yield spawn(
              sendMessages,
              selectedData.id,
              selectedData.user,
              selectedData.userInfo,
              selectedData.queryConfirmedKey ? selectedData.queryConfirmedKey : null,
              professionalInfo,
              selectedData.time,
              selectedData.requestedOnline,
              addressInfo,
              addressUid,
              action.payload.agendaId,
              idToken,
              'create',
              mainUser,
            );
          }
        }
      } else {
        yield put({ type: actions.ABORT_CONFIRM_REQUEST });
      }
    } catch (error) {
      console.warn(error);
      yield put({ type: actions.CONFIRM_REQUEST_FAIL });
    }
  });
}

function rejectRequestOnDB(
  key,
  patient,
  addressUid,
  agendaId,
  idToken,
  mainUser,
) {
  const auth = getAuth();
  const { currentUser } = auth;
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    ({ uid } = currentUser);
  }
  const config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };
  const bodyParameters = {
    uid,
    addressUid,
    agendaId,
    key,
    patient,
    currentUser: currentUser.uid,
    // request: {
    //   userInfo,
    //   professionalInfo,
    // },
  };
  // return { status: 201 };
  return axios.post(
    `${ROOT_URL}/rejectAppointmentRequest`,
    bodyParameters,
    config,
  );
}

export function* rejectRequest() {
  yield takeEvery(actions.REJECT_REQUEST, function* (action) {
    try {
      yield put({
        type: actions.REJECTING_REQUEST,
        payload: action.payload.appointment.id,
      });
      yield call(sleep, 500);
      let profile = yield select(getProfileFromStore);
      if (_.isEmpty(profile)) {
        yield take(profileActions.PROFILE_INFO_SUCCESS);
        profile = yield select(getProfileFromStore);
      }
      let professionalInfo = { ...profile };
      delete professionalInfo.keywords;
      delete professionalInfo.addresses;
      const idToken = yield call(getIdToken);
      const addressUid = yield select(getSelectedAddressFromStore);
      const addressInfo = profile.addresses.find((address) => address.id === addressUid);
      // const agendaId = yield select(getSelectedAgendaFromStore);
      const customUsers = yield select(getCustomUsersFromStore);
      const mainUser = yield select(getMainUserFromStore);
      if (action.payload.appointment.professional && action.payload.appointment.professional !== professionalInfo.id) {
        const foundProfessional = customUsers.find((el) => action.payload.appointment.professional === el.id);
        professionalInfo = { ...foundProfessional };
      }
      const { status } = yield call(
        rejectRequestOnDB,
        action.payload.appointment.id,
        action.payload.appointment.user,
        addressUid,
        action.payload.agendaId,
        idToken,
        mainUser,
      );
      if (status === 201) {
        yield all([
          put({ type: actions.REJECT_REQUEST_SUCCESS }),
        ]);
      }
      // const automaticMessage = yield select(getActiveMessageBot);
      const fullMessagesSettings = yield select(getFullMessagesSettingsFromStore);
      const manuallySendMessage = !fullMessagesSettings?.[professionalInfo.id]?.sendAutomaticMessages;
      if (manuallySendMessage) {
        yield all([
          put({
            type: actions.CONFIRM_MANUAL_CONFIRMATION_REQUEST_SUCCESS,
            payload: {
              modalVisible: 'onlineAppointmentMessagesModel',
              selectedData: action.payload.appointment,
            },
          }),
        ]);
      } else {
        yield spawn(
          sendMessages,
          action.payload.appointment.id,
          action.payload.appointment.user,
          action.payload.appointment.userInfo,
          action.payload.appointment.queryConfirmedKey ? action.payload.appointment.queryConfirmedKey : null,
          professionalInfo,
          action.payload.appointment.time,
          action.payload.appointment.requestedOnline,
          addressInfo,
          addressUid,
          action.payload.agendaId,
          idToken,
          'reject',
          mainUser,
        );
      }
    } catch (error) {
      console.warn(error);
      yield put({ type: actions.REJECT_REQUEST_FAIL });
    }
  });
}

function editAppointmentTimeOnDB(
  idToken,
  selectedData,
  addressUid,
  agendaId,
  start,
  duration,
  professionalInfo,
  resendMessage = false,
  mainUser,
  removeVideoCall,
) {
  const auth = getAuth();
  const { currentUser } = auth;
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    ({ uid } = currentUser);
  }
  const config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };
  const data = {
    ...selectedData,
    user: selectedData.mode === 'blocked' ? 'blocked' : selectedData.user,
  };
  const bodyParameters = {
    uid,
    selectedData: data,
    addressUid,
    agendaId,
    start,
    duration,
    professionalInfo,
    resendMessage,
    currentUser: currentUser.uid,
    removeVideoCall,
  };
  // return { status: 201 };
  return axios.post(
    `${ROOT_URL}/editAppointmentTime`,
    bodyParameters,
    config,
  );
}

function handleUpdateEvent(idEvent, videoCallCreator, date, duration, selectedPatientInfo) {
  const startTime = moment(date, 'YYYY-MM-DD HH:mm').tz('America/Sao_Paulo').format();
  const splittedDuration = duration.split(':');
  const endTime = moment(date, 'YYYY-MM-DD HH:mm').add(moment.duration(duration).asMinutes(), 'm').format();
  const event = {
    start: { dateTime: startTime },
    end: { dateTime: endTime },
    // attendees: [{ email: `${selectedData.userInfo.email} ` }],
    // conferenceData: {
    //   createRequest: {
    //     requestId: 'sample123',
    //     conferenceSolutionKey: { type: 'hangoutsMeet' },
    //   },
    // },
    summary: `Chamada de vídeo com o paciente ${selectedPatientInfo.firstName} ${selectedPatientInfo.lastName}`,
    description: `Consulta com duração de ${splittedDuration[0]}h:${splittedDuration[1]}m
    para o paciente ${selectedPatientInfo.firstName} ${selectedPatientInfo.lastName}`,
  };
  return window.gapi.client.calendar.events.update({
    calendarId: `${videoCallCreator}`,
    eventId: `${idEvent}`,
    resource: event,
  });
}

export function* editAppointmentTimeRequest() {
  yield takeLatest(actions.EDIT_APPOINTMENT_TIME_REQUEST, function* (action) {
    try {
      yield put({
        type: actions.START_SAVING_APPOINTMENT_EDIT,
        // payload: action.payload.selectedData.id,
      });
      let profile = yield select(getProfileFromStore);
      if (_.isEmpty(profile)) {
        yield take(profileActions.PROFILE_INFO_SUCCESS);
        profile = yield select(getProfileFromStore);
      }
      let professionalInfo = { ...profile };
      delete professionalInfo.keywords;
      delete professionalInfo.addresses;
      const idToken = yield call(getIdToken);
      let gisUserProfile = yield select(getGisUserProfileFromStore);
      const addressUid = yield select(getSelectedAddressFromStore);
      // const addressInfo = profile.addresses.find((address) => address.id === addressUid);
      const agendaId = yield select(getSelectedAgendaFromStore);
      const customUsers = yield select(getCustomUsersFromStore);
      const { selectedData } = action.payload;
      if (selectedData.professional && selectedData.professional !== professionalInfo.id) {
        const foundProfessional = customUsers.find((el) => selectedData.professional === el.id);
        professionalInfo = { ...foundProfessional };
      }
      let formatedStart = '';
      const diff = moment(action.payload.end).diff(moment(action.payload.start));
      const diffMin = Math.abs(moment.duration(diff).asMinutes());
      const duration = moment('00:00', 'HH:mm').add(diffMin, 'm').format('HH:mm');
      if (action.payload.blockAll && duration === '23:59') {
        formatedStart = `${moment(action.payload.start).format('YYYY-MM-DD')} 00:00`;
      } else {
        formatedStart = moment(action.payload.start).format('YYYY-MM-DD HH:mm');
      }
      const mainUser = yield select(getMainUserFromStore);
      let removeVideoCall = false;

      if (selectedData?.videoCallData?.videoCallId
        && selectedData?.videoCallData?.videoCallCreator
        && selectedData?.userInfo) {
        if (!gisUserProfile) {
          notificationAntd.warning({
            message: 'Você não está logado com a conta Google utilizada ao criar a consulta.',
            description: (
              <span>
                As alterações de horário ou duração da consulta não serão atualizadas no seu Google Calendar.&nbsp;
                <Button
                  type="link"
                  style={{ padding: 0, height: '21px' }}
                  onClick={() => {
                    googleAuthRequestChannel.put({ type: gapiActions.GIS_AUTHORIZATION_REQUEST });
                  }}
                >
                  Clique aqui para acessar sua conta Google
                </Button>
                &nbsp;ou&nbsp;
                <Button
                  type="link"
                  style={{ padding: 0, height: '21px' }}
                  onClick={() => {
                    googleAuthRequestChannel.put({ type: gapiActions.CONTINUE_WITHOUT_GIS_AUTHORIZATION });
                  }}
                >
                  continue sem uma conta Google.
                </Button>
              </span>
            ),
            duration: null,
            key: 'gapi-notification',
            onClose: () => googleAuthRequestChannel.put({ type: gapiActions.CONTINUE_WITHOUT_GIS_AUTHORIZATION }),
          });
          const actionTriggered = yield take([
            gapiActions.CONTINUE_WITHOUT_GIS_AUTHORIZATION,
            gapiActions.SET_GIS_ACCESS_TOKEN,
          ]);
          notificationAntd.destroy('gapi-notification');
          if (actionTriggered.type === 'SET_GIS_ACCESS_TOKEN') {
            gisUserProfile = yield select(getGisUserProfileFromStore);
          }
        }
        if (!window.gapi || !gisUserProfile) {
          notification(
            'error',
            'Algo deu errado para se comunicar com os servidores da Google',
            'As alterações de horário ou duração da consulta não serão atualizadas no seu Google Calendar.',
          );
        } else if (window.gapi.client.getToken() === null) {
          notification(
            'error',
            'Não há permissão suficiente de uma conta no Google Calendar para habilitar vídeo chamada',
            'As alterações de horário ou duração da consulta não serão atualizadas no seu Google Calendar.',
          );
        } else if (gisUserProfile?.email === selectedData.videoCallData.videoCallCreator) {
          try {
            yield call(
              handleUpdateEvent,
              selectedData.videoCallData.videoCallId,
              selectedData.videoCallData.videoCallCreator,
              formatedStart,
              duration,
              selectedData.userInfo,
            );
          } catch (error) {
            console.warn(error);
            if (error?.result?.error?.message === 'Resource has been deleted') {
              notification('warning', 'O evento em seu Google Calendar já foi apagado.');
              removeVideoCall = true;
            } else {
              throw (error);
            }
          }
        } else if (gisUserProfile?.email !== action.payload.selectedData.videoCallData.videoCallCreator) {
          notification(
            'warning',
            'Você não está logado com a conta Google utilizada ao criar a consulta',
            'As alterações de horário ou duração da consulta não foram atualizadas no seu Google Calendar.',
          );
        }
      }

      const { status } = yield call(
        editAppointmentTimeOnDB,
        idToken,
        action.payload.selectedData,
        addressUid,
        agendaId,
        formatedStart,
        duration,
        professionalInfo,
        action.payload.resendMessage,
        mainUser,
        removeVideoCall,
      );
      if (status === 201) {
        yield all([
          put({ type: actions.EDIT_APPOINTMENT_TIME_SUCCESS }),
        ]);
      }
      if (action.payload.resendMessage) {
        yield put({
          type: calendarActions.CALENDAR_EVENT_SELECTED,
          payload: {
            modalVisible: 'manualAppointmentMessagesModel',
            selectedData: {
              ...action.payload.selectedData,
              time: formatedStart,
            },
          },
        });

        // const fullMessagesSettings = yield select(getFullMessagesSettingsFromStore);
        // const manuallySendMessage = !fullMessagesSettings?.[professionalInfo.id]?.sendAutomaticMessages;
        // if (manuallySendMessage) {
        //   yield all([
        //     put({
        //       type: calendarActions.CALENDAR_EVENT_SELECTED,
        //       payload: {
        //         modalVisible: 'manualAppointmentMessagesModel',
        //         selectedData: {
        //           ...action.payload.selectedData,
        //           time: formatedStart,
        //         },
        //       },
        //     }),
        //   ]);
        // } else {
        //   yield spawn(
        //     sendMessages,
        //     action.payload.selectedData.id,
        //     action.payload.selectedData.user,
        //     action.payload.selectedData.userInfo,
        //     action.payload.selectedData.queryConfirmedKey,
        //     professionalInfo,
        //     formatedStart,
        //     action.payload.selectedData.requestedOnline,
        //     addressInfo,
        //     addressUid,
        //     agendaId,
        //     idToken,
        //     'edit',
        //     mainUser,
        //   );
        // }
      }
    } catch (error) {
      console.warn(error);
      yield put({ type: actions.EDIT_APPOINTMENT_TIME_ERROR });
      if (error?.result?.error?.status === 'PERMISSION_DENIED') {
        notificationAntd.warning({
          message: 'É necessário conceder permissões para o Meagenda acessar o Google Calendar',
          description: (
            <span>
              É necessário se autenticar novamente para conceder as permissões.&nbsp;
              <Button
                type="link"
                style={{
                  padding: 0,
                  height: '21px',
                  whiteSpace: 'break-spaces',
                  textAlign: 'start',
                }}
                onClick={() => {
                  googleAuthRequestChannel.put({ type: gapiActions.GIS_AUTHORIZATION_REQUEST });
                  notificationAntd.destroy('gapi-notification');
                }}
              >
                Clique aqui para fazer autenticação da sua conta Google
              </Button>
            </span>
          ),
          duration: null,
          key: 'gapi-notification',
        });
      }
      if (error?.result?.error?.status === 'UNAUTHENTICATED') {
        notification(
          'warning',
          'A sua conta Google não está conectada ou sua sessão expirou.',
          'As alterações de horário ou duração da consulta não podem ser atualizadas no seu Google Calendar.',
        );
        yield call(sleep, 2000);
        yield put({ type: gapiActions.GIS_AUTHORIZATION_REQUEST });
      }
    }
  });
}

export function* sendMessageOnAppointmentEditTimeRequest() {
  yield takeLatest(actions.NOTIFY_NEW_APPOINTMENT_TIME_REQUEST, function* (action) {
    try {
      yield put({
        type: calendarActions.SET_CALENDAR_MODAL_DATA,
        payload: {
          modalVisible: 'sendMessage',
        },
      });
      const sendMessageAction = yield take(calendarActions.NOTIFY_NEW_APPOINTMENT_TIME_RESPONSE);
      yield put({
        type: actions.EDIT_APPOINTMENT_TIME_REQUEST,
        payload: {
          ...action.payload,
          resendMessage: sendMessageAction.payload,
        },
      });
    } catch (error) {
      console.warn(error);
      yield put({ type: actions.EDIT_APPOINTMENT_TIME_ERROR });
    }
  });
}

export function* sendMessageOnBacthedAppointmentEditTimeRequest() {
  yield takeLatest(actions.NOTIFY_NEW_BATCHED_APPOINTMENT_TIME_REQUEST, function* (action) {
    try {
      yield put({
        type: calendarActions.SET_CALENDAR_MODAL_DATA,
        payload: {
          modalVisible: 'sendMessage',
        },
      });
      const sendMessageAction = yield take(calendarActions.NOTIFY_NEW_APPOINTMENT_TIME_RESPONSE);
      yield put({
        type: actions.CONTINUE_EDIT_BATCHED_APPOINTMENT,
        payload: {
          ...action.payload,
          resendMessage: sendMessageAction.payload,
        },
      });
    } catch (error) {
      console.warn(error);
      yield put({ type: actions.EDIT_APPOINTMENT_TIME_ERROR });
    }
  });
}

function patientMissedAppointmentOnDB(appointmentId, agendaId, queryConfirmedKey, mainUser) {
  const dbRef = ref(getDatabase());
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  if (queryConfirmedKey) {
    // Cleaning queryConfirmed
    updates[`/queryConfirmed/${queryConfirmedKey}/finalized`] = null;
    updates[`/queryConfirmed/${queryConfirmedKey}/manuallyConfirmed`] = null;
    updates[`/queryConfirmed/${queryConfirmedKey}/surgery`] = null;
    // Cleaning query2HoursBefore
    updates[`/query2HoursBefore/${queryConfirmedKey}/finalized`] = null;
    updates[`/query2HoursBefore/${queryConfirmedKey}/surgery`] = null;
  }
  // New status method
  updates[`/requests/${uid}/${agendaId}/confirmed/${appointmentId}/patientDidNotShow`] = true;
  updates[`/requests/${uid}/${agendaId}/confirmed/${appointmentId}/arrivalTime`] = null;
  updates[`/requests/${uid}/${agendaId}/confirmed/${appointmentId}/startedAttendance`] = null;
  updates[`/requests/${uid}/${agendaId}/confirmed/${appointmentId}/endTime`] = null;
  updates[`/requests/${uid}/${agendaId}/confirmed/${appointmentId}/appointmentMissed`] = null;
  updates[`/requests/${uid}/${agendaId}/confirmed/${appointmentId}/status`] = 'patientDidNotShow';
  updates[`/requests/${uid}/${agendaId}/confirmed/${appointmentId}/selectedLabel`] = null;
  return update(dbRef, updates);
}

function patientAttendanceStartedOnDB(appointmentId, agendaId, queryConfirmedKey, mainUser) {
  const dbRef = ref(getDatabase());
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  // const time = moment().tz('America/Sao_Paulo').format();
  if (queryConfirmedKey) {
    // Cleaning queryConfirmed
    updates[`/queryConfirmed/${queryConfirmedKey}/finalized`] = null;
    updates[`/queryConfirmed/${queryConfirmedKey}/manuallyConfirmed`] = null;
    updates[`/queryConfirmed/${queryConfirmedKey}/surgery`] = null;
    // Cleaning query2HoursBefore
    updates[`/query2HoursBefore/${queryConfirmedKey}/finalized`] = null;
    updates[`/query2HoursBefore/${queryConfirmedKey}/surgery`] = null;
  }
  // New status method
  // updates[`/requests/${uid}/${agendaId}/confirmed/${appointmentId}/startedAttendance`] = time;
  updates[`/requests/${uid}/${agendaId}/confirmed/${appointmentId}/startedAttendance`] = dbServerTimestamp();
  // updates[`/requests/${uid}/${agendaId}/confirmed/${appointmentId}/arrivalTime`] = null;
  updates[`/requests/${uid}/${agendaId}/confirmed/${appointmentId}/endTime`] = null;
  updates[`/requests/${uid}/${agendaId}/confirmed/${appointmentId}/patientDidNotShow`] = null;
  updates[`/requests/${uid}/${agendaId}/confirmed/${appointmentId}/appointmentMissed`] = null;
  updates[`/requests/${uid}/${agendaId}/confirmed/${appointmentId}/status`] = 'startedAttendance';
  updates[`/requests/${uid}/${agendaId}/confirmed/${appointmentId}/selectedLabel`] = null;
  return update(dbRef, updates);
}

function addArrivalTimeOnDB(appointmentId, agendaId, queryConfirmedKey, mainUser) {
  const dbRef = ref(getDatabase());
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  // const time = moment().tz('America/Sao_Paulo').format();
  if (queryConfirmedKey) {
    // Cleaning queryConfirmed
    updates[`/queryConfirmed/${queryConfirmedKey}/finalized`] = null;
    updates[`/queryConfirmed/${queryConfirmedKey}/manuallyConfirmed`] = null;
    updates[`/queryConfirmed/${queryConfirmedKey}/surgery`] = null;
    // Cleaning query2HoursBefore
    updates[`/query2HoursBefore/${queryConfirmedKey}/finalized`] = null;
    updates[`/query2HoursBefore/${queryConfirmedKey}/surgery`] = null;
  }
  // New status method
  // updates[`/requests/${uid}/${agendaId}/confirmed/${appointmentId}/arrivalTime`] = time;
  updates[`/requests/${uid}/${agendaId}/confirmed/${appointmentId}/arrivalTime`] = dbServerTimestamp();
  updates[`/requests/${uid}/${agendaId}/confirmed/${appointmentId}/startedAttendance`] = null;
  updates[`/requests/${uid}/${agendaId}/confirmed/${appointmentId}/endTime`] = null;
  updates[`/requests/${uid}/${agendaId}/confirmed/${appointmentId}/patientDidNotShow`] = null;
  updates[`/requests/${uid}/${agendaId}/confirmed/${appointmentId}/appointmentMissed`] = null;
  updates[`/requests/${uid}/${agendaId}/confirmed/${appointmentId}/status`] = 'arrived';
  updates[`/requests/${uid}/${agendaId}/confirmed/${appointmentId}/selectedLabel`] = null;
  return update(dbRef, updates);
}

function messageWasReadDB(appointment, agendaId, mainUser) {
  const dbRef = ref(getDatabase());
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  const path = appointment.type;
  updates[`/requests/${uid}/${agendaId}/${path}/${appointment.id}/hasUnreadMessage`] = false;
  return update(dbRef, updates);
}

export function* patientAttendanceStarted() {
  yield takeLatest(actions.PATIENT_BEING_ATTENDING_REQUEST, function* (action) {
    try {
      yield put({
        type: actions.START_PATIENT_BEING_ATTENDING,
        payload: action.payload.id,
      });
      yield call(sleep, 500);
      const agendaId = yield select(getSelectedAgendaFromStore);
      const mainUser = yield select(getMainUserFromStore);
      yield call(
        patientAttendanceStartedOnDB,
        action.payload.id,
        agendaId,
        action.payload.queryConfirmedKey,
        mainUser,
      );
      yield all([
        put({ type: actions.PATIENT_BEING_ATTENDING_SUCCESS }),
        put({
          type: calendarActions.UPDATE_SELECTED_DATA_REQUEST,
          payload: action.payload.id,
        }),
      ]);
    } catch (error) {
      console.warn(error);
      yield put({ type: actions.PATIENT_BEING_ATTENDING_ERROR });
    }
  });
}

export function* patientMissedAppointment() {
  yield takeLatest(actions.PATIENT_MISSED_APPOINTMENT_REQUEST, function* (action) {
    try {
      yield put({
        type: actions.START_SAVING_PATIENT_MISSED_APPOINTMENT,
        payload: action.payload.id,
      });
      yield call(sleep, 500);
      const agendaId = yield select(getSelectedAgendaFromStore);
      const mainUser = yield select(getMainUserFromStore);
      yield call(
        patientMissedAppointmentOnDB,
        action.payload.id,
        agendaId,
        action.payload.queryConfirmedKey,
        mainUser,
      );
      yield all([
        put({ type: actions.PATIENT_MISSED_APPOINTMENT_SUCCESS }),
        put({
          type: calendarActions.UPDATE_SELECTED_DATA_REQUEST,
          payload: action.payload.id,
        }),
      ]);
    } catch (error) {
      console.warn(error);
      yield put({ type: actions.PATIENT_MISSED_APPOINTMENT_ERROR });
    }
  });
}

export function* patientArrivedTime() {
  yield takeLatest(actions.ARRIVED_TIME_REQUEST, function* (action) {
    try {
      yield put({
        type: actions.START_SAVING_ARRIVED_TIME,
        payload: action.payload.id,
      });
      yield call(sleep, 500);
      const agendaId = yield select(getSelectedAgendaFromStore);
      const mainUser = yield select(getMainUserFromStore);
      yield call(
        addArrivalTimeOnDB,
        action.payload.id,
        agendaId,
        action.payload.queryConfirmedKey,
        mainUser,
      );
      yield all([
        put({ type: actions.ARRIVED_TIME_SUCCESS }),
        put({
          type: calendarActions.UPDATE_SELECTED_DATA_REQUEST,
          payload: action.payload.id,
        }),
      ]);
    } catch (error) {
      console.warn(error);
      yield put({ type: actions.ARRIVED_TIME_ERROR });
    }
  });
}

export function* messageWasRead() {
  yield takeLatest(actions.MESSAGE_READ_REQUEST, function* (action) {
    try {
      yield put({
        type: actions.START_SAVING_MESSAGE_READ,
        payload: action.payload.id,
      });
      yield call(sleep, 500);
      const agendaId = yield select(getSelectedAgendaFromStore);
      const mainUser = yield select(getMainUserFromStore);
      yield call(
        messageWasReadDB,
        action.payload,
        agendaId,
        mainUser,
      );
      yield put({
        type: actions.MESSAGE_READ_SUCCESS,
        payload: action.payload.id,
      });
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.MESSAGE_READ_ERROR,
        payload: action.payload.id,
      });
    }
  });
}

function editAppointmentNotesOnDB(appointmentId, agendaId, notes, mainUser) {
  const dbRef = ref(getDatabase());
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  updates[`/requests/${uid}/${agendaId}/confirmed/${appointmentId}/notes`] = notes;
  return update(dbRef, updates);
}

export function* editAppointmentNotesRequest() {
  yield takeLatest(actions.EDIT_APPOINTMENT_NOTES_REQUEST, function* (action) {
    try {
      yield put({ type: actions.SAVING_APPOINTMENT_NOTES });
      yield call(sleep, 500);
      const agendaId = yield select(getSelectedAgendaFromStore);
      const mainUser = yield select(getMainUserFromStore);
      yield call(
        editAppointmentNotesOnDB,
        action.payload.selectedData.id,
        agendaId,
        action.payload.text,
        mainUser,
      );
      // yield put({
      //   type: actions.EDIT_APPOINTMENT_NOTES_SUCCESS,
      //   payload: action.payload.text,
      // });
      yield all([
        put({
          type: actions.EDIT_APPOINTMENT_NOTES_SUCCESS,
          payload: {
            text: action.payload.text,
            id: action.payload.selectedData.id,
          },
        }),
        put({
          type: calendarActions.UPDATE_SELECTED_DATA_REQUEST,
          payload: action.payload.selectedData.id,
        }),
      ]);
    } catch (error) {
      console.warn(error);
      yield put({ type: actions.EDIT_APPOINTMENT_NOTES_ERROR });
    }
  });
}

function editManualConfirmationMessageOnDB(appointmentId, agendaId, manualConfirmationValue, mainUser) {
  const dbRef = ref(getDatabase());
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  updates[`/requests/${uid}/${agendaId}/confirmed/${appointmentId}/manualConfirmationMessageSent`] = manualConfirmationValue;
  return update(dbRef, updates);
}

export function* setManualConfirmationMessageSent() {
  yield takeLatest(actions.SET_MANUAL_CONFIRMATION_MESSAGE_SENT_REQUEST, function* (action) {
    try {
      yield call(sleep, 500);
      const agendaId = yield select(getSelectedAgendaFromStore);
      const mainUser = yield select(getMainUserFromStore);
      yield call(
        editManualConfirmationMessageOnDB,
        action.payload.selectedData.id,
        agendaId,
        action.payload.manualConfirmationValue,
        mainUser,
      );
    } catch (error) {
      console.warn(error);
      yield put({ type: actions.SET_MANUAL_CONFIRMATION_MESSAGE_SENT_ERROR });
    }
  });
}

function finalizeAppointmentOnDB(
  selectedData,
  key,
  patient,
  lastAppointment,
  proceduresType,
  procedures,
  addressUid,
  agendaId,
  idToken,
  unified,
  mainUser,
) {
  const auth = getAuth();
  const { currentUser } = auth;
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    ({ uid } = currentUser);
  }
  const config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };
  const bodyParameters = {
    uid,
    addressUid,
    agendaId,
    selectedData,
    patient,
    key,
    lastAppointment,
    proceduresType,
    procedures,
    currentUser: currentUser.uid,
  };
  // return true;
  const analytics = getAnalytics();
  logEvent(analytics, 'finalize_appointment', {
    professional_id: currentUser.uid,
  });
  return axios.post(
    `${ROOT_URL}/finalizeAppointment`,
    bodyParameters,
    config,
  );
}

export function* finalizeAppointmentRequest() {
  yield takeEvery(actions.FINALIZE_APPOINTMENT_REQUEST, function* (action) {
    try {
      yield put({
        type: actions.START_FINALIZING_APPOINTMENT,
        payload: action.payload.user,
      });
      const idToken = yield call(getIdToken);
      const unified = yield select(getUnifiedTokenStore);
      const addressUid = yield select(getSelectedAddressFromStore);
      const agendaId = yield select(getSelectedAgendaFromStore);
      const mainUser = yield select(getMainUserFromStore);
      const lastAppointment = {
        id: action.payload.id,
        time: action.payload.time,
        // mode: action.payload.mode,
        // plan: action.payload.plan ? action.payload.plan : null,
        isReturn: action.payload.isReturn,
      };
      if (unified.id && unified.address.some((a) => a === addressUid)) {
        let profile = yield select(getProfileFromStore);
        if (_.isEmpty(profile)) {
          yield take(profileActions.PROFILE_INFO_SUCCESS);
          profile = yield select(getProfileFromStore);
        }
        // so ta pra unificado, agora com centro medico, faz sentido ter pra todos
        lastAppointment.professional = {
          firstName: profile.firstName,
          lastName: profile.lastName,
        };
      }
      let proceduresType = 'lastProceduresArr';
      let procedures = null;
      if (action.payload.proceduresArr) {
        procedures = { ...action.payload.proceduresArr };
      } else if (action.payload.procedure) {
        proceduresType = 'lastProcedure';
        procedures = {
          ...action.payload.procedure,
          plan: action.payload.plan ? action.payload.plan : null,
        };
      }
      yield call(
        finalizeAppointmentOnDB,
        action.payload,
        action.payload.id,
        action.payload.user,
        lastAppointment,
        proceduresType,
        procedures,
        addressUid,
        agendaId,
        idToken,
        unified,
        mainUser,
      );
      yield all([
        put({
          type: contactActions.FETCH_PATIENT_DATA,
          payload: {
            id: action.payload.user,
            appointment: action.payload,
          },
        }),
        put({ type: actions.FINALIZE_APPOINTMENT_SUCCESS }),
        put({
          type: calendarActions.UPDATE_SELECTED_DATA_REQUEST,
          payload: action.payload.id,
        }),
      ]);
      const appointmentHistory = yield select(getAppoitmentHistoryFromStore);
      if (appointmentHistory?.[action.payload.user]) {
        // Reset appointment history so it is downloaded again
        yield put({
          type: contactActions.RESET_PATIENT_HISTORY,
        });
      }
    } catch (error) {
      console.warn(error);
      yield put({ type: actions.FINALIZE_APPOINTMENT_ERROR });
    }
  });
}

function confirmPatientAppointmentOnDB(
  appointmentId,
  sendMessagesActive,
  queryConfirmedKey,
  agendaId,
  mainUser,
) {
  const dbRef = ref(getDatabase());
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  // updates[`/requests/${uid}/${agendaId}/confirmed/${appointmentId}/arrivalTime`] = null;
  updates[`/requests/${uid}/${agendaId}/confirmed/${appointmentId}/startedAttendance`] = null;
  updates[`/requests/${uid}/${agendaId}/confirmed/${appointmentId}/endTime`] = null;
  updates[`/requests/${uid}/${agendaId}/confirmed/${appointmentId}/patientDidNotShow`] = null;
  updates[`/requests/${uid}/${agendaId}/confirmed/${appointmentId}/appointmentMissed`] = null;
  updates[`/requests/${uid}/${agendaId}/confirmed/${appointmentId}/status`] = 'confirmed';
  updates[`/requests/${uid}/${agendaId}/confirmed/${appointmentId}/selectedLabel`] = null;
  // Cleaning queryConfirmed
  if (sendMessagesActive && queryConfirmedKey) {
    updates[`/queryConfirmed/${queryConfirmedKey}/finalized`] = null;
    updates[`/queryConfirmed/${queryConfirmedKey}/surgery`] = null;
    updates[`/queryConfirmed/${queryConfirmedKey}/manuallyConfirmed`] = true;
    // Cleaning query2HoursBefore
    updates[`/query2HoursBefore/${queryConfirmedKey}/finalized`] = null;
    updates[`/query2HoursBefore/${queryConfirmedKey}/surgery`] = null;
  }
  const analytics = getAnalytics();
  logEvent(analytics, 'confirm_appointment', {
    professional_id: uid,
  });
  return update(dbRef, updates);
}

export function* patientConfirmedAppointmentRequest() {
  yield takeEvery(actions.PATIENT_CONFIRMED_APPOINTMENT_REQUEST, function* (action) {
    try {
      yield put({
        // type: actions.START_UPDATING_REQUEST,
        type: actions.START_CONFIRMING_PATIENT_REQUEST,
        payload: action.payload.user,
      });
      yield call(sleep, 500);
      // const idToken = yield call(getIdToken);
      const agendaId = yield select(getSelectedAgendaFromStore);
      const mainUser = yield select(getMainUserFromStore);
      yield call(
        confirmPatientAppointmentOnDB,
        action.payload.id,
        action.payload.sendMessages,
        action.payload.queryConfirmedKey,
        agendaId,
        mainUser,
      );
      yield all([
        put({ type: actions.PATIENT_CONFIRMED_APPOINTMENT_SUCCESS }),
        put({
          type: calendarActions.UPDATE_SELECTED_DATA_REQUEST,
          payload: action.payload.id,
        }),
      ]);
    } catch (error) {
      console.warn(error);
      yield put({ type: actions.PATIENT_CONFIRMED_APPOINTMENT_ERROR });
    }
  });
}

function cancelAppointmentOnDB(
  selectedData,
  key,
  patient,
  addressUid,
  agendaId,
  idToken,
  mainUser,
) {
  const auth = getAuth();
  const { currentUser } = auth;
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    ({ uid } = currentUser);
  }
  const config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };
  const bodyParameters = {
    uid,
    addressUid,
    agendaId,
    selectedData,
    key,
    patient,
    currentUser: currentUser.uid,
  };
  // return true;
  const analytics = getAnalytics();
  logEvent(analytics, 'cancel_appointment', {
    professional_id: currentUser.uid,
  });
  return axios.post(
    `${ROOT_URL}/cancelAppointment`,
    bodyParameters,
    config,
  );
}

function handleDeleteEvent(idEvent, videoCallCreator) {
  return window.gapi.client.calendar.events.delete({
    calendarId: `${videoCallCreator}`,
    eventId: `${idEvent}`,
  });
}

export function* cancelAppointmentRequest() {
  yield takeEvery(actions.CANCEL_APPOINTMENT_REQUEST, function* (action) {
    try {
      yield put({
        type: actions.START_CANCELING_APPOINTMENT,
        payload: action.payload.user,
      });
      let profile = yield select(getProfileFromStore);
      if (_.isEmpty(profile)) {
        yield take(profileActions.PROFILE_INFO_SUCCESS);
        profile = yield select(getProfileFromStore);
      }
      let professionalInfo = { ...profile };
      delete professionalInfo.keywords;
      delete professionalInfo.addresses;
      const idToken = yield call(getIdToken);
      const addressUid = yield select(getSelectedAddressFromStore);
      const agendaId = yield select(getSelectedAgendaFromStore);
      const customUsers = yield select(getCustomUsersFromStore);
      const mainUser = yield select(getMainUserFromStore);
      let gisUserProfile = yield select(getGisUserProfileFromStore);
      // const addressInfo = profile.addresses.find((address) => address.id === addressUid);
      if (action.payload.professional && action.payload.professional !== professionalInfo.id) {
        const foundProfessional = customUsers.find((el) => action.payload.professional === el.id);
        professionalInfo = { ...foundProfessional };
      }
      if (action.payload.isVideoCall && action.payload.videoCallMode === 'meets' && action.payload.videoCallData) {
        const {
          videoCallCreator,
          videoCallId,
        } = action.payload.videoCallData;
        if (!gisUserProfile) {
          notificationAntd.warning({
            message: 'Você não está logado com a conta Google utilizada ao criar a consulta.',
            description: (
              <span>
                As alterações de horário ou duração da consulta não serão atualizadas no seu Google Calendar.&nbsp;
                <Button
                  type="link"
                  style={{ padding: 0, height: '21px' }}
                  onClick={() => {
                    googleAuthRequestChannel.put({ type: gapiActions.GIS_AUTHORIZATION_REQUEST });
                  }}
                >
                  Clique aqui para acessar sua conta Google
                </Button>
                &nbsp;ou&nbsp;
                <Button
                  type="link"
                  style={{ padding: 0, height: '21px' }}
                  onClick={() => {
                    googleAuthRequestChannel.put({ type: gapiActions.CONTINUE_WITHOUT_GIS_AUTHORIZATION });
                  }}
                >
                  continue sem uma conta Google.
                </Button>
              </span>
            ),
            duration: null,
            key: 'gapi-notification',
            onClose: () => googleAuthRequestChannel.put({ type: gapiActions.CONTINUE_WITHOUT_GIS_AUTHORIZATION }),
          });
          const actionTriggered = yield take([
            gapiActions.CONTINUE_WITHOUT_GIS_AUTHORIZATION,
            gapiActions.SET_GIS_ACCESS_TOKEN,
          ]);
          notificationAntd.destroy('gapi-notification');
          if (actionTriggered.type === 'SET_GIS_ACCESS_TOKEN') {
            gisUserProfile = yield select(getGisUserProfileFromStore);
          }
        }
        if (!window.gapi || !gisUserProfile) {
          notification(
            'error',
            'Algo deu errado para se comunicar com os servidores da Google',
            'As alterações de horário ou duração da consulta não serão atualizadas no seu Google Calendar.',
          );
        } else if (window.gapi.client.getToken() === null) {
          notification(
            'error',
            'Não há permissão suficiente de uma conta no Google Calendar para habilitar vídeo chamada',
            'As alterações de horário ou duração da consulta não serão atualizadas no seu Google Calendar.',
          );
        } else if (gisUserProfile?.email === action.payload?.videoCallData?.videoCallCreator) {
          // User is logged in and it is the same account when created.
          yield call(handleDeleteEvent, videoCallId, videoCallCreator);
        } else if (gisUserProfile?.email !== action.payload?.videoCallData?.videoCallCreator) {
          // User is logged in but it is NOT the same account when created.
          notification(
            'warning',
            'A conta Google não é a mesma utilizada ao criar a consulta',
            'As alterações de horário ou duração da consulta não serão atualizadas no seu Google Calendar.',
          );
          // throw new Error('Wrong Google account connected.');
        }
      }
      yield call(
        cancelAppointmentOnDB,
        action.payload,
        action.payload.id,
        action.payload.user,
        addressUid,
        agendaId,
        idToken,
        mainUser,
      );
      yield all([
        put({ type: actions.CANCEL_APPOINTMENT_SUCCESS }),
        put({
          type: calendarActions.UPDATE_SELECTED_DATA_REQUEST,
          payload: action.payload.id,
        }),
      ]);
      const appointmentHistory = yield select(getAppoitmentHistoryFromStore);
      if (appointmentHistory?.[action.payload.user]) {
        // Reset appointment history so it is downloaded again
        yield put({
          type: contactActions.RESET_PATIENT_HISTORY,
        });
      }
      if (action.payload.sendMessages) {
        yield put({
          type: calendarActions.CALENDAR_EVENT_SELECTED,
          payload: {
            modalVisible: 'manualAppointmentMessagesModel',
            selectedData: action.payload,
          },
        });

        // const fullMessagesSettings = yield select(getFullMessagesSettingsFromStore);
        // const manuallySendMessage = !fullMessagesSettings?.[professionalInfo.id]?.sendAutomaticMessages;
        // if (manuallySendMessage) {
        //   yield all([
        //     put({
        //       type: calendarActions.CALENDAR_EVENT_SELECTED,
        //       payload: {
        //         modalVisible: 'manualAppointmentMessagesModel',
        //         selectedData: action.payload,
        //       },
        //     }),
        //   ]);
        // } else {
        //   yield spawn(
        //     sendMessages,
        //     action.payload.id,
        //     action.payload.user,
        //     action.payload.userInfo,
        //     action.payload.queryConfirmedKey,
        //     professionalInfo,
        //     action.payload.time,
        //     action.payload.requestedOnline,
        //     addressInfo,
        //     addressUid,
        //     agendaId,
        //     idToken,
        //     'cancel',
        //     mainUser,
        //   );
        // }
      }
    } catch (error) {
      if (error.request && error.request.response) {
        const errorObj = JSON.parse(error.request.response);
        if (errorObj.status === 'already-canceled') {
          yield put({ type: actions.APPOINTMENT_DOES_NOT_EXIST });
        } else {
          yield put({ type: actions.CANCEL_APPOINTMENT_ERROR });
        }
      } else {
        yield put({ type: actions.CANCEL_APPOINTMENT_ERROR });
      }
    }
  });
}

function setSurgeryOnOnDB(
  appointmentId,
  agendaId,
  sendMessagesActive,
  queryConfirmedKey,
  mainUser,
) {
  const dbRef = ref(getDatabase());
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  // New status method
  updates[`/requests/${uid}/${agendaId}/confirmed/${appointmentId}/startedAttendance`] = null;
  updates[`/requests/${uid}/${agendaId}/confirmed/${appointmentId}/arrivalTime`] = null;
  updates[`/requests/${uid}/${agendaId}/confirmed/${appointmentId}/endTime`] = null;
  updates[`/requests/${uid}/${agendaId}/confirmed/${appointmentId}/patientDidNotShow`] = null;
  updates[`/requests/${uid}/${agendaId}/confirmed/${appointmentId}/appointmentMissed`] = null;
  updates[`/requests/${uid}/${agendaId}/confirmed/${appointmentId}/status`] = 'surgery';
  updates[`/requests/${uid}/${agendaId}/confirmed/${appointmentId}/selectedLabel`] = null;
  if (sendMessagesActive && queryConfirmedKey) {
    updates[`/queryConfirmed/${queryConfirmedKey}/finalized`] = null;
    updates[`/queryConfirmed/${queryConfirmedKey}/manuallyConfirmed`] = null;
    updates[`/queryConfirmed/${queryConfirmedKey}/surgery`] = true;
    // Cleaning query2HoursBefore
    updates[`/query2HoursBefore/${queryConfirmedKey}/finalized`] = null;
    updates[`/query2HoursBefore/${queryConfirmedKey}/surgery`] = true;
  }
  return update(dbRef, updates);
}

export function* setSurgeryRequest() {
  yield takeEvery(actions.SET_SURGERY_REQUEST, function* (action) {
    try {
      yield put({
        type: actions.START_SET_SURGERY,
        payload: action.payload.id,
      });
      yield call(sleep, 500);
      const agendaId = yield select(getSelectedAgendaFromStore);
      const mainUser = yield select(getMainUserFromStore);
      yield call(
        setSurgeryOnOnDB,
        action.payload.id,
        agendaId,
        action.payload.sendMessages,
        action.payload.queryConfirmedKey,
        mainUser,
      );
      yield all([
        put({ type: actions.SET_SURGERY_SUCCESS }),
        put({
          type: calendarActions.UPDATE_SELECTED_DATA_REQUEST,
          payload: action.payload.id,
        }),
      ]);
    } catch (error) {
      console.warn(error);
      yield put({ type: actions.SET_SURGERY_ERROR });
    }
  });
}

function savePatientWhatsAppOnDB(
  patientWhatsApp,
  seectedId,
  addressUid,
  unified = {},
  mainUser,
) {
  const dbRef = ref(getDatabase());
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  if (unified.id && unified.address.some((a) => a === addressUid)) {
    updates[`unified/${unified.id}/patients/${seectedId}/mobile`] = patientWhatsApp;
  } else {
    updates[`users/${uid}/patients/${seectedId}/mobile`] = patientWhatsApp;
  }
  return update(dbRef, updates);
}

function manuallySetAppointmentOnDB(
  {
    appointment, key, addressUid, agendaId, professionalInfo, addressInfo,
  },
  idToken,
  mainUser,
) {
  const auth = getAuth();
  const { currentUser } = auth;
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    ({ uid } = currentUser);
  }
  const config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };
  const bodyParameters = {
    uid,
    addressUid,
    agendaId,
    professionalProfile: professionalInfo,
    addressInfo,
    patient: appointment.user,
    appointment,
    key,
    currentUser: currentUser.uid,
  };
  const analytics = getAnalytics();
  logEvent(analytics, 'create_appointment', {
    professional_id: currentUser.uid,
    patient_id: appointment.user,
    appointment_duration: appointment.duration,
    appointment_date: appointment.time,
  });
  return axios.post(
    `${ROOT_URL}/manuallyCreateAppointment`,
    bodyParameters,
    config,
  );
}

function handleCreateGoogleMeetingEvent(requestId, date, duration, selectedPatientInfo, recurrence = null) {
  const calendarId = 'primary';
  const startTime = moment(date, 'YYYY-MM-DD HH:mm').tz('America/Sao_Paulo').format();
  const splittedDuration = duration.split(':');
  const endTime = moment(date, 'YYYY-MM-DD HH:mm').add(moment.duration(duration).asMinutes(), 'm').format();
  const event = {
    start: { dateTime: startTime, timeZone: 'America/Sao_Paulo' },
    end: { dateTime: endTime, timeZone: 'America/Sao_Paulo' },
    // attendees: [{ email: `${selectedData.userInfo.email} ` }],
    conferenceData: {
      createRequest: {
        requestId,
        conferenceSolutionKey: { type: 'hangoutsMeet' },
      },
    },
    summary: `Chamada de vídeo com o paciente ${selectedPatientInfo.firstName} ${selectedPatientInfo.lastName}`,
    description: `Consulta com duração de ${splittedDuration[0]}h:${splittedDuration[1]}m
    para o paciente ${selectedPatientInfo.firstName} ${selectedPatientInfo.lastName}`,
  };
  if (recurrence) {
    event.recurrence = [recurrence];
  }
  return window.gapi.client.calendar.events
    .insert({
      calendarId,
      conferenceDataVersion: 1,
      resource: event,
    });
}

export function* manuallySetNewAppointmentRequest() {
  yield takeEvery(actions.MANUALLY_SET_NEW_APPOINTMENT_REQUEST, function* (action) {
    try {
      yield put({ type: actions.SETTING_NEW_APPOINTMENT });
      // yield call(sleep, 5000);
      const unifiedToken = yield select(getUnifiedTokenStore);
      const mainUser = yield select(getMainUserFromStore);
      const addressUid = yield select(getSelectedAddressFromStore);
      const agendaId = yield select(getSelectedAgendaFromStore);
      const customUsers = yield select(getCustomUsersFromStore);
      const {
        user,
        isVideoCall,
        videoCallMode,
        time,
        duration,
        patientWhatsApp,
      } = action.payload;
      let profile = yield select(getProfileFromStore);
      if (_.isEmpty(profile)) {
        yield take(profileActions.PROFILE_INFO_SUCCESS);
        profile = yield select(getProfileFromStore);
      }
      let professionalInfo = { ...profile };
      delete professionalInfo.keywords;
      delete professionalInfo.addresses;
      if (action.payload.professional && action.payload.professional !== professionalInfo.id) {
        const foundProfessional = customUsers.find((el) => action.payload.professional === el.id);
        professionalInfo = { ...foundProfessional };
      }
      let patients = yield select(getPatientsFromStore);
      let userInfo = patients.find((contact) => contact.id === user);
      if (patientWhatsApp && userInfo?.mobile !== patientWhatsApp) {
        yield call(
          savePatientWhatsAppOnDB,
          patientWhatsApp,
          user,
          addressUid,
          unifiedToken,
          mainUser,
        );
        yield put({
          type: contactActions.FETCH_PATIENT_DATA,
          payload: {
            id: user,
          },
        });
        yield take(contactActions.PATIENTS_FETCH_SUCCESS);
        patients = yield select(getPatientsFromStore);
        userInfo = patients.find((contact) => contact.id === user);
      }

      const addressInfo = profile.addresses.find((address) => address.id === addressUid);
      const idToken = yield call(getIdToken);
      const timestamp = moment().tz('America/Sao_Paulo').format();
      const db = getDatabase();
      let uid;
      if (mainUser) {
        uid = mainUser;
      } else {
        const auth = getAuth();
        const { currentUser } = auth;
        ({ uid } = currentUser);
      }
      const pushKey = push(child(ref(db), `/requests/${uid}/${agendaId}/confirmed`)).key;
      const queryConfirmedKey = push(child(ref(db), '/queryConfirmed')).key;
      let videoCallLink = '';
      let videoCallCreator = '';
      let videoCallId = '';
      if (isVideoCall && videoCallMode === 'meets') {
        // if (!googleAuthInstance?.isSignedIn?.get()) {
        // if (!gisUserPayload) {
        if (!window.gapi) {
          notification('error', 'Algo deu errado para se comunicar com os servidores da Google');
          throw new Error('No Gapi loaded.');
        } else if (window.gapi.client.getToken() === null) {
          notification('error', 'Não há permissão suficiente de uma conta no Google Calendar para habilitar vídeo chamada');
          throw new Error('No Google account connected.');
        } else {
          const videoCallLinkInteiro = yield call(
            handleCreateGoogleMeetingEvent,
            pushKey,
            time,
            duration,
            userInfo,
          );
          videoCallCreator = videoCallLinkInteiro.result.creator.email;
          videoCallLink = videoCallLinkInteiro.result.hangoutLink;
          videoCallId = videoCallLinkInteiro.result.id;
        }
      }
      const videoCallData = {
        videoCallLink,
        videoCallCreator,
        videoCallId,
      };
      // const automaticMessage = yield select(getActiveMessageBot);
      const fullMessagesSettings = yield select(getFullMessagesSettingsFromStore);
      const manuallySendMessage = !fullMessagesSettings?.[professionalInfo.id]?.sendAutomaticMessages;
      const { data } = yield call(manuallySetAppointmentOnDB, {
        appointment: {
          ...action.payload,
          manuallySendMessage,
          timestamp,
          queryConfirmedKey,
          isVideoCall,
          videoCallMode,
          videoCallData,
          address: addressUid,
        },
        key: pushKey,
        addressUid,
        agendaId,
        professionalInfo,
        addressInfo,
      }, idToken, mainUser);
      yield put({
        type: actions.MANUALLY_SET_NEW_APPOINTMENT_SUCCESS,
      });
      if (data?.selectedData) {
        if (action.payload.sendMessages) {
          yield put({
            type: calendarActions.CALENDAR_EVENT_SELECTED,
            payload: {
              modalVisible: 'manualAppointmentMessagesModel',
              selectedData: data.selectedData,
            },
          });

          // if (manuallySendMessage) {
          //   yield all([
          //     put({
          //       type: calendarActions.CALENDAR_EVENT_SELECTED,
          //       payload: {
          //         modalVisible: 'manualAppointmentMessagesModel',
          //         selectedData: data.selectedData,
          //       },
          //     }),
          //   ]);
          // } else {
          //   yield spawn(
          //     sendMessages,
          //     pushKey,
          //     user,
          //     userInfo,
          //     queryConfirmedKey,
          //     professionalInfo,
          //     action.payload.time,
          //     action.payload.requestedOnline,
          //     addressInfo,
          //     addressUid,
          //     agendaId,
          //     idToken,
          //     'create',
          //     mainUser,
          //   );
          // }
        }
      }
    } catch (error) {
      console.warn(error);
      console.warn(error.message);
      if (error?.result?.error?.message) {
        yield put({
          type: gapiActions.RESET_GIS_ACCESS_TOKEN,
        });
      }
      yield put({
        type: actions.MANUALLY_SET_NEW_APPOINTMENT_FAIL,
      });
      if (error.message === 'No Google account connected.') {
        yield call(sleep, 1000);
        yield put({ type: gapiActions.GIS_AUTHORIZATION_REQUEST });
      }
      if (error?.result?.error?.status === 'PERMISSION_DENIED') {
        notificationAntd.warning({
          message: 'É necessário conceder permissões para o Meagenda acessar o Google Calendar',
          description: (
            <span>
              É necessário se autenticar novamente para conceder as permissões.&nbsp;
              <Button
                type="link"
                style={{
                  padding: 0,
                  height: '21px',
                  whiteSpace: 'break-spaces',
                  textAlign: 'start',
                }}
                onClick={() => {
                  googleAuthRequestChannel.put({ type: gapiActions.GIS_AUTHORIZATION_REQUEST });
                  notificationAntd.destroy('gapi-notification');
                }}
              >
                Clique aqui para fazer autenticação da sua conta Google
              </Button>
            </span>
          ),
          duration: null,
          key: 'gapi-notification',
        });
      }
      if (error?.result?.error?.status === 'UNAUTHENTICATED') {
        notification('error', 'A sua conta Google não está conectada ou sua sessão expirou.');
        yield call(sleep, 2000);
        // yield put({ type: gapiActions.GOOGLE_AUTH_REQUEST });
        yield put({ type: gapiActions.GIS_AUTHORIZATION_REQUEST });
      }
    }
  });
}

function editWholeAppointmentOnDB(
  {
    selectedData, params, addressUid, agendaId, professionalInfo,
  },
  idToken,
  mainUser,
  videoCallLink,
  isVideoCall,
  videoCallMode,
  videoCallCreator,
  videoCallId,
) {
  const auth = getAuth();
  const { currentUser } = auth;
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    ({ uid } = currentUser);
  }
  const config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };
  const bodyParameters = {
    uid,
    addressUid,
    agendaId,
    professionalInfo,
    selectedData,
    params,
    videoCallLink,
    isVideoCall,
    videoCallMode,
    videoCallCreator,
    videoCallId,
    currentUser: currentUser.uid,
  };
  // return { status: 201 };
  return axios.post(
    `${ROOT_URL}/editWholeAppointment`,
    bodyParameters,
    config,
  );
}

function resetEditMessageSent(agendaId, key, mainUser) {
  const dbRef = ref(getDatabase());
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  updates[`/requests/${uid}/${agendaId}/confirmed/${key}/editMessageSent`] = false;
  return update(dbRef, updates);
}

export function* editWholeAppointmentRequest() {
  yield takeEvery(actions.EDIT_WHOLE_APPOINTMENT_REQUEST, function* (action) {
    try {
      yield put({ type: actions.START_EDITING_APPOINTMENT_REQUEST });
      const addressUid = yield select(getSelectedAddressFromStore);
      const agendaId = yield select(getSelectedAgendaFromStore);
      const customUsers = yield select(getCustomUsersFromStore);
      let profile = yield select(getProfileFromStore);
      if (_.isEmpty(profile)) {
        yield take(profileActions.PROFILE_INFO_SUCCESS);
        profile = yield select(getProfileFromStore);
      }
      const patients = yield select(getPatientsFromStore);
      let gisUserProfile = yield select(getGisUserProfileFromStore);

      const {
        isVideoCall,
        videoCallMode,
        time,
        duration,
        // changeVideoCallCreator,
        wasVideoCall,
        isScheduleEqual,
        isDurationEqual,
      } = action.payload.params;

      const {
        user,
      } = action.payload.selectedData;
      const userInfo = patients.find((contact) => contact.id === user);
      let { videoCallLink, videoCallCreator, videoCallId } = action.payload.params;
      let isMeetsVideoCall = false;
      if (isVideoCall && videoCallMode === 'meets') {
        isMeetsVideoCall = true;
      }
      let wasMeetsVideoCall = false;
      if (wasVideoCall && action.payload.selectedData?.videoCallMode === 'meets') {
        wasMeetsVideoCall = true;
      }
      if (isMeetsVideoCall) {
        if (!gisUserProfile) {
          notificationAntd.warning({
            message: 'Você não está logado com a conta Google utilizada ao criar a consulta.',
            description: (
              <span>
                As alterações de horário ou duração da consulta não serão atualizadas no seu Google Calendar.&nbsp;
                <Button
                  type="link"
                  style={{ padding: 0, height: '21px' }}
                  onClick={() => {
                    googleAuthRequestChannel.put({ type: gapiActions.GIS_AUTHORIZATION_REQUEST });
                  }}
                >
                  Clique aqui para acessar sua conta Google
                </Button>
                &nbsp;ou&nbsp;
                <Button
                  type="link"
                  style={{ padding: 0, height: '21px' }}
                  onClick={() => {
                    googleAuthRequestChannel.put({ type: gapiActions.CONTINUE_WITHOUT_GIS_AUTHORIZATION });
                  }}
                >
                  continue sem uma conta Google.
                </Button>
              </span>
            ),
            duration: null,
            key: 'gapi-notification',
            onClose: () => googleAuthRequestChannel.put({ type: gapiActions.CONTINUE_WITHOUT_GIS_AUTHORIZATION }),
          });
          const actionTriggered = yield take([
            gapiActions.CONTINUE_WITHOUT_GIS_AUTHORIZATION,
            gapiActions.SET_GIS_ACCESS_TOKEN,
          ]);
          notificationAntd.destroy('gapi-notification');
          if (actionTriggered.type === 'SET_GIS_ACCESS_TOKEN') {
            gisUserProfile = yield select(getGisUserProfileFromStore);
          }
        }
        if (wasMeetsVideoCall) {
          // It IS and it already WAS video call.
          if (!window.gapi || !gisUserProfile) {
            notification(
              'error',
              'Algo deu errado para se comunicar com os servidores da Google',
              'As alterações de horário ou duração da consulta não serão atualizadas no seu Google Calendar.',
            );
          } else if (window.gapi.client.getToken() === null) {
            notification(
              'error',
              'Não há permissão suficiente de uma conta no Google Calendar para habilitar vídeo chamada',
              'As alterações de horário ou duração da consulta não serão atualizadas no seu Google Calendar.',
            );
          } else if (gisUserProfile?.email === action.payload.selectedData?.videoCallData?.videoCallCreator) {
            // User is logged in and it is the same account when created.
            if (!isScheduleEqual || !isDurationEqual) {
              // Appointment time or duration has changed,
              // need to change it on calendar.
              yield call(handleUpdateEvent, videoCallId, videoCallCreator, time, duration, userInfo);
            }
          } else if (gisUserProfile?.email !== action.payload.selectedData?.videoCallData?.videoCallCreator) {
            // User is logged in but it is NOT the same account when created.
            if (!isScheduleEqual || !isDurationEqual) {
              // Appointment time or duration has changed.
              notification(
                'warning',
                'A conta Google não é a mesma utilizada ao criar a consulta',
                'As alterações de horário ou duração da consulta não serão atualizadas no seu Google Calendar.',
              );
              // throw new Error('Wrong Google account connected.');
            }
          }
        }

        if (!wasMeetsVideoCall) {
          // It is video call now, before it was not.
          if (window.gapi.client.getToken() === null || !gisUserProfile) {
            // User is not logged in.
            notification('error', 'Não foi possível entrar em uma conta no Google Calendar para habilitar vídeo chamada.');
            throw new Error('No Google account connected.');
          } else {
            // User is logged in.
            // Proceed to create Google event.
            const fullVideoCallLink = yield call(
              handleCreateGoogleMeetingEvent,
              action.payload.selectedData.id,
              time,
              duration,
              userInfo,
            );
            videoCallLink = fullVideoCallLink.result.hangoutLink;
            videoCallCreator = fullVideoCallLink.result.creator.email;
            videoCallId = fullVideoCallLink.result.id;
          }
        }
      } else if (wasMeetsVideoCall) {
        // Google Meets video call radio button is NOT selected,
        // but previously it was.
        if (gisUserProfile?.email === action.payload.selectedData?.videoCallData?.videoCallCreator) {
          try {
            yield call(handleDeleteEvent, videoCallId, videoCallCreator);
          } catch (error) {
            console.warn(error);
            if (error?.result?.error?.message === 'Resource has been deleted') {
              notification('info', 'O evento em seu Google Calendar já havia sido apagado.');
            } else {
              throw (error);
            }
          }
          videoCallLink = '';
          videoCallCreator = '';
          videoCallId = '';
        } else {
          // User is not connected to the correct Google account (or not connected at all).
          notification(
            'error',
            'A conta Google não é a mesma utilizada ao habilitar a teleconsulta',
            'É necessário estar conectado com a mesma conta para poder apagá-la do Google Calendar',
          );
          throw new Error('No correct Google account connected.');
        }
      }

      let professionalInfo = { ...profile };
      delete professionalInfo.keywords;
      delete professionalInfo.addresses;
      if (action.payload.params.professional && action.payload.params.professional !== professionalInfo.id) {
        const foundProfessional = customUsers.find((el) => action.payload.params.professional === el.id);
        professionalInfo = { ...foundProfessional };
      }
      // const addressInfo = profile.addresses.find((address) => address.id === addressUid);
      const idToken = yield call(getIdToken);
      const mainUser = yield select(getMainUserFromStore);
      const { status } = yield call(
        editWholeAppointmentOnDB,
        {
          selectedData: action.payload.selectedData,
          params: action.payload.params,
          addressUid,
          agendaId,
          professionalInfo,
        },
        idToken,
        mainUser,
        videoCallLink || '',
        isVideoCall || false,
        videoCallMode,
        videoCallCreator || '',
        videoCallId || '',
      );
      if (status === 201) {
        if (action.payload.params && action.payload.params.resendMessage) {
          yield put({
            type: calendarActions.CALENDAR_EVENT_SELECTED,
            payload: {
              modalVisible: 'manualAppointmentMessagesModel',
              selectedData: {
                ...action.payload.selectedData,
                time: action.payload?.params?.time,
              },
            },
          });

          // const fullMessagesSettings = yield select(getFullMessagesSettingsFromStore);
          // const manuallySendMessage = !fullMessagesSettings?.[professionalInfo.id]?.sendAutomaticMessages;
          // if (manuallySendMessage) {
          //   yield all([
          //     put({
          //       type: calendarActions.CALENDAR_EVENT_SELECTED,
          //       payload: {
          //         modalVisible: 'manualAppointmentMessagesModel',
          //         selectedData: {
          //           ...action.payload.selectedData,
          //           time: action.payload?.params?.time,
          //         },
          //       },
          //     }),
          //   ]);
          // } else {
          //   yield spawn(
          //     sendMessages,
          //     action.payload.selectedData.id,
          //     action.payload.selectedData.user,
          //     action.payload.selectedData.userInfo,
          //     action.payload.selectedData.queryConfirmedKey,
          //     professionalInfo,
          //     action.payload.params.time,
          //     action.payload.selectedData.requestedOnline,
          //     addressInfo,
          //     addressUid,
          //     agendaId,
          //     idToken,
          //     'edit',
          //     mainUser,
          //   );
          // }
        } else {
          yield call(resetEditMessageSent, agendaId, action.payload.selectedData.id, mainUser);
        }
        yield all([
          put({ type: actions.EDIT_WHOLE_APPOINTMENT_SUCCESS }),
          put({
            type: calendarActions.UPDATE_SELECTED_DATA_REQUEST,
            payload: action.payload.selectedData.id,
          }),
        ]);
      }
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.EDIT_WHOLE_APPOINTMENT_ERROR,
      });
      if (error?.result?.error?.status === 'PERMISSION_DENIED') {
        notificationAntd.warning({
          message: 'É necessário conceder permissões para o Meagenda acessar o Google Calendar',
          description: (
            <span>
              É necessário se autenticar novamente para conceder as permissões.&nbsp;
              <Button
                type="link"
                style={{
                  padding: 0,
                  height: '21px',
                  whiteSpace: 'break-spaces',
                  textAlign: 'start',
                }}
                onClick={() => {
                  googleAuthRequestChannel.put({ type: gapiActions.GIS_AUTHORIZATION_REQUEST });
                  notificationAntd.destroy('gapi-notification');
                }}
              >
                Clique aqui para fazer autenticação da sua conta Google
              </Button>
            </span>
          ),
          duration: null,
          key: 'gapi-notification',
        });
      }
      if (error?.result?.error?.status === 'UNAUTHENTICATED') {
        notification(
          'warning',
          'A sua conta Google não está conectada ou sua sessão expirou.',
          'As alterações de horário ou duração da consulta não podem ser atualizadas no seu Google Calendar.',
        );
        yield call(sleep, 2000);
        yield put({ type: gapiActions.GIS_AUTHORIZATION_REQUEST });
      }
    }
  });
}

function blockDaysOnDB(days, confirmed, agendaId, mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const blockedDates = {};
  confirmed.forEach((el) => {
    if (el.mode === 'blocked' && el.blockAll) {
      blockedDates[el.time.split(' ')[0]] = true;
    }
  });
  const timestamp = moment().tz('America/Sao_Paulo').format();
  const updates = {};
  days.forEach((day) => {
    const time = moment(day).format('YYYY-MM-DD');
    if (blockedDates[time]) {
      // Do nothing, day already blocked
    } else {
      const pushKey = push(child(ref(db), `/requests/${uid}/${agendaId}/confirmed`)).key;
      updates[`requests/${uid}/${agendaId}/confirmed/${pushKey}`] = {
        allDay: true,
        duration: '23:59',
        notes: '',
        timestamp,
        mode: 'blocked',
        time: `${time} 00:00`,
      };
    }
  });
  // return true;
  return update(dbRef, updates);
}

export function* blockDaysRequest() {
  yield takeEvery(actions.BLOCK_DAYS_REQUEST, function* (action) {
    try {
      yield put({
        type: actions.START_BLOCKING_DAYS,
      });
      yield call(sleep, 500);
      const confirmed = yield select(getConfirmedFromStore);
      const agendaId = yield select(getSelectedAgendaFromStore);
      const mainUser = yield select(getMainUserFromStore);
      yield call(blockDaysOnDB, action.payload, confirmed, agendaId, mainUser);
      yield put({
        type: actions.BLOCK_DAYS_SUCCESS,
      });
    } catch (error) {
      console.warn(error);
      yield put({ type: actions.BLOCK_DAYS_ERROR });
    }
  });
}

function unblockDaysOnDB(key, agendaId, mainUser) {
  const dbRef = ref(getDatabase());
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  updates[`requests/${uid}/${agendaId}/confirmed/${key}`] = null;
  return update(dbRef, updates);
}

export function* unblockDaysRequest() {
  yield takeEvery(actions.UNBLOCK_DAY_REQUEST, function* (action) {
    try {
      yield put({
        type: actions.START_UNBLOCKING_DAY,
        payload: action.payload.id,
      });
      yield call(sleep, 500);
      const agendaId = yield select(getSelectedAgendaFromStore);
      const mainUser = yield select(getMainUserFromStore);
      yield call(unblockDaysOnDB, action.payload.id, agendaId, mainUser);
      yield put({
        type: actions.UNBLOCK_DAY_SUCCESS,
      });
    } catch (error) {
      console.warn(error);
      yield put({ type: actions.UNBLOCK_DAY_ERROR });
    }
  });
}

function unblockMultipleDaysOnDB(events, agendaId, mainUser) {
  const dbRef = ref(getDatabase());
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  events.forEach((el) => {
    updates[`requests/${uid}/${agendaId}/confirmed/${el.id}`] = null;
  });
  return update(dbRef, updates);
}

export function* unblockMultipleDaysRequest() {
  yield takeEvery(actions.UNBLOCK_MULTIPLE_DAYS_REQUEST, function* (action) {
    try {
      yield put({
        type: actions.START_UNBLOCKING_MULTIPLE_DAYS,
      });
      yield call(sleep, 500);
      const agendaId = yield select(getSelectedAgendaFromStore);
      const mainUser = yield select(getMainUserFromStore);
      yield call(unblockMultipleDaysOnDB, action.payload, agendaId, mainUser);
      yield put({
        type: actions.UNBLOCK_DAY_SUCCESS,
      });
    } catch (error) {
      console.warn(error);
      yield put({ type: actions.UNBLOCK_DAY_ERROR });
    }
  });
}

function blockTimeOnDB(
  {
    time,
    duration,
    notes,
    selectedRrule,
  },
  agendaId,
  mainUser,
) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const timestamp = moment().tz('America/Sao_Paulo').format();
  const updates = {};
  if (!_.isEmpty(selectedRrule)) {
    const batchedKey = push(child(ref(db), `batchedAppointments/${uid}/${agendaId}/batched`)).key;
    updates[`batchedAppointments/${uid}/${agendaId}/batched/${batchedKey}`] = {
      blocked: true,
      appointmentModel: {
        allDay: false,
        notes,
        duration,
        timestamp,
        mode: 'blocked',
        time,
        selectedRrule,
      },
      queryTimestamp: selectedRrule.queryTimestamp,
      rrule: selectedRrule.rrule,
      selectedValue: selectedRrule.selectedValue,
      label: selectedRrule.label || null,
    };
  } else {
    const pushKey = push(child(ref(db), `requests/${uid}/${agendaId}/confirmed`)).key;
    updates[`requests/${uid}/${agendaId}/confirmed/${pushKey}`] = {
      allDay: false,
      duration,
      notes,
      timestamp,
      mode: 'blocked',
      time,
    };
  }
  // return true;
  return update(dbRef, updates);
}

export function* blockTimeRequest() {
  yield takeEvery(actions.BLOCK_TIME_REQUEST, function* (action) {
    try {
      yield put({
        type: actions.START_BLOCKING_TIME,
      });
      yield call(sleep, 500);
      const agendaId = yield select(getSelectedAgendaFromStore);
      const mainUser = yield select(getMainUserFromStore);
      yield call(blockTimeOnDB, action.payload, agendaId, mainUser);
      yield put({
        type: actions.BLOCK_TIME_SUCCESS,
      });
    } catch (error) {
      console.warn(error);
      yield put({ type: actions.BLOCK_TIME_ERROR });
    }
  });
}

function clearAppointmentStatusOnDB(
  appointmentId,
  sendMessagesActive,
  queryConfirmedKey,
  status,
  time,
  addressUid,
  agendaId,
  patientId,
  unified = {},
  mainUser,
) {
  const dbRef = ref(getDatabase());
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  if (status === 'finalized') {
    if (unified.id && unified.address.some((a) => a === addressUid)) {
      updates[`/unified/${unified.id}/patients/${patientId}/lastAppointment`] = null;
      updates[`/unified/${unified.id}/patients/${patientId}/lastProcedure`] = null;
    } else {
      updates[`/users/${uid}/patients/${patientId}/lastAppointment`] = null;
      updates[`/users/${uid}/patients/${patientId}/lastProceduresArr`] = null;
    }
    if (sendMessagesActive && queryConfirmedKey) {
      updates[`/queryConfirmed/${queryConfirmedKey}/finalized`] = null;
      updates[`/queryConfirmed/${queryConfirmedKey}/time`] = time;
      updates[`/query2HoursBefore/${queryConfirmedKey}/finalized`] = null;
      updates[`/query2HoursBefore/${queryConfirmedKey}/time`] = time;
    }
  }
  if (status === 'confirmed' && sendMessagesActive && queryConfirmedKey) {
    updates[`/queryConfirmed/${queryConfirmedKey}/manuallyConfirmed`] = null;
    updates[`/queryConfirmed/${queryConfirmedKey}/time`] = time;
  }
  if (status === 'surgery' && sendMessagesActive && queryConfirmedKey) {
    updates[`/queryConfirmed/${queryConfirmedKey}/surgery`] = null;
    updates[`/queryConfirmed/${queryConfirmedKey}/time`] = time;
    updates[`/query2HoursBefore/${queryConfirmedKey}/surgery`] = null;
    updates[`/query2HoursBefore/${queryConfirmedKey}/time`] = time;
  }
  updates[`/requests/${uid}/${agendaId}/confirmed/${appointmentId}/arrivalTime`] = null;
  updates[`/requests/${uid}/${agendaId}/confirmed/${appointmentId}/startedAttendance`] = null;
  updates[`/requests/${uid}/${agendaId}/confirmed/${appointmentId}/endTime`] = null;
  updates[`/requests/${uid}/${agendaId}/confirmed/${appointmentId}/patientDidNotShow`] = null;
  updates[`/requests/${uid}/${agendaId}/confirmed/${appointmentId}/appointmentMissed`] = null;
  updates[`/requests/${uid}/${agendaId}/confirmed/${appointmentId}/status`] = '';
  updates[`/requests/${uid}/${agendaId}/confirmed/${appointmentId}/selectedLabel`] = null;
  // return true;
  return update(dbRef, updates);
}

export function* clearAppointmentStatusRequest() {
  yield takeLatest(actions.CLEAR_APPOINTMENT_STATUS_REQUEST, function* (action) {
    try {
      yield put({
        type: actions.START_CLEARING_APPOINTMENT_STATUS,
        payload: action.payload.id,
      });
      yield call(sleep, 500);
      const unified = yield select(getUnifiedTokenStore);
      const addressUid = yield select(getSelectedAddressFromStore);
      const agendaId = yield select(getSelectedAgendaFromStore);
      const mainUser = yield select(getMainUserFromStore);
      yield call(
        clearAppointmentStatusOnDB,
        action.payload.id,
        action.payload.sendMessages,
        action.payload.queryConfirmedKey,
        action.payload.status,
        action.payload.time,
        addressUid,
        agendaId,
        action.payload.user,
        unified,
        mainUser,
      );
      if (action.payload.status === 'finalized') {
        yield all([
          put({
            type: contactActions.FETCH_PATIENT_DATA,
            payload: {
              id: action.payload.user,
              appointment: action.payload,
            },
          }),
          put({ type: actions.CLEAR_APPOINTMENT_STATUS_SUCCESS }),
          put({
            type: calendarActions.UPDATE_SELECTED_DATA_REQUEST,
            payload: action.payload.id,
          }),
        ]);
        const appointmentHistory = yield select(getAppoitmentHistoryFromStore);
        if (appointmentHistory?.[action.payload.user]) {
          // Reset appointment history so it is downloaded again
          yield put({
            type: contactActions.RESET_PATIENT_HISTORY,
          });
        }
      } else {
        yield all([
          put({ type: actions.CLEAR_APPOINTMENT_STATUS_SUCCESS }),
          put({
            type: calendarActions.UPDATE_SELECTED_DATA_REQUEST,
            payload: action.payload.id,
          }),
        ]);
      }
    } catch (error) {
      console.warn(error);
      yield put({ type: actions.CLEAR_APPOINTMENT_STATUS_ERROR });
    }
  });
}

function updateAppointmentValueOnDB(appointmentId, agendaId, value, mainUser) {
  const dbRef = ref(getDatabase());
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  updates[`/requests/${uid}/${agendaId}/confirmed/${appointmentId}/value`] = value;
  return update(dbRef, updates);
}

export function* updateAppointmentValueRequest() {
  yield takeLatest(actions.UPDATE_APPOINTMENT_VALUE_REQUEST, function* (action) {
    try {
      yield put({ type: actions.START_UPDATING_APPOINTMENT_VALUE });
      yield call(sleep, 500);
      const agendaId = yield select(getSelectedAgendaFromStore);
      const mainUser = yield select(getMainUserFromStore);
      yield call(
        updateAppointmentValueOnDB,
        action.payload.selectedData.id,
        agendaId,
        action.payload.value,
        mainUser,
      );
      yield all([
        put({ type: actions.UPDATE_APPOINTMENT_VALUE_SUCCESS }),
        put({
          type: calendarActions.UPDATE_SELECTED_DATA_REQUEST,
          payload: action.payload.selectedData.id,
        }),
      ]);
    } catch (error) {
      console.warn(error);
      yield put({ type: actions.UPDATE_APPOINTMENT_VALUE_ERROR });
    }
  });
}

function getPatientAppointmentsFromDB(patientId, agendaId, mainUser) {
  const db = getDatabase();
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const databaseRef = ref(db, `requests/${uid}/${agendaId}/confirmed`);
  const queryRef = dbQuery(
    databaseRef,
    orderByChild('user'),
    equalTo(patientId),
  );
  return get(queryRef);
}

function getPatientBatchedAppointmentsFromDB(patientId, agendaId, mainUser) {
  const db = getDatabase();
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const databaseRef = ref(db, `batchedAppointments/${uid}/${agendaId}/batched`);
  const queryRef = dbQuery(
    databaseRef,
    orderByChild('appointmentModel/user'),
    equalTo(patientId),
  );
  return new Promise((resolve) => {
    onValue(queryRef, resolve, { onlyOnce: true });
  });
}

export function* getPatientAppointmentsRequest() {
  yield takeLatest(actions.GET_PATIENT_APPOINTMENTS_REQUEST, function* (action) {
    try {
      yield put({ type: actions.DOWNLOADING_PATIENT_APPOINTMENTS });
      yield call(sleep, 500);
      const agendaId = yield select(getSelectedAgendaFromStore);
      const mainUser = yield select(getMainUserFromStore);
      const appointments = yield call(
        getPatientAppointmentsFromDB,
        action.payload,
        agendaId,
        mainUser,
      );
      const batched = yield call(
        getPatientBatchedAppointmentsFromDB,
        action.payload,
        agendaId,
        mainUser,
      );
      let normalizedAppointments = [];
      if (appointments.exists()) {
        normalizedAppointments = _.map(appointments.val(), (val, id) => {
          const { duration } = val;
          return {
            ...val,
            start: moment(val.time, 'YYYY-MM-DD HH:mm').toDate(),
            id,
            end: moment(val.time, 'YYYY-MM-DD HH:mm').add(moment.duration(duration).asMinutes(), 'm').toDate(),
            allDay: false,
            blockAll: val.allDay ? val.allDay : false,
          };
        });
      }
      let normalizedBatched = [];
      const filteredNormalizedBatched = [];
      const currentDate = moment().tz('America/Sao_Paulo');
      if (batched.exists()) {
        normalizedBatched = _.map(batched.val(), (val, id) => {
          const rule = rrulestr(val.rrule);
          const ruleAfter = rule.after(datetime(currentDate.year(), currentDate.month() + 1, currentDate.date()), true);
          let firstRecurrenceDate = null;
          if (ruleAfter) {
            firstRecurrenceDate = moment(
              ruleAfter,
            ).utcOffset(0).format('YYYY-MM-DD');
            // time: `${firstRecurrenceDate} ${response.data.batched[key].appointmentModel.time.split(' ')[1]}`,
          }
          return {
            ...val,
            // start: moment(val.time, 'YYYY-MM-DD HH:mm').toDate(),
            id,
            time: firstRecurrenceDate ? `${firstRecurrenceDate} ${val.appointmentModel.time.split(' ')[1]}` : null,
            firstRecurrenceDate,
            // end: moment(val.time, 'YYYY-MM-DD HH:mm').add(moment.duration(duration).asMinutes(), 'm').toDate(),
            // allDay: false,
            // blockAll: val.allDay ? val.allDay : false,
          };
        });
        normalizedBatched.forEach((el) => {
          if (el.firstRecurrenceDate) {
            filteredNormalizedBatched.push({
              ...el,
              duration: el.appointmentModel.duration,
              start: moment(el.time, 'YYYY-MM-DD HH:mm').toDate(),
              end: moment(el.time, 'YYYY-MM-DD HH:mm').add(moment.duration(el.appointmentModel.duration).asMinutes(), 'm').toDate(),
              allDay: false,
              blockAll: el.appointmentModel.allDay ? el.appointmentModel.allDay : false,
            });
          }
        });
      }
      yield all([
        put({
          type: actions.GET_PATIENT_APPOINTMENTS_SUCCESS,
          payload: [...normalizedAppointments, ...filteredNormalizedBatched],
        }),
      ]);
    } catch (error) {
      console.warn(error);
      yield put({ type: actions.GET_PATIENT_APPOINTMENTS_ERROR });
      notification(
        'error',
        'Algo deu errado ao baixar os agendamentos',
        'Tente novamente mais tarde ou entre em contato com o suporte se o problema persistir.',
      );
    }
  });
}

function finalizeAppointmentWithInvoiceOnDB(
  appointment,
  invoice,
  values,
  addressUid,
  idToken,
  unified,
  mainUser,
) {
  const db = getDatabase();
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const obj = {};
  Object.keys(values).forEach((field) => {
    obj[field] = values[field];
    /*
    In case we want to update the procedures info on the appointment side, such as 'qty' or 'value',
    this is the piece of code to update the 'proceduresArr' of the appointment.

    if (field === 'proceduresArr' && appointment.proceduresArr) {
      const updatedAppointmentProceduresArr = _.cloneDeep(appointment.proceduresArr);
      values[field].forEach((el) => {
        const foundProcIndex = appointment.proceduresArr.findIndex((p) => p.id === el.procedureId);
        if (foundProcIndex > -1) {
          if (el.mode) {
            updatedAppointmentProceduresArr[foundProcIndex].mode = { ...el.mode };
          }
          if (el.inventory) {
            updatedAppointmentProceduresArr[foundProcIndex].inventory = _.cloneDeep(el.inventory);
          }
        }
      });
      updates[`/requests/${uid}/${addressUid}/confirmed/${appointment.id}/proceduresArr`] = updatedAppointmentProceduresArr;
    }
    */
  });
  if (invoice && obj.invoiceStatus !== 'not-finalized') {
    obj.invoiceStatus = 'confirmed';
  }
  const databaseRef = ref(db, `finalizedAppointments/${uid}/${addressUid}/${appointment.id}`);
  return update(databaseRef, obj);
}

export function* finalizeAppointmentWithInvoiceRequest() {
  yield takeEvery(actions.FINALIZE_APPOINTMENT_WITH_INVOICE_REQUEST, function* (action) {
    try {
      const { selectedData, invoice, values } = action.payload;
      yield put({
        type: actions.START_FINALIZE_APPOINTMENT_WITH_INVOICE,
        // payload: invoice ? 'submit' : 'later',
        payload: true,
      });
      yield call(sleep, 500);
      const idToken = yield call(getIdToken);
      const unified = yield select(getUnifiedTokenStore);
      // const agendaId = yield select(getSelectedAgendaFromStore);
      const addressUid = yield select(getSelectedAddressFromStore);
      const mainUser = yield select(getMainUserFromStore);
      yield call(
        finalizeAppointmentWithInvoiceOnDB,
        selectedData,
        invoice,
        values,
        addressUid,
        idToken,
        unified,
        mainUser,
      );
      yield put({
        type: actions.FINALIZE_APPOINTMENT_WITH_INVOICE_SUCCESS,
      });
    } catch (error) {
      console.warn(error);
      yield put({ type: actions.FINALIZE_APPOINTMENT_WITH_INVOICE_ERROR });
    }
  });
}

function getFinalizedAppointmentFromDB(id, addressUid, mainUser) {
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const db = getDatabase();
  return new Promise((resolve) => {
    onValue(dbQuery(ref(db, `finalizedAppointments/${uid}/${addressUid}/${id}`)), resolve, { onlyOnce: true });
  });
}

export function* getFinalizedAppointmentRequest() {
  yield takeLatest(actions.GET_FINALIZED_APPOINTMENT_REQUEST, function* (action) {
    try {
      yield put({ type: actions.DOWNLOADING_FINALIZED_APPOINTMENT });
      yield call(sleep, 3000);
      const addressUid = yield select(getSelectedAddressFromStore);
      // const agendaId = yield select(getSelectedAgendaFromStore);
      const mainUser = yield select(getMainUserFromStore);
      const finalizedAppointment = yield call(
        getFinalizedAppointmentFromDB,
        action.payload,
        addressUid,
        mainUser,
      );
      let value = null;
      if (finalizedAppointment.val()) {
        value = {
          ...finalizedAppointment.val(),
          id: finalizedAppointment.key,
        };
      }
      yield put({
        type: actions.GET_FINALIZED_APPOINTMENT_SUCCESS,
        payload: {
          finalizedAppointment: value,
        },
      });
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.GET_FINALIZED_APPOINTMENT_ERROR,
      });
    }
  });
}

export function* getNewEntranceModalFullWidthRequest() {
  yield takeEvery(actions.GET_NEW_ENTRANCE_MODAL_FULL_WIDTH_REQUEST, function* () {
    try {
      // yield localStorage.removeItem('new_entrance_modal_full_width');
      const value = yield localStorage.getItem('new_entrance_modal_full_width');
      if (!_.isUndefined(value)) {
        yield put({
          type: actions.GET_NEW_ENTRANCE_MODAL_FULL_WIDTH_SUCCESS,
          payload: JSON.parse(value),
        });
      }
    } catch (error) {
      console.warn(error);
    }
  });
}

export function* setNewEntranceModalFullWidthRequest() {
  yield takeLatest(actions.SET_NEW_ENTRANCE_MODAL_FULL_WIDTH_REQUEST, function* (action) {
    try {
      yield localStorage.setItem('new_entrance_modal_full_width', action.payload);
      yield put({
        type: actions.SET_NEW_ENTRANCE_MODAL_FULL_WIDTH_SUCCESS,
        payload: action.payload,
      });
    } catch (error) {
      console.warn(error);
    }
  });
}

function setSelectedLabelOnOnDB(
  appointmentId,
  sendMessagesActive,
  queryConfirmedKey,
  status,
  time,
  agendaId,
  labelKey,
  mainUser,
) {
  const dbRef = ref(getDatabase());
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  if (status === 'finalized') {
    if (sendMessagesActive && queryConfirmedKey) {
      updates[`/queryConfirmed/${queryConfirmedKey}/finalized`] = null;
      updates[`/queryConfirmed/${queryConfirmedKey}/time`] = time;
      updates[`/query2HoursBefore/${queryConfirmedKey}/finalized`] = null;
      updates[`/query2HoursBefore/${queryConfirmedKey}/time`] = time;
    }
  }
  if (status === 'confirmed' && sendMessagesActive && queryConfirmedKey) {
    updates[`/queryConfirmed/${queryConfirmedKey}/manuallyConfirmed`] = null;
    updates[`/queryConfirmed/${queryConfirmedKey}/time`] = time;
  }
  if (status === 'surgery' && sendMessagesActive && queryConfirmedKey) {
    updates[`/queryConfirmed/${queryConfirmedKey}/surgery`] = null;
    updates[`/queryConfirmed/${queryConfirmedKey}/time`] = time;
    updates[`/query2HoursBefore/${queryConfirmedKey}/surgery`] = null;
    updates[`/query2HoursBefore/${queryConfirmedKey}/time`] = time;
  }
  updates[`/requests/${uid}/${agendaId}/confirmed/${appointmentId}/arrivalTime`] = null;
  updates[`/requests/${uid}/${agendaId}/confirmed/${appointmentId}/startedAttendance`] = null;
  updates[`/requests/${uid}/${agendaId}/confirmed/${appointmentId}/endTime`] = null;
  updates[`/requests/${uid}/${agendaId}/confirmed/${appointmentId}/patientDidNotShow`] = null;
  updates[`/requests/${uid}/${agendaId}/confirmed/${appointmentId}/appointmentMissed`] = null;
  updates[`/requests/${uid}/${agendaId}/confirmed/${appointmentId}/status`] = '';
  updates[`/requests/${uid}/${agendaId}/confirmed/${appointmentId}/selectedLabel`] = labelKey;
  // return true;
  return update(dbRef, updates);
}

export function* setSelectedLabelRequest() {
  yield takeEvery(actions.SET_SELECTED_LABEL_REQUEST, function* (action) {
    try {
      yield put({
        type: actions.START_SETTING_SELECTED_LABEL,
        payload: action.payload.key,
      });
      yield call(sleep, 500);
      const mainUser = yield select(getMainUserFromStore);
      const agendaId = yield select(getSelectedAgendaFromStore);
      const { selectedData, key } = action.payload;
      yield call(
        setSelectedLabelOnOnDB,
        selectedData.id,
        selectedData.sendMessages,
        selectedData.queryConfirmedKey,
        selectedData.status,
        selectedData.time,
        agendaId,
        key,
        mainUser,
      );
      yield all([
        put({ type: actions.SET_SELECTED_LABEL_SUCCESS }),
        put({
          type: calendarActions.UPDATE_SELECTED_DATA_REQUEST,
          payload: selectedData.id,
        }),
      ]);
    } catch (error) {
      console.warn(error);
      yield put({ type: actions.SET_SURGERY_ERROR });
    }
  });
}

function saveAgendaLabelOnFirestore(labels, newLabel = null, labelKey = null, addressId, agendaKey, mainUser) {
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const fs = getFirestore();
  const agendaRef = doc(
    fs,
    'professionals',
    uid,
    'addresses',
    addressId,
    'agendas',
    agendaKey,
  );
  const newLabels = { ...labels };
  if (!newLabel) {
    // Removing selected label.
    delete newLabels[labelKey];
  } else if (!labelKey) {
    // Creating new label.
    const colRef = collection(
      fs,
      'professionals',
      uid,
      'addresses',
      addressId,
      'agendas',
    );
    const newKey = doc(colRef).id;
    newLabels[newKey] = { ...newLabel };
  } else {
    // Updating label.
    newLabels[labelKey] = { ...newLabel };
  }
  return updateDoc(agendaRef, {
    labels: newLabels,
  });
}

export function* saveAgendaLabelRequest() {
  yield takeEvery(actions.SAVE_AGENDA_LABEL_REQUEST, function* (action) {
    try {
      yield put({
        type: actions.SAVING_AGENDA_LABEL,
        payload: action.payload.key || true,
      });
      const idTokenResult = yield call(accessTokenResult);
      const mainUser = idTokenResult.claims.mainUser
        ? idTokenResult.claims.mainUser
        : null;
      const addressUid = yield select(getSelectedAddressFromStore);
      const agendasArr = yield select(getAgendasArrFromStore);
      const agendaId = yield select(getSelectedAgendaFromStore);
      const {
        item,
        key,
      } = action.payload;
      const foundAgenda = agendasArr.find((el) => el.id === agendaId);
      if (foundAgenda?.labels) {
        const currLabels = { ...foundAgenda.labels };
        yield call(
          saveAgendaLabelOnFirestore,
          currLabels,
          item,
          key,
          addressUid,
          agendaId,
          mainUser,
        );
        yield put({
          type: actions.FETCH_AGENDAS_FROM_FIRESTORE_REQUEST,
        });
      } else {
        throw new Error('No agenda found.');
      }
    } catch (error) {
      console.warn(error);
      notification(
        'error',
        'Não foi possível salvar a legenda',
        'Tente novamente mais tarde ou entre em contato com o suporte se o problema persistir.',
      );
    }
  });
}

function createBatchedListener(params = {}, agendaId, mainUser) {
  const db = getDatabase();
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const currentDate = moment().tz('America/Sao_Paulo').toDate();
  let start = moment(currentDate).tz('America/Sao_Paulo').startOf('week').format('YYYY-MM-DD');
  if (params.start) {
    ({ start } = params);
  }
  const startToUse = moment(start)
    .tz('America/Sao_Paulo')
    .subtract(1, 'weeks')
    .format('YYYY-MM-DD');
  const listener = eventChannel((emit) => {
    const databaseRef = ref(db, `batchedAppointments/${uid}/${agendaId}/batched`); // trocar batched por generators
    const queryRef = dbQuery(
      databaseRef,
      orderByChild('queryTimestamp'),
      startAt(startToUse),
      // endAt(`${end}\uf8ff`),
    );
    const unsubscribe = onValue(queryRef, (req) => (
      emit(req.val() || {})
    ));
    return () => unsubscribe();
  });
  return listener;
}

export function* getBatchedAppointmentsRequest() {
  yield takeEvery([
    actions.SET_NEW_AGENDA_LISTENER,
    actions.SET_NEW_BATCHED_LISTENER,
    actions.SELECT_AGENDA,
  ], function* (action) {
    try {
      const agendaId = yield select(getSelectedAgendaFromStore);
      const mainUser = yield select(getMainUserFromStore);
      const batchedAppointments = yield select(getBatchedAppointmentsFromStore);
      if (batchedAppointments) {
        yield put({ type: actions.RESET_BATCHED_APPOINTMENTS });
      }
      const batchedListener = yield call(createBatchedListener, action.payload, agendaId, mainUser);
      yield takeEvery(batchedListener, function* (batched) {
        const normalizedBatched = _.map(batched, (val, id) => ({
          ...val,
          batchedId: id,
        }));
        yield put({
          type: actions.SET_FULL_BATCHED_APPOINTMENTS_ARR,
          payload: {
            arr: normalizedBatched,
          },
        });
      });
      const actionTriggered = yield take([
        appActions.CANCEL_LISTENERS,
        actions.CHANGE_LISTENER_PARAM,
        actions.SELECT_AGENDA,
      ]);
      batchedListener.close();
      if (actionTriggered?.type === 'CHANGE_LISTENER_PARAM') {
        // Reset agenda's listener to new params.
        yield put({
          type: actions.SET_NEW_BATCHED_LISTENER,
          payload: actionTriggered.payload,
        });
      } else if (actionTriggered?.type === 'SELECT_AGENDA') {
        // Agenda changed.
      } else {
        // It is the general "cancel all listeners".
        yield all([
          put({ type: appActions.CANCEL_LISTENERS_SUCCESS }),
          put({ type: actions.RESET_AGENDA }),
        ]);
      }
    } catch (error) {
      console.warn(error);
      yield put({ type: actions.GET_PATIENT_APPOINTMENTS_ERROR });
    }
  });
}

function createPersistedBatchedListener(params = {}, agendaId, mainUser) {
  const db = getDatabase();
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const currentDate = moment().tz('America/Sao_Paulo').toDate();
  let start = moment(currentDate).tz('America/Sao_Paulo').startOf('week').format('YYYY-MM-DD');
  if (params.start) {
    ({ start } = params);
  }
  const startToUse = moment(start)
    .tz('America/Sao_Paulo')
    .subtract(1, 'weeks')
    .format('YYYY-MM-DD');
  const listener = eventChannel((emit) => {
    const databaseRef = ref(db, `batchedAppointments/${uid}/${agendaId}/persisted`);
    const queryRef = dbQuery(
      databaseRef,
      orderByChild('time'),
      startAt(startToUse),
      // endAt(`${end}\uf8ff`),
    );
    const unsubscribe = onValue(queryRef, (req) => (
      emit(req.val() || {})
    ));
    return () => unsubscribe();
  });
  return listener;
}

export function* getPersistedBatchedAppointmentsRequest() {
  yield takeEvery([
    actions.SET_NEW_AGENDA_LISTENER,
    actions.SET_NEW_PERSISTED_LISTENER,
    actions.SELECT_AGENDA,
  ], function* (action) {
    try {
      const agendaId = yield select(getSelectedAgendaFromStore);
      const mainUser = yield select(getMainUserFromStore);
      const persistedListener = yield call(createPersistedBatchedListener, action.payload, agendaId, mainUser);
      yield takeEvery(persistedListener, function* (persisted) {
        yield put({
          type: actions.SET_PERSISTED_BATCHED_APPOINTMENTS,
          payload: {
            obj: persisted,
          },
        });
      });
      const actionTriggered = yield take([
        appActions.CANCEL_LISTENERS,
        actions.CHANGE_LISTENER_PARAM,
        actions.SELECT_AGENDA,
      ]);
      persistedListener.close();
      if (actionTriggered?.type === 'CHANGE_LISTENER_PARAM') {
        // Reset agenda's listener to new params.
        yield put({
          type: actions.SET_NEW_PERSISTED_LISTENER,
          payload: actionTriggered.payload,
        });
      } else if (actionTriggered?.type === 'SELECT_AGENDA') {
        // Agenda changed.
      } else {
        // It is the general "cancel all listeners".
        yield all([
          put({ type: appActions.CANCEL_LISTENERS_SUCCESS }),
          put({ type: actions.RESET_AGENDA }),
        ]);
      }
    } catch (error) {
      console.warn(error);
    }
  });
}

export function* generateBatchedAppointmentsEventsRequest() {
  yield takeEvery([
    actions.SET_FULL_BATCHED_APPOINTMENTS_ARR,
    actions.CHANGE_BATCHED_START_END_DATES,
    actions.SET_PERSISTED_BATCHED_APPOINTMENTS,
  ], function* () {
    try {
      const agendaId = yield select(getSelectedAgendaFromStore);
      const mainUser = yield select(getMainUserFromStore);
      const currentAgendaStartDateListener = yield select(getCurrentAgendaStartDateListenerFromStore);
      const batchedStartEndDates = yield select(getBatchedStartEndDatesFromStore);
      const fullBatchedAppointmentsArr = yield select(getFullBatchedAppointmentsArrFromStore);
      const persistedBatchedAppointments = yield select(getPersistedBatchedAppointmentsFromStore);
      const db = getDatabase();
      let uid;
      if (mainUser) {
        uid = mainUser;
      } else {
        const auth = getAuth();
        const { currentUser } = auth;
        ({ uid } = currentUser);
      }
      const batchedDatesEvents = [];
      fullBatchedAppointmentsArr.forEach((el) => {
        const rule = rrulestr(el.rrule);
        const ruleDtstart = moment(rule.origOptions.dtstart).format('YYYY-MM-DD');
        let start = moment(currentAgendaStartDateListener, 'YYYY-MM-DD').startOf('week').subtract(1, 'days');
        let end = moment(currentAgendaStartDateListener, 'YYYY-MM-DD').endOf('week').add(1, 'days');
        if (batchedStartEndDates?.length > 0) {
          start = moment(batchedStartEndDates[0], 'YYYY-MM-DD').subtract(1, 'days');
          end = moment(batchedStartEndDates[1], 'YYYY-MM-DD').add(1, 'days');
        }
        const batchedDates = rule.between(start.toDate(), end.toDate(), true);
        const duration = moment.duration(el.appointmentModel.duration).asMinutes() || 15;
        const timeStr = moment(el.appointmentModel.time, 'YYYY-MM-DD HH:mm').format('HH:mm');
        batchedDates.forEach((date) => {
          const dateStr = moment(date).utcOffset(0).format('YYYY-MM-DD');
          if (dateStr >= ruleDtstart) {
            let alreadyPersisted = false;
            if (persistedBatchedAppointments) {
              alreadyPersisted = Object.values(persistedBatchedAppointments).some((obj) => {
                const splittedTime = obj.time.split(' ')[0];
                if (splittedTime === dateStr && el.batchedId === obj.batchedId) {
                  return true;
                }
                return false;
              });
            }
            if (!alreadyPersisted) {
              const startTime = moment(`${dateStr} ${timeStr}`, 'YYYY-MM-DD HH:mm').toDate();
              const endTime = moment(`${dateStr} ${timeStr}`, 'YYYY-MM-DD HH:mm').add(duration, 'm').toDate();
              const pushKey = push(child(ref(db), `/requests/${uid}/${agendaId}/confirmed`)).key;
              if (el.blocked) {
                batchedDatesEvents.push({
                  ...el.appointmentModel,
                  batchedId: el.batchedId,
                  id: pushKey,
                  time: `${dateStr} ${timeStr}`,
                  start: startTime,
                  end: endTime,
                  type: 'confirmed',
                });
              } else {
                batchedDatesEvents.push({
                  ...el.appointmentModel,
                  batchedId: el.batchedId,
                  id: pushKey,
                  time: `${dateStr} ${timeStr}`,
                  start: startTime,
                  end: endTime,
                  allDay: false,
                  blockAll: false,
                  type: 'confirmed',
                });
              }
            }
          }
        });
      });
      yield put({
        type: actions.SET_BATCHED_APPOINTMENTS,
        payload: {
          arr: batchedDatesEvents,
        },
      });
    } catch (error) {
      console.warn(error);
      yield put({ type: actions.GET_PATIENT_APPOINTMENTS_ERROR });
    }
  });
}

function createBatchAppointmentOnDB(
  {
    appointment,
    addressUid,
    agendaId,
    firstRecurrenceDate,
    key,
    professionalInfo,
  },
  idToken,
  mainUser,
) {
  const auth = getAuth();
  const { currentUser } = auth;
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    ({ uid } = currentUser);
  }
  const config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };
  const copyAppointment = _.cloneDeep(appointment);
  delete copyAppointment.wasBatched;
  const bodyParameters = {
    uid,
    addressUid,
    agendaId,
    patient: appointment.user,
    appointment: copyAppointment,
    currentUser: currentUser.uid,
    key,
    firstRecurrenceDate,
    professionalProfile: professionalInfo,
  };
  // return true;
  return axios.post(
    `${ROOT_URL}/createBatchAppointment`,
    bodyParameters,
    config,
  );
}

export function* createBatchAppointmentRequest() {
  yield takeEvery(actions.CREATE_BATCH_APPOINTMENT_REQUEST, function* (action) {
    try {
      yield put({ type: actions.SETTING_NEW_APPOINTMENT });
      // yield call(sleep, 5000);
      const unifiedToken = yield select(getUnifiedTokenStore);
      const mainUser = yield select(getMainUserFromStore);
      const addressUid = yield select(getSelectedAddressFromStore);
      const agendaId = yield select(getSelectedAgendaFromStore);
      const customUsers = yield select(getCustomUsersFromStore);
      const {
        user,
        isVideoCall,
        videoCallMode,
        patientWhatsApp,
        time,
        duration,
        selectedRrule,
      } = action.payload;
      let profile = yield select(getProfileFromStore);
      if (_.isEmpty(profile)) {
        yield take(profileActions.PROFILE_INFO_SUCCESS);
        profile = yield select(getProfileFromStore);
      }
      let professionalInfo = { ...profile };
      delete professionalInfo.keywords;
      delete professionalInfo.addresses;
      if (action.payload.professional && action.payload.professional !== professionalInfo.id) {
        const foundProfessional = customUsers.find((el) => action.payload.professional === el.id);
        professionalInfo = { ...foundProfessional };
      }
      let patients = yield select(getPatientsFromStore);
      let userInfo = patients.find((contact) => contact.id === user);
      if (patientWhatsApp && userInfo?.mobile !== patientWhatsApp) {
        yield call(
          savePatientWhatsAppOnDB,
          patientWhatsApp,
          user,
          addressUid,
          unifiedToken,
          mainUser,
        );
        yield put({
          type: contactActions.FETCH_PATIENT_DATA,
          payload: {
            id: user,
          },
        });
        yield take(contactActions.PATIENTS_FETCH_SUCCESS);
        patients = yield select(getPatientsFromStore);
        userInfo = patients.find((contact) => contact.id === user);
      }

      const idToken = yield call(getIdToken);
      const timestamp = moment().tz('America/Sao_Paulo').format();
      // const automaticMessage = yield select(getActiveMessageBot);
      const fullMessagesSettings = yield select(getFullMessagesSettingsFromStore);
      const manuallySendMessage = !fullMessagesSettings?.[professionalInfo.id]?.sendAutomaticMessages;
      const db = getDatabase();
      let uid;
      if (mainUser) {
        uid = mainUser;
      } else {
        const auth = getAuth();
        const { currentUser } = auth;
        ({ uid } = currentUser);
      }
      const pushKey = push(child(ref(db), `batchedAppointments/${uid}/${agendaId}/batched`)).key;

      let videoCallLink = '';
      let videoCallCreator = '';
      let videoCallId = '';
      if (isVideoCall && videoCallMode === 'meets') {
        const recurrence = selectedRrule.rrule.split('\n')[1];
        const videoCallLinkInteiro = yield call(
          handleCreateGoogleMeetingEvent,
          pushKey,
          time,
          duration,
          userInfo,
          recurrence,
        );
        videoCallCreator = videoCallLinkInteiro.result.creator.email;
        videoCallLink = videoCallLinkInteiro.result.hangoutLink;
        videoCallId = videoCallLinkInteiro.result.id;
      }
      const videoCallData = {
        videoCallLink,
        videoCallCreator,
        videoCallId,
      };

      const rule = rrulestr(selectedRrule.rrule);
      const currentDate = moment().tz('America/Sao_Paulo').add(1, 'day');
      const ruleAfter = rule.after(datetime(currentDate.year(), currentDate.month() + 1, currentDate.date()), true);
      let firstRecurrenceDate = null;
      if (ruleAfter) {
        firstRecurrenceDate = moment(
          ruleAfter,
        ).utcOffset(0).format('YYYY-MM-DD');
      }
      yield call(createBatchAppointmentOnDB, {
        appointment: {
          ...action.payload,
          manuallySendMessage,
          timestamp,
          // queryConfirmedKey,
          isVideoCall,
          videoCallMode,
          videoCallData,
        },
        firstRecurrenceDate: ruleAfter ? `${firstRecurrenceDate} ${time.split(' ')[1]}` : null,
        key: pushKey,
        addressUid,
        agendaId,
        professionalInfo,
      }, idToken, mainUser);

      yield put({
        type: actions.MANUALLY_SET_NEW_APPOINTMENT_SUCCESS,
      });
    } catch (error) {
      console.warn(error);
      console.warn(error.message);
      if (error?.result?.error?.message) {
        yield put({
          type: gapiActions.RESET_GIS_ACCESS_TOKEN,
        });
      }
      yield put({
        type: actions.MANUALLY_SET_NEW_APPOINTMENT_FAIL,
      });
    }
  });
}

function setPersistedBatchedAppointmentOnOnDB(
  selectedData,
  professionalInfo,
  addressUid,
  agendaId,
  mainUser,
  idToken,
  unified = {},
) {
  const db = getDatabase();
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };
  const newObj = _.cloneDeep(selectedData);
  delete newObj.start;
  delete newObj.end;
  delete newObj.oldTime;
  delete newObj.allDay;
  delete newObj.blockAll;
  delete newObj.type;
  delete newObj.logs;
  // if (onlyThis) {
  //   delete newObj.batchedId;
  //   newObj.selectedRrule = {};
  // }
  delete newObj.batchedId;
  newObj.selectedRrule = {};
  newObj.wasBatched = true;
  const queryConfirmedKey = push(child(ref(db), '/queryConfirmed')).key;
  newObj.queryConfirmedKey = queryConfirmedKey;
  const bodyParameters = {
    uid,
    agendaId,
    selectedData: newObj,
    batchedId: selectedData.batchedId,
    oldTime: selectedData.oldTime,
  };
  if (selectedData.sendMessages) {
    const queryObj = {
      time: selectedData.time,
      address: addressUid,
      agenda: agendaId,
      mainUser: professionalInfo.mainUser || null,
      professional: selectedData.professional || uid,
      appointment: selectedData.id,
      patient: selectedData.user,
      userLocal: true,
      unifiedToken: unified.id && unified.address.some((a) => a === addressUid)
        ? unified.id : null,
      manuallySendMessage: selectedData.manuallySendMessage || null,
    };
    if (selectedData.isVideoCall) {
      queryObj.videoCallLink = true;
    }
    bodyParameters.queryObj = queryObj;
  }
  // return true;
  return axios.post(
    `${ROOT_URL}/persistBatchAppointment`,
    bodyParameters,
    config,
  );
}

function getCalendarEventsInstance(idEvent, videoCallCreator) {
  return window.gapi.client.calendar.events.instances({
    calendarId: videoCallCreator,
    eventId: idEvent,
  });
}

function handleCancelSingleRecurringEvent(idEvent, origEvent, firstEvent, videoCallCreator) {
  return window.gapi.client.calendar.events.update({
    calendarId: `${videoCallCreator}`,
    eventId: `${idEvent}`,
    resource: { ...origEvent, status: 'cancelled' },
  });
}

export function* persistBatchedAppointmentsRequest() {
  yield takeEvery(actions.PERSIST_BATCHED_APPOINTMENT_REQUEST, function* (action) {
    try {
      yield put({ type: actions.START_PERSISTING_BATCHED_APPOINTMENT });
      const idToken = yield call(getIdToken);
      const unifiedToken = yield select(getUnifiedTokenStore);
      const mainUser = yield select(getMainUserFromStore);
      const addressUid = yield select(getSelectedAddressFromStore);
      const agendaId = yield select(getSelectedAgendaFromStore);
      const customUsers = yield select(getCustomUsersFromStore);
      let gisUserProfile = yield select(getGisUserProfileFromStore);
      let persistedBatchedAppointments = yield select(getPersistedBatchedAppointmentsFromStore);
      let profile = yield select(getProfileFromStore);
      if (_.isEmpty(profile)) {
        yield take(profileActions.PROFILE_INFO_SUCCESS);
        profile = yield select(getProfileFromStore);
      }
      let professionalInfo = { ...profile };
      delete professionalInfo.keywords;
      delete professionalInfo.addresses;
      const { selectedData } = action.payload;
      if (selectedData.professional && selectedData.professional !== professionalInfo.id) {
        const foundProfessional = customUsers.find((el) => action.payload.professional === el.id);
        professionalInfo = { ...foundProfessional };
      }
      if (!persistedBatchedAppointments) {
        yield take(actions.SET_PERSISTED_BATCHED_APPOINTMENTS);
        persistedBatchedAppointments = yield select(getPersistedBatchedAppointmentsFromStore);
      }
      const alreadyPersisted = Object.values(persistedBatchedAppointments).some((obj) => {
        if (obj.time === action.payload.selectedData.time && obj.batchedId === action.payload.selectedData.batchedId) {
          return true;
        }
        return false;
      });
      if (!alreadyPersisted) {
        let videoCallLink = '';
        let videoCallCreator = '';
        let videoCallId = '';
        if (selectedData.isVideoCall && selectedData.videoCallMode === 'meets') {
          if (!gisUserProfile) {
            notificationAntd.warning({
              message: 'Você não está logado com a conta Google utilizada ao criar a consulta.',
              description: (
                <span>
                  As alterações de horário ou duração da consulta não serão atualizadas no seu Google Calendar.&nbsp;
                  <Button
                    type="link"
                    style={{ padding: 0, height: '21px' }}
                    onClick={() => {
                      googleAuthRequestChannel.put({ type: gapiActions.GIS_AUTHORIZATION_REQUEST });
                    }}
                  >
                    Clique aqui para acessar sua conta Google
                  </Button>
                  &nbsp;ou&nbsp;
                  <Button
                    type="link"
                    style={{ padding: 0, height: '21px' }}
                    onClick={() => {
                      googleAuthRequestChannel.put({ type: gapiActions.CONTINUE_WITHOUT_GIS_AUTHORIZATION });
                    }}
                  >
                    continue sem uma conta Google.
                  </Button>
                </span>
              ),
              duration: null,
              key: 'gapi-notification',
              onClose: () => googleAuthRequestChannel.put({ type: gapiActions.CONTINUE_WITHOUT_GIS_AUTHORIZATION }),
            });
            const actionTriggered = yield take([
              gapiActions.CONTINUE_WITHOUT_GIS_AUTHORIZATION,
              gapiActions.SET_GIS_ACCESS_TOKEN,
            ]);
            notificationAntd.destroy('gapi-notification');
            if (actionTriggered.type === 'SET_GIS_ACCESS_TOKEN') {
              gisUserProfile = yield select(getGisUserProfileFromStore);
            }
          }
          if (!window.gapi || !gisUserProfile) {
            notification(
              'error',
              'Algo deu errado para se comunicar com os servidores da Google',
              'As alterações de horário ou duração da consulta não serão atualizadas no seu Google Calendar.',
            );
          } else if (window.gapi.client.getToken() === null) {
            notification(
              'error',
              'Não há permissão suficiente de uma conta no Google Calendar para habilitar vídeo chamada',
              'As alterações de horário ou duração da consulta não serão atualizadas no seu Google Calendar.',
            );
          } else if (gisUserProfile?.email === action.payload.selectedData?.videoCallData?.videoCallCreator) {
            // User is logged in and it is the same account when created.
            const instances = yield call(getCalendarEventsInstance, selectedData.videoCallData.videoCallId, selectedData.videoCallData.videoCallCreator);
            if (instances?.result?.items?.length > 0) {
              const firstEvent = instances.result.items[0];
              const foundEvent = instances.result.items.find((el) => {
                if (el?.originalStartTime?.dateTime && selectedData.time.split(' ')[0] === moment(el.originalStartTime.dateTime).format('YYYY-MM-DD')) {
                  return true;
                }
                return false;
              });
              if (foundEvent) {
                // Need to remove this event from its batch.
                yield call(
                  handleCancelSingleRecurringEvent,
                  foundEvent.id,
                  foundEvent,
                  firstEvent,
                  selectedData.videoCallData.videoCallCreator,
                  selectedData.time,
                  selectedData.duration,
                  // selectedData.userInfo,
                );
              }
              // Creating single event in Google Calendar.
              const videoCallLinkInteiro = yield call(
                handleCreateGoogleMeetingEvent,
                selectedData.id,
                selectedData.time,
                selectedData.duration,
                selectedData.userInfo,
              );
              videoCallCreator = videoCallLinkInteiro.result.creator.email;
              videoCallLink = videoCallLinkInteiro.result.hangoutLink;
              videoCallId = videoCallLinkInteiro.result.id;
            }
          } else if (gisUserProfile?.email !== action.payload.selectedData?.videoCallData?.videoCallCreator) {
            // User is logged in but it is NOT the same account when created.
            notification(
              'warning',
              'A conta Google não é a mesma utilizada ao criar a consulta',
              'As alterações de horário ou duração da consulta não serão atualizadas no seu Google Calendar.',
            );
            // throw new Error('Wrong Google account connected.');
          }
        }

        const videoCallData = {
          videoCallLink,
          videoCallCreator,
          videoCallId,
        };
        yield call(
          setPersistedBatchedAppointmentOnOnDB,
          { ...selectedData, videoCallData },
          professionalInfo,
          addressUid,
          agendaId,
          mainUser,
          idToken,
          unifiedToken,
        );
        yield put({
          type: calendarActions.UPDATE_SELECTED_DATA_REQUEST,
          payload: selectedData.id,
        });
        yield put({
          type: actions.PERSIST_BATCHED_APPOINTMENT_SUCCESS,
        });
      } else {
        throw new Error('Already persisted.');
      }
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.PERSIST_BATCHED_APPOINTMENT_ERROR,
      });
      notification(
        'error',
        'Algo deu errado',
        'Tente novamente mais tarde. Se o erro persistir, entre em contato com o suporte.',
      );
      if (error?.result?.error?.message) {
        yield put({
          type: gapiActions.RESET_GIS_ACCESS_TOKEN,
        });
      }
    }
  });
}

function handleUpdateRecurrenceGoogleMeetEvent(idEvent, videoCallCreator, recurrence, date = null, duration = null) {
  const event = {
    recurrence: [recurrence],
  };
  if (date && duration) {
    const startTime = moment(date, 'YYYY-MM-DD HH:mm').tz('America/Sao_Paulo').format();
    const endTime = moment(date, 'YYYY-MM-DD HH:mm').add(moment.duration(duration).asMinutes(), 'm').format();
    event.start = { dateTime: startTime, timeZone: 'America/Sao_Paulo' };
    event.end = { dateTime: endTime, timeZone: 'America/Sao_Paulo' };
  }
  return window.gapi.client.calendar.events.patch({
    calendarId: `${videoCallCreator}`,
    eventId: `${idEvent}`,
    resource: event,
  });
}

function updateBatchAppointmentOnDB(
  {
    newSelectedData,
    saveBatchedOption,
    appointment,
    rruleSettings,
    addressUid,
    agendaId,
    professionalInfo,
  },
  idToken,
  mainUser,
) {
  const auth = getAuth();
  const { currentUser } = auth;
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    ({ uid } = currentUser);
  }
  const config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };
  const bodyParameters = {
    uid,
    addressUid,
    agendaId,
    professionalProfile: professionalInfo,
    selectedData: newSelectedData,
    appointment,
    currentUser: currentUser.uid,
    saveBatchedOption,
  };
  if (!_.isEmpty(rruleSettings)) {
    bodyParameters.oldRuleSelectedRruleObj = rruleSettings.oldRuleSelectedRruleObj || null;
    bodyParameters.newRuleSelectedRruleObj = rruleSettings.newRuleSelectedRruleObj || null;
    bodyParameters.newBatchedId = rruleSettings.newBatchedId || null;
    bodyParameters.oldFirstRecurrenceDate = rruleSettings.oldFirstRecurrenceDate || null;
    bodyParameters.newFirstRecurrenceDate = rruleSettings.newFirstRecurrenceDate || null;
  }
  // return true;
  return axios.post(
    `${ROOT_URL}/updateBatchAppointment`,
    bodyParameters,
    config,
  );
}

export function* updateBatchAppointmentRequest() {
  yield takeEvery(actions.UPDATE_BATCH_APPOINTMENT_REQUEST, function* (action) {
    try {
      yield put({ type: actions.START_EDITING_APPOINTMENT_REQUEST });
      // yield call(sleep, 5000);
      const mainUser = yield select(getMainUserFromStore);
      const addressUid = yield select(getSelectedAddressFromStore);
      const agendaId = yield select(getSelectedAgendaFromStore);
      const customUsers = yield select(getCustomUsersFromStore);
      let gisUserProfile = yield select(getGisUserProfileFromStore);
      const idToken = yield call(getIdToken);
      const timestamp = moment().tz('America/Sao_Paulo').format();
      const {
        selectedData,
        saveBatchedOption,
        params,
        videoCallCreator,
        wasVideoCall,
        videoCallId,
      } = action.payload;
      const {
        time,
        duration,
        isVideoCall,
        videoCallMode,
      } = params;
      let profile = yield select(getProfileFromStore);
      if (_.isEmpty(profile)) {
        yield take(profileActions.PROFILE_INFO_SUCCESS);
        profile = yield select(getProfileFromStore);
      }
      let professionalInfo = { ...profile };
      delete professionalInfo.keywords;
      delete professionalInfo.addresses;
      if (params.professional && params.professional !== professionalInfo.id) {
        const foundProfessional = customUsers.find((el) => action.payload.professional === el.id);
        professionalInfo = { ...foundProfessional };
      }
      const appointment = {
        ...action.payload.params,
        manuallySendMessage: selectedData.manuallySendMessage || null,
        timestamp,
        address: addressUid,
      };
      const newSelectedData = _.cloneDeep(selectedData);
      delete newSelectedData.start;
      delete newSelectedData.end;
      delete newSelectedData.oldTime;
      delete newSelectedData.allDay;
      delete newSelectedData.blockAll;
      delete newSelectedData.type;
      delete newSelectedData.logs;
      const rruleSettings = {};
      if (saveBatchedOption === 'this-and-following') {
        // Old rule that will be edited to have a UNTIL param set.
        const oldRule = rrulestr(newSelectedData.selectedRrule.rrule);
        const newEndDateMoment = moment(selectedData.time, 'YYYY-MM-DD HH:mm').subtract(1, 'days');
        const newEndDate = datetime(newEndDateMoment.year(), newEndDateMoment.month() + 1, newEndDateMoment.date());
        const oldRuleWithEndDate = new RRule({
          dtstart: oldRule.options.dtstart,
          freq: oldRule.options.freq,
          interval: oldRule.options.interval,
          until: newEndDate, // new "until" date
          count: null,
          wkst: RRule.SU,
          byweekday: oldRule.options.byweekday,
          bymonthday: oldRule.options.bymonthday,
          bysetpos: oldRule.origOptions.bysetpos,
          bymonth: oldRule.origOptions.bymonth,
          byyearday: oldRule.origOptions.byyearday,
          byweekno: oldRule.origOptions.byweekno,
        });
        const oldRuleWithEndDateForGoogleCalendar = new RRule({
          dtstart: oldRule.options.dtstart,
          freq: oldRule.options.freq,
          interval: oldRule.options.interval,
          until: datetime(newEndDateMoment.year(), newEndDateMoment.month() + 1, newEndDateMoment.date() + 1),
          count: null,
          wkst: RRule.SU,
          byweekday: oldRule.options.byweekday,
          bymonthday: oldRule.options.bymonthday,
          bysetpos: oldRule.origOptions.bysetpos,
          bymonth: oldRule.origOptions.bymonth,
          byyearday: oldRule.origOptions.byyearday,
          byweekno: oldRule.origOptions.byweekno,
        });
        let oldRuleQueryTimestamp = '2199-01-01';
        if (oldRuleWithEndDate.options.until) {
          oldRuleQueryTimestamp = moment.utc(oldRuleWithEndDate.options.until).format('YYYY-MM-DD');
        }
        if (oldRuleWithEndDate.options.count) {
          const last = oldRuleWithEndDate.all().pop();
          oldRuleQueryTimestamp = moment.utc(last).format('YYYY-MM-DD');
        }
        const {
          selectedRrule,
        } = selectedData;
        const oldRuleSelectedRruleObj = {
          rrule: oldRuleWithEndDate.toString(),
          rruleFroGoogleCalendar: oldRuleWithEndDateForGoogleCalendar.toString(),
          queryTimestamp: oldRuleQueryTimestamp,
          selectedValue: selectedRrule.selectedValue,
          label: sanitizeStringForRrule(oldRuleWithEndDate.toText(gettextRrule, PORTUGUESE)),
        };
        // New rule with the new time settings to apply to this and following appointments.
        const newRule = rrulestr(params.selectedRrule.rrule);
        const newStartDateMoment = moment(params.time, 'YYYY-MM-DD HH:mm');
        const newStartDate = datetime(newStartDateMoment.year(), newStartDateMoment.month() + 1, newStartDateMoment.date());
        const newRuleWithStartDate = new RRule({
          dtstart: newStartDate, // new "start" date
          freq: newRule.origOptions.freq,
          interval: newRule.origOptions.interval,
          until: newRule.origOptions.until,
          // count: null,
          count: newRule.origOptions.count,
          wkst: RRule.SU,
          byweekday: newRule.origOptions.byweekday,
          bymonthday: newRule.origOptions.bymonthday,
          bysetpos: newRule.origOptions.bysetpos,
          bymonth: newRule.origOptions.bymonth,
          byyearday: newRule.origOptions.byyearday,
          byweekno: newRule.origOptions.byweekno,
        });
        let newRuleQueryTimestamp = '2199-01-01';
        if (newRuleWithStartDate.options.until) {
          newRuleQueryTimestamp = moment.utc(newRuleWithStartDate.options.until).format('YYYY-MM-DD');
        }
        if (newRuleWithStartDate.options.count) {
          const last = newRuleWithStartDate.all().pop();
          newRuleQueryTimestamp = moment.utc(last).format('YYYY-MM-DD');
        }
        const newRuleSelectedRruleObj = {
          rrule: newRuleWithStartDate.toString(),
          queryTimestamp: newRuleQueryTimestamp,
          selectedValue: params.selectedRrule.selectedValue || 'custom-defined',
          label: params.selectedRrule.selectedValue && params.selectedRrule.selectedValue !== 'custom-defined'
            ? params.selectedRrule.label
            : sanitizeStringForRrule(newRuleWithStartDate.toText(gettextRrule, PORTUGUESE)),
        };
        const db = getDatabase();
        const auth = getAuth();
        const { currentUser } = auth;
        let uid;
        if (mainUser) {
          uid = mainUser;
        } else {
          ({ uid } = currentUser);
        }
        const newBatchedId = push(child(ref(db), `batchedAppointments/${uid}/${agendaId}/batched`)).key;

        const currentDate = moment().tz('America/Sao_Paulo').add(1, 'day');
        const oldRuleAfter = oldRuleWithEndDate.after(datetime(currentDate.year(), currentDate.month() + 1, currentDate.date()), true);
        let oldFirstRecurrenceDate = null;
        if (oldRuleAfter) {
          oldFirstRecurrenceDate = moment(
            oldRuleAfter,
          ).utcOffset(0).format('YYYY-MM-DD');
        }
        const newRuleAfter = newRuleWithStartDate.after(datetime(currentDate.year(), currentDate.month() + 1, currentDate.date()), true);
        // const newRuleAfter = null;
        let newFirstRecurrenceDate = null;
        if (newRuleAfter) {
          newFirstRecurrenceDate = moment(
            newRuleAfter,
          ).utcOffset(0).format('YYYY-MM-DD');
        }

        rruleSettings.oldFirstRecurrenceDate = oldFirstRecurrenceDate;
        rruleSettings.newFirstRecurrenceDate = newFirstRecurrenceDate;
        rruleSettings.oldRuleSelectedRruleObj = oldRuleSelectedRruleObj;
        rruleSettings.newRuleSelectedRruleObj = newRuleSelectedRruleObj;
        rruleSettings.newBatchedId = newBatchedId;

        // Need to edit old selectedRrule to use UNTIL or COUNT
        const oldRecurrenceWithEndDate = oldRuleSelectedRruleObj.rruleFroGoogleCalendar.split('\n')[1];
        const newRecurrenceWithStartDate = newRuleSelectedRruleObj.rrule.split('\n')[1];
        if (videoCallMode === 'meets') {
          if (isVideoCall) {
            if (!gisUserProfile) {
              notificationAntd.warning({
                message: 'Você não está logado com a conta Google utilizada ao criar a consulta.',
                description: (
                  <span>
                    As alterações de horário ou duração da consulta não serão atualizadas no seu Google Calendar.&nbsp;
                    <Button
                      type="link"
                      style={{ padding: 0, height: '21px' }}
                      onClick={() => {
                        googleAuthRequestChannel.put({ type: gapiActions.GIS_AUTHORIZATION_REQUEST });
                      }}
                    >
                      Clique aqui para acessar sua conta Google
                    </Button>
                    &nbsp;ou&nbsp;
                    <Button
                      type="link"
                      style={{ padding: 0, height: '21px' }}
                      onClick={() => {
                        googleAuthRequestChannel.put({ type: gapiActions.CONTINUE_WITHOUT_GIS_AUTHORIZATION });
                      }}
                    >
                      continue sem uma conta Google.
                    </Button>
                  </span>
                ),
                duration: null,
                key: 'gapi-notification',
                onClose: () => googleAuthRequestChannel.put({ type: gapiActions.CONTINUE_WITHOUT_GIS_AUTHORIZATION }),
              });
              const actionTriggered = yield take([
                gapiActions.CONTINUE_WITHOUT_GIS_AUTHORIZATION,
                gapiActions.SET_GIS_ACCESS_TOKEN,
              ]);
              notificationAntd.destroy('gapi-notification');
              if (actionTriggered.type === 'SET_GIS_ACCESS_TOKEN') {
                gisUserProfile = yield select(getGisUserProfileFromStore);
              }
            }
            if (wasVideoCall) {
              // It IS and it already WAS video call.
              if (!window.gapi || !gisUserProfile) {
                notification(
                  'error',
                  'Algo deu errado para se comunicar com os servidores da Google',
                  'As alterações de horário ou duração da consulta não serão atualizadas no seu Google Calendar.',
                );
              } else if (window.gapi.client.getToken() === null) {
                notification(
                  'error',
                  'Não há permissão suficiente de uma conta no Google Calendar para habilitar vídeo chamada',
                  'As alterações de horário ou duração da consulta não serão atualizadas no seu Google Calendar.',
                );
              } else if (gisUserProfile?.email === action.payload.selectedData?.videoCallData?.videoCallCreator) {
                // User is logged in and it is the same account when created.
                // Changing recurrence to have a end date on Google Calendar
                yield call(
                  handleUpdateRecurrenceGoogleMeetEvent,
                  videoCallId,
                  videoCallCreator,
                  oldRecurrenceWithEndDate,
                );
                // Creating new event with recurrence on Google Calendar with new start date
                const fullVideoCallLink = yield call(
                  handleCreateGoogleMeetingEvent,
                  action.payload.selectedData.id,
                  time,
                  duration,
                  selectedData.userInfo,
                  newRecurrenceWithStartDate,
                );
                appointment.videoCallData = {};
                appointment.videoCallData.videoCallLink = fullVideoCallLink.result.hangoutLink;
                appointment.videoCallData.videoCallCreator = fullVideoCallLink.result.creator.email;
                appointment.videoCallData.videoCallId = fullVideoCallLink.result.id;
              } else if (gisUserProfile?.email !== action.payload.selectedData?.videoCallData?.videoCallCreator) {
                // User is logged in but it is NOT the same account when created.
                notification(
                  'warning',
                  'A conta Google não é a mesma utilizada ao criar a consulta',
                  'As alterações de horário ou duração da consulta não serão atualizadas no seu Google Calendar.',
                );
                // throw new Error('Wrong Google account connected.');
              }
            }

            if (!wasVideoCall) {
              // It is video call now, before it was not.
              if (window.gapi.client.getToken() === null || !gisUserProfile) {
                // User is not logged in.
                notification('error', 'Não foi possível entrar em uma conta no Google Calendar para habilitar vídeo chamada.');
                throw new Error('No Google account connected.');
              } else {
                // User is logged in.
                // Proceed to update recurring Google event.
                const fullVideoCallLink = yield call(
                  handleCreateGoogleMeetingEvent,
                  action.payload.selectedData.id,
                  time,
                  duration,
                  selectedData.userInfo,
                  newRecurrenceWithStartDate,
                );
                appointment.videoCallData = {};
                appointment.videoCallData.videoCallLink = fullVideoCallLink.result.hangoutLink;
                appointment.videoCallData.videoCallCreator = fullVideoCallLink.result.creator.email;
                appointment.videoCallData.videoCallId = fullVideoCallLink.result.id;
              }
            }
          } else if (wasVideoCall) {
            // Video call switch is NOT checked (isVideoCall is false),
            // but previously it was.
            if (gisUserProfile?.email === action.payload.selectedData?.videoCallData?.videoCallCreator) {
              try {
                // Changing recurrence to have a end date on Google Calendar
                yield call(
                  handleUpdateRecurrenceGoogleMeetEvent,
                  videoCallId,
                  videoCallCreator,
                  oldRecurrenceWithEndDate,
                );
              } catch (error) {
                console.warn(error);
                if (error?.result?.error?.message === 'Resource has been deleted') {
                  notification('info', 'O evento em seu Google Calendar já havia sido apagado.');
                } else {
                  throw (error);
                }
              }
              appointment.videoCallData = {};
              appointment.videoCallData.videoCallLink = '';
              appointment.videoCallData.videoCallCreator = '';
              appointment.videoCallData.videoCallId = '';
            } else {
              // User is not connected to the correct Google account (or not connected at all).
              notification(
                'error',
                'A conta Google não é a mesma utilizada ao habilitar a teleconsulta',
                'É necessário estar conectado com a mesma conta para poder apagá-la do Google Calendar',
              );
              throw new Error('No correct Google account connected.');
            }
          }
        }
      } else if (saveBatchedOption === 'all') {
        if (videoCallMode === 'meets') {
          if (isVideoCall) {
            const currentRule = rrulestr(newSelectedData.selectedRrule.rrule);
            const currentRuleStart = moment.utc(currentRule.options.dtstart).format('YYYY-MM-DD');
            if (!gisUserProfile) {
              notificationAntd.warning({
                message: 'Você não está logado com a conta Google utilizada ao criar a consulta.',
                description: (
                  <span>
                    As alterações de horário ou duração da consulta não serão atualizadas no seu Google Calendar.&nbsp;
                    <Button
                      type="link"
                      style={{ padding: 0, height: '21px' }}
                      onClick={() => {
                        googleAuthRequestChannel.put({ type: gapiActions.GIS_AUTHORIZATION_REQUEST });
                      }}
                    >
                      Clique aqui para acessar sua conta Google
                    </Button>
                    &nbsp;ou&nbsp;
                    <Button
                      type="link"
                      style={{ padding: 0, height: '21px' }}
                      onClick={() => {
                        googleAuthRequestChannel.put({ type: gapiActions.CONTINUE_WITHOUT_GIS_AUTHORIZATION });
                      }}
                    >
                      continue sem uma conta Google.
                    </Button>
                  </span>
                ),
                duration: null,
                key: 'gapi-notification',
                onClose: () => googleAuthRequestChannel.put({ type: gapiActions.CONTINUE_WITHOUT_GIS_AUTHORIZATION }),
              });
              const actionTriggered = yield take([
                gapiActions.CONTINUE_WITHOUT_GIS_AUTHORIZATION,
                gapiActions.SET_GIS_ACCESS_TOKEN,
              ]);
              notificationAntd.destroy('gapi-notification');
              if (actionTriggered.type === 'SET_GIS_ACCESS_TOKEN') {
                gisUserProfile = yield select(getGisUserProfileFromStore);
              }
            }
            if (wasVideoCall) {
              // It IS and it already WAS video call.
              if (!window.gapi || !gisUserProfile) {
                notification(
                  'error',
                  'Algo deu errado para se comunicar com os servidores da Google',
                  'As alterações de horário ou duração da consulta não serão atualizadas no seu Google Calendar.',
                );
              } else if (window.gapi.client.getToken() === null) {
                notification(
                  'error',
                  'Não há permissão suficiente de uma conta no Google Calendar para habilitar vídeo chamada',
                  'As alterações de horário ou duração da consulta não serão atualizadas no seu Google Calendar.',
                );
              } else if (gisUserProfile?.email === action.payload.selectedData?.videoCallData?.videoCallCreator) {
                // User is logged in and it is the same account when created.
                yield call(
                  handleUpdateRecurrenceGoogleMeetEvent,
                  videoCallId,
                  videoCallCreator,
                  params.selectedRrule.rrule.split('\n')[1],
                  `${currentRuleStart} ${time.split(' ')[1]}`,
                  duration,
                );
              } else if (gisUserProfile?.email !== action.payload.selectedData?.videoCallData?.videoCallCreator) {
                // User is logged in but it is NOT the same account when created.
                notification(
                  'warning',
                  'A conta Google não é a mesma utilizada ao criar a consulta',
                  'As alterações de horário ou duração da consulta não serão atualizadas no seu Google Calendar.',
                );
                // throw new Error('Wrong Google account connected.');
              }
            }

            if (!wasVideoCall) {
              // It is video call now, before it was not.
              if (window.gapi.client.getToken() === null || !gisUserProfile) {
                // User is not logged in.
                notification('error', 'Não foi possível entrar em uma conta no Google Calendar para habilitar vídeo chamada.');
                throw new Error('No Google account connected.');
              } else {
                // User is logged in.
                // Proceed to update recurring Google event.
                const fullVideoCallLink = yield call(
                  handleCreateGoogleMeetingEvent,
                  action.payload.selectedData.id,
                  `${currentRuleStart} ${time.split(' ')[1]}`,
                  duration,
                  selectedData.userInfo,
                  params.selectedRrule.rrule.split('\n')[1],
                );
                appointment.videoCallData = {};
                appointment.videoCallData.videoCallLink = fullVideoCallLink.result.hangoutLink;
                appointment.videoCallData.videoCallCreator = fullVideoCallLink.result.creator.email;
                appointment.videoCallData.videoCallId = fullVideoCallLink.result.id;
              }
            }
          } else if (wasVideoCall) {
            // Video call switch is NOT checked (isVideoCall is false),
            // but previously it was.
            if (gisUserProfile?.email === action.payload.selectedData?.videoCallData?.videoCallCreator) {
              try {
                yield call(handleDeleteEvent, videoCallId, videoCallCreator);
              } catch (error) {
                console.warn(error);
                if (error?.result?.error?.message === 'Resource has been deleted') {
                  notification('info', 'O evento em seu Google Calendar já havia sido apagado.');
                } else {
                  throw (error);
                }
              }
              appointment.videoCallData = {};
              appointment.videoCallData.videoCallLink = '';
              appointment.videoCallData.videoCallCreator = '';
              appointment.videoCallData.videoCallId = '';
            } else {
              // User is not connected to the correct Google account (or not connected at all).
              notification(
                'error',
                'A conta Google não é a mesma utilizada ao habilitar a teleconsulta',
                'É necessário estar conectado com a mesma conta para poder apagá-la do Google Calendar',
              );
              throw new Error('No correct Google account connected.');
            }
          }
        }
        const currentDate = moment().tz('America/Sao_Paulo').add(1, 'day');
        const rule = rrulestr(newSelectedData.selectedRrule.rrule);
        const oldRuleAfter = rule.after(datetime(currentDate.year(), currentDate.month() + 1, currentDate.date()), true);
        let oldFirstRecurrenceDate = null;
        if (oldRuleAfter) {
          oldFirstRecurrenceDate = moment(
            oldRuleAfter,
          ).utcOffset(0).format('YYYY-MM-DD');
        }
        rruleSettings.oldFirstRecurrenceDate = oldFirstRecurrenceDate;
      }
      yield call(updateBatchAppointmentOnDB, {
        newSelectedData,
        saveBatchedOption,
        appointment,
        rruleSettings,
        addressUid,
        agendaId,
        professionalInfo,
      }, idToken, mainUser);
      yield put({
        type: actions.EDIT_WHOLE_APPOINTMENT_SUCCESS,
      });
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.EDIT_WHOLE_APPOINTMENT_ERROR,
      });
    }
  });
}

function cancelBatchAppointmentOnDB(
  {
    saveBatchedOption,
    selectedData,
    rruleSettings,
    addressUid,
    agendaId,
  },
  idToken,
  mainUser,
) {
  const auth = getAuth();
  const { currentUser } = auth;
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    ({ uid } = currentUser);
  }
  const config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };
  const newObj = _.cloneDeep(selectedData);
  delete newObj.start;
  delete newObj.end;
  delete newObj.allDay;
  delete newObj.blockAll;
  delete newObj.type;
  delete newObj.logs;
  const bodyParameters = {
    uid,
    addressUid,
    agendaId,
    selectedData: newObj,
    currentUser: currentUser.uid,
    saveBatchedOption,
  };
  if (!_.isEmpty(rruleSettings)) {
    bodyParameters.oldRuleSelectedRruleObj = rruleSettings.oldRuleSelectedRruleObj;
  }
  // return true;
  return axios.post(
    `${ROOT_URL}/cancelBatchAppointment`,
    bodyParameters,
    config,
  );
}

export function* cancelBatchAppointmentRequest() {
  yield takeEvery(actions.CANCEL_BATCH_APPOINTMENT_REQUEST, function* (action) {
    try {
      yield put({
        type: actions.START_CANCELING_APPOINTMENT,
        payload: true,
      });
      const mainUser = yield select(getMainUserFromStore);
      const addressUid = yield select(getSelectedAddressFromStore);
      const agendaId = yield select(getSelectedAgendaFromStore);
      let gisUserProfile = yield select(getGisUserProfileFromStore);
      const idToken = yield call(getIdToken);
      const {
        selectedData,
        saveBatchedOption,
      } = action.payload;
      const rruleSettings = {};
      if (saveBatchedOption === 'only-this') {
        if (selectedData.isVideoCall && selectedData.videoCallMode === 'meets') {
          if (!gisUserProfile) {
            notificationAntd.warning({
              message: 'Você não está logado com a conta Google utilizada ao criar a consulta.',
              description: (
                <span>
                  As alterações de horário ou duração da consulta não serão atualizadas no seu Google Calendar.&nbsp;
                  <Button
                    type="link"
                    style={{ padding: 0, height: '21px' }}
                    onClick={() => {
                      googleAuthRequestChannel.put({ type: gapiActions.GIS_AUTHORIZATION_REQUEST });
                    }}
                  >
                    Clique aqui para acessar sua conta Google
                  </Button>
                  &nbsp;ou&nbsp;
                  <Button
                    type="link"
                    style={{ padding: 0, height: '21px' }}
                    onClick={() => {
                      googleAuthRequestChannel.put({ type: gapiActions.CONTINUE_WITHOUT_GIS_AUTHORIZATION });
                    }}
                  >
                    continue sem uma conta Google.
                  </Button>
                </span>
              ),
              duration: null,
              key: 'gapi-notification',
              onClose: () => googleAuthRequestChannel.put({ type: gapiActions.CONTINUE_WITHOUT_GIS_AUTHORIZATION }),
            });
            const actionTriggered = yield take([
              gapiActions.CONTINUE_WITHOUT_GIS_AUTHORIZATION,
              gapiActions.SET_GIS_ACCESS_TOKEN,
            ]);
            notificationAntd.destroy('gapi-notification');
            if (actionTriggered.type === 'SET_GIS_ACCESS_TOKEN') {
              gisUserProfile = yield select(getGisUserProfileFromStore);
            }
          }
          if (!window.gapi || !gisUserProfile) {
            notification(
              'error',
              'Algo deu errado para se comunicar com os servidores da Google',
              'As alterações de horário ou duração da consulta não serão atualizadas no seu Google Calendar.',
            );
          } else if (window.gapi.client.getToken() === null) {
            notification(
              'error',
              'Não há permissão suficiente de uma conta no Google Calendar para habilitar vídeo chamada',
              'As alterações de horário ou duração da consulta não serão atualizadas no seu Google Calendar.',
            );
          } else if (gisUserProfile?.email === action.payload.selectedData?.videoCallData?.videoCallCreator) {
            // User is logged in and it is the same account when created.
            const instances = yield call(getCalendarEventsInstance, selectedData.videoCallData.videoCallId, selectedData.videoCallData.videoCallCreator);
            if (instances?.result?.items?.length > 0) {
              const firstEvent = instances.result.items[0];
              const foundEvent = instances.result.items.find((el) => {
                if (el?.originalStartTime?.dateTime && selectedData.time.split(' ')[0] === moment(el.originalStartTime.dateTime).format('YYYY-MM-DD')) {
                  return true;
                }
                return false;
              });
              if (foundEvent) {
                // Need to remove this event from its batch.
                yield call(
                  handleCancelSingleRecurringEvent,
                  foundEvent.id,
                  foundEvent,
                  firstEvent,
                  selectedData.videoCallData.videoCallCreator,
                  selectedData.time,
                  selectedData.duration,
                  // selectedData.userInfo,
                );
              }
            }
          } else if (gisUserProfile?.email !== action.payload.selectedData?.videoCallData?.videoCallCreator) {
            // User is logged in but it is NOT the same account when created.
            notification(
              'warning',
              'A conta Google não é a mesma utilizada ao criar a consulta',
              'As alterações de horário ou duração da consulta não serão atualizadas no seu Google Calendar.',
            );
            // throw new Error('Wrong Google account connected.');
          }
        }
      } else if (saveBatchedOption === 'this-and-following') {
        const oldRule = rrulestr(selectedData.selectedRrule.rrule);
        const newEndDateMoment = moment(selectedData.time, 'YYYY-MM-DD HH:mm').subtract(1, 'days');
        const newEndDate = datetime(newEndDateMoment.year(), newEndDateMoment.month() + 1, newEndDateMoment.date());
        const oldRuleWithEndDate = new RRule({
          dtstart: oldRule.options.dtstart,
          freq: oldRule.options.freq,
          interval: oldRule.options.interval,
          until: newEndDate, // new "until" date
          count: null,
          wkst: RRule.SU,
          byweekday: oldRule.options.byweekday,
          bymonthday: oldRule.options.bymonthday,
          bysetpos: oldRule.origOptions.bysetpos,
          bymonth: oldRule.origOptions.bymonth,
          byyearday: oldRule.origOptions.byyearday,
          byweekno: oldRule.origOptions.byweekno,
        });
        const currentDate = moment().tz('America/Sao_Paulo').add(1, 'day');
        const ruleAfter = oldRuleWithEndDate.after(datetime(currentDate.year(), currentDate.month() + 1, currentDate.date()), true);
        let firstRecurrenceDate = null;
        if (ruleAfter) {
          firstRecurrenceDate = moment(
            ruleAfter,
          ).utcOffset(0).format('YYYY-MM-DD');
        }
        const oldRuleWithEndDateForGoogleCalendar = new RRule({
          dtstart: oldRule.options.dtstart,
          freq: oldRule.options.freq,
          interval: oldRule.options.interval,
          until: datetime(newEndDateMoment.year(), newEndDateMoment.month() + 1, newEndDateMoment.date() + 1),
          count: null,
          wkst: RRule.SU,
          byweekday: oldRule.options.byweekday,
          bymonthday: oldRule.options.bymonthday,
          bysetpos: oldRule.origOptions.bysetpos,
          bymonth: oldRule.origOptions.bymonth,
          byyearday: oldRule.origOptions.byyearday,
          byweekno: oldRule.origOptions.byweekno,
        });
        let oldRuleQueryTimestamp = '2199-01-01';
        if (oldRuleWithEndDate.options.until) {
          oldRuleQueryTimestamp = moment.utc(oldRuleWithEndDate.options.until).format('YYYY-MM-DD');
        }
        if (oldRuleWithEndDate.options.count) {
          const last = oldRuleWithEndDate.all().pop();
          oldRuleQueryTimestamp = moment.utc(last).format('YYYY-MM-DD');
        }
        const {
          selectedRrule,
        } = selectedData;
        const oldRuleSelectedRruleObj = {
          rrule: oldRuleWithEndDate.toString(),
          rruleFroGoogleCalendar: oldRuleWithEndDateForGoogleCalendar.toString(),
          queryTimestamp: oldRuleQueryTimestamp,
          selectedValue: selectedRrule.selectedValue,
          label: sanitizeStringForRrule(oldRuleWithEndDate.toText(gettextRrule, PORTUGUESE)),
          firstRecurrenceDate: ruleAfter ? `${firstRecurrenceDate} ${selectedData.time.split(' ')[1]}` : null,
        };
        rruleSettings.oldRuleSelectedRruleObj = oldRuleSelectedRruleObj;
        // Need to edit old selectedRrule to use UNTIL or COUNT
        const oldRecurrenceWithEndDate = oldRuleSelectedRruleObj.rruleFroGoogleCalendar.split('\n')[1];
        if (selectedData.isVideoCall && selectedData.videoCallMode === 'meets') {
          if (!gisUserProfile) {
            notificationAntd.warning({
              message: 'Você não está logado com a conta Google utilizada ao criar a consulta.',
              description: (
                <span>
                  As alterações de horário ou duração da consulta não serão atualizadas no seu Google Calendar.&nbsp;
                  <Button
                    type="link"
                    style={{ padding: 0, height: '21px' }}
                    onClick={() => {
                      googleAuthRequestChannel.put({ type: gapiActions.GIS_AUTHORIZATION_REQUEST });
                    }}
                  >
                    Clique aqui para acessar sua conta Google
                  </Button>
                  &nbsp;ou&nbsp;
                  <Button
                    type="link"
                    style={{ padding: 0, height: '21px' }}
                    onClick={() => {
                      googleAuthRequestChannel.put({ type: gapiActions.CONTINUE_WITHOUT_GIS_AUTHORIZATION });
                    }}
                  >
                    continue sem uma conta Google.
                  </Button>
                </span>
              ),
              duration: null,
              key: 'gapi-notification',
              onClose: () => googleAuthRequestChannel.put({ type: gapiActions.CONTINUE_WITHOUT_GIS_AUTHORIZATION }),
            });
            const actionTriggered = yield take([
              gapiActions.CONTINUE_WITHOUT_GIS_AUTHORIZATION,
              gapiActions.SET_GIS_ACCESS_TOKEN,
            ]);
            notificationAntd.destroy('gapi-notification');
            if (actionTriggered.type === 'SET_GIS_ACCESS_TOKEN') {
              gisUserProfile = yield select(getGisUserProfileFromStore);
            }
          }
          if (!window.gapi || !gisUserProfile) {
            notification(
              'error',
              'Algo deu errado para se comunicar com os servidores da Google',
              'As alterações de horário ou duração da consulta não serão atualizadas no seu Google Calendar.',
            );
          } else if (window.gapi.client.getToken() === null) {
            notification(
              'error',
              'Não há permissão suficiente de uma conta no Google Calendar para habilitar vídeo chamada',
              'As alterações de horário ou duração da consulta não serão atualizadas no seu Google Calendar.',
            );
          } else if (gisUserProfile?.email === action.payload.selectedData?.videoCallData?.videoCallCreator) {
            // User is logged in and it is the same account when created.
            const { videoCallId, videoCallCreator } = selectedData.videoCallData;
            try {
              // Changing recurrence to have a end date on Google Calendar
              yield call(
                handleUpdateRecurrenceGoogleMeetEvent,
                videoCallId,
                videoCallCreator,
                oldRecurrenceWithEndDate,
              );
            } catch (error) {
              console.warn(error);
              if (error?.result?.error?.message === 'Resource has been deleted') {
                notification('info', 'O evento em seu Google Calendar já havia sido apagado.');
              } else {
                throw (error);
              }
            }
          } else if (gisUserProfile?.email !== action.payload.selectedData?.videoCallData?.videoCallCreator) {
            // User is logged in but it is NOT the same account when created.
            notification(
              'warning',
              'A conta Google não é a mesma utilizada ao criar a consulta',
              'As alterações de horário ou duração da consulta não serão atualizadas no seu Google Calendar.',
            );
            // throw new Error('Wrong Google account connected.');
          }
        }
      } else if (saveBatchedOption === 'all') {
        if (selectedData.isVideoCall && selectedData.videoCallMode === 'meets') {
          if (!gisUserProfile) {
            notificationAntd.warning({
              message: 'Você não está logado com a conta Google utilizada ao criar a consulta.',
              description: (
                <span>
                  As alterações de horário ou duração da consulta não serão atualizadas no seu Google Calendar.&nbsp;
                  <Button
                    type="link"
                    style={{ padding: 0, height: '21px' }}
                    onClick={() => {
                      googleAuthRequestChannel.put({ type: gapiActions.GIS_AUTHORIZATION_REQUEST });
                    }}
                  >
                    Clique aqui para acessar sua conta Google
                  </Button>
                  &nbsp;ou&nbsp;
                  <Button
                    type="link"
                    style={{ padding: 0, height: '21px' }}
                    onClick={() => {
                      googleAuthRequestChannel.put({ type: gapiActions.CONTINUE_WITHOUT_GIS_AUTHORIZATION });
                    }}
                  >
                    continue sem uma conta Google.
                  </Button>
                </span>
              ),
              duration: null,
              key: 'gapi-notification',
              onClose: () => googleAuthRequestChannel.put({ type: gapiActions.CONTINUE_WITHOUT_GIS_AUTHORIZATION }),
            });
            const actionTriggered = yield take([
              gapiActions.CONTINUE_WITHOUT_GIS_AUTHORIZATION,
              gapiActions.SET_GIS_ACCESS_TOKEN,
            ]);
            notificationAntd.destroy('gapi-notification');
            if (actionTriggered.type === 'SET_GIS_ACCESS_TOKEN') {
              gisUserProfile = yield select(getGisUserProfileFromStore);
            }
          }
          if (!window.gapi || !gisUserProfile) {
            notification(
              'error',
              'Algo deu errado para se comunicar com os servidores da Google',
              'As alterações de horário ou duração da consulta não serão atualizadas no seu Google Calendar.',
            );
          } else if (window.gapi.client.getToken() === null) {
            notification(
              'error',
              'Não há permissão suficiente de uma conta no Google Calendar para habilitar vídeo chamada',
              'As alterações de horário ou duração da consulta não serão atualizadas no seu Google Calendar.',
            );
          } else if (gisUserProfile?.email === action.payload.selectedData?.videoCallData?.videoCallCreator) {
            // User is logged in and it is the same account when created.
            const { videoCallId, videoCallCreator } = selectedData.videoCallData;
            try {
              yield call(handleDeleteEvent, videoCallId, videoCallCreator);
            } catch (error) {
              console.warn(error);
              if (error?.result?.error?.message === 'Resource has been deleted') {
                notification('info', 'O evento em seu Google Calendar já havia sido apagado.');
              } else {
                throw (error);
              }
            }
          } else if (gisUserProfile?.email !== action.payload.selectedData?.videoCallData?.videoCallCreator) {
            // User is logged in but it is NOT the same account when created.
            notification(
              'warning',
              'A conta Google não é a mesma utilizada ao criar a consulta',
              'As alterações de horário ou duração da consulta não serão atualizadas no seu Google Calendar.',
            );
            // throw new Error('Wrong Google account connected.');
          }
        }
      }

      yield call(cancelBatchAppointmentOnDB, {
        saveBatchedOption,
        selectedData,
        rruleSettings,
        addressUid,
        agendaId,
      }, idToken, mainUser);
      yield all([
        put({ type: actions.CANCEL_APPOINTMENT_SUCCESS }),
        put({
          type: calendarActions.UPDATE_SELECTED_DATA_REQUEST,
          payload: action.payload.id,
        }),
      ]);
    } catch (error) {
      console.warn(error);
      yield put({ type: actions.CANCEL_APPOINTMENT_ERROR });
    }
  });
}

function updateBatchBlockedOnDB(
  {
    editedSelectedData,
    newData,
    saveBatchedOption,
    rruleSettings,
    agendaId,
  },
  mainUser,
) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  if (saveBatchedOption === 'this-and-following') {
    const {
      oldRuleSelectedRruleObj,
      newBatchedId,
      newRuleSelectedRruleObj,
    } = rruleSettings;
    updates[`batchedAppointments/${uid}/${agendaId}/batched/${editedSelectedData.batchedId}/appointmentModel`] = {
      ...editedSelectedData,
      selectedRrule: oldRuleSelectedRruleObj,
    };
    updates[`batchedAppointments/${uid}/${agendaId}/batched/${editedSelectedData.batchedId}/label`] = oldRuleSelectedRruleObj.label;
    updates[`batchedAppointments/${uid}/${agendaId}/batched/${editedSelectedData.batchedId}/queryTimestamp`] = oldRuleSelectedRruleObj.queryTimestamp;
    updates[`batchedAppointments/${uid}/${agendaId}/batched/${editedSelectedData.batchedId}/rrule`] = oldRuleSelectedRruleObj.rrule;
    updates[`batchedAppointments/${uid}/${agendaId}/batched/${editedSelectedData.batchedId}/selectedValue`] = oldRuleSelectedRruleObj.selectedValue;
    updates[`batchedAppointments/${uid}/${agendaId}/batched/${newBatchedId}`] = {
      blocked: true,
      appointmentModel: {
        ...newData,
        selectedRrule: newRuleSelectedRruleObj,
        allDay: false,
        mode: 'blocked',
      },
      ...newRuleSelectedRruleObj,
    };
  }
  if (saveBatchedOption === 'all') {
    const pushKey = editedSelectedData.batchedId || push(child(ref(db), `batchedAppointments/${uid}/${agendaId}/batched`)).key;
    const ruleWithOldStart = `${editedSelectedData.selectedRrule.rrule.split('\n')[0]}\n${newData.selectedRrule.rrule.split('\n')[1]}`;
    const fullData = {
      ...editedSelectedData,
      ...newData,
    };
    updates[`batchedAppointments/${uid}/${agendaId}/batched/${pushKey}`] = {
      appointmentModel: fullData,
      queryTimestamp: fullData.selectedRrule.queryTimestamp,
      rrule: ruleWithOldStart,
      selectedValue: fullData.selectedRrule.selectedValue,
      label: fullData.selectedRrule.label || null,
    };
  }
  // return true;
  return update(dbRef, updates);
}

export function* updateBatchBlockedRequest() {
  yield takeEvery(actions.UPDATE_BATCH_BLOCKED_REQUEST, function* (action) {
    try {
      yield put({ type: actions.START_BLOCKING_TIME });
      // yield call(sleep, 5000);
      const mainUser = yield select(getMainUserFromStore);
      const agendaId = yield select(getSelectedAgendaFromStore);
      const timestamp = moment().tz('America/Sao_Paulo').format();
      const {
        selectedData,
        saveBatchedOption,
        params,
      } = action.payload;
      let profile = yield select(getProfileFromStore);
      if (_.isEmpty(profile)) {
        yield take(profileActions.PROFILE_INFO_SUCCESS);
        profile = yield select(getProfileFromStore);
      }
      const newData = {
        ...action.payload.params,
        timestamp,
      };
      const editedSelectedData = _.cloneDeep(selectedData);
      delete editedSelectedData.id;
      delete editedSelectedData.start;
      delete editedSelectedData.end;
      delete editedSelectedData.oldTime;
      delete editedSelectedData.oldDuration;
      delete editedSelectedData.blockAll;
      delete editedSelectedData.type;
      delete editedSelectedData.logs;
      const rruleSettings = {};
      if (saveBatchedOption === 'this-and-following') {
        // Old rule that will be edited to have a UNTIL param set.
        const oldRule = rrulestr(editedSelectedData.selectedRrule.rrule);
        const newEndDateMoment = moment(selectedData.time, 'YYYY-MM-DD HH:mm').subtract(1, 'days');
        const newEndDate = datetime(newEndDateMoment.year(), newEndDateMoment.month() + 1, newEndDateMoment.date());
        const oldRuleWithEndDate = new RRule({
          dtstart: oldRule.options.dtstart,
          freq: oldRule.options.freq,
          interval: oldRule.options.interval,
          until: newEndDate, // new "until" date
          count: null,
          wkst: RRule.SU,
          byweekday: oldRule.options.byweekday,
          bymonthday: oldRule.options.bymonthday,
          bysetpos: oldRule.origOptions.bysetpos,
          bymonth: oldRule.origOptions.bymonth,
          byyearday: oldRule.origOptions.byyearday,
          byweekno: oldRule.origOptions.byweekno,
        });
        let oldRuleQueryTimestamp = '2199-01-01';
        if (oldRuleWithEndDate.options.until) {
          oldRuleQueryTimestamp = moment.utc(oldRuleWithEndDate.options.until).format('YYYY-MM-DD');
        }
        if (oldRuleWithEndDate.options.count) {
          const last = oldRuleWithEndDate.all().pop();
          oldRuleQueryTimestamp = moment.utc(last).format('YYYY-MM-DD');
        }
        const {
          selectedRrule,
        } = selectedData;
        const oldRuleSelectedRruleObj = {
          rrule: oldRuleWithEndDate.toString(),
          queryTimestamp: oldRuleQueryTimestamp,
          selectedValue: selectedRrule.selectedValue,
          label: sanitizeStringForRrule(oldRuleWithEndDate.toText(gettextRrule, PORTUGUESE)),
        };
        // New rule with the new time settings to apply to this and following appointments.
        const newRule = rrulestr(params.selectedRrule.rrule);
        const newStartDateMoment = moment(params.time, 'YYYY-MM-DD HH:mm');
        const newStartDate = datetime(newStartDateMoment.year(), newStartDateMoment.month() + 1, newStartDateMoment.date());
        const newRuleWithStartDate = new RRule({
          dtstart: newStartDate, // new "start" date
          freq: newRule.options.freq,
          interval: newRule.options.interval,
          until: newRule.options.until,
          // count: null,
          count: newRule.options.count,
          wkst: RRule.SU,
          byweekday: newRule.options.byweekday,
          bymonthday: newRule.options.bymonthday,
          bysetpos: newRule.origOptions.bysetpos,
          bymonth: newRule.origOptions.bymonth,
          byyearday: newRule.origOptions.byyearday,
          byweekno: newRule.origOptions.byweekno,
        });
        let newRuleQueryTimestamp = '2199-01-01';
        if (newRuleWithStartDate.options.until) {
          newRuleQueryTimestamp = moment.utc(newRuleWithStartDate.options.until).format('YYYY-MM-DD');
        }
        if (newRuleWithStartDate.options.count) {
          const last = newRuleWithStartDate.all().pop();
          newRuleQueryTimestamp = moment.utc(last).format('YYYY-MM-DD');
        }
        const newRuleSelectedRruleObj = {
          rrule: newRuleWithStartDate.toString(),
          queryTimestamp: newRuleQueryTimestamp,
          selectedValue: 'custom-defined',
          label: params.selectedRrule.selectedValue && params.selectedRrule.selectedValue !== 'custom-defined'
            ? params.selectedRrule.label
            : sanitizeStringForRrule(newRuleWithStartDate.toText(gettextRrule, PORTUGUESE)),
        };
        const db = getDatabase();
        const auth = getAuth();
        const { currentUser } = auth;
        let uid;
        if (mainUser) {
          uid = mainUser;
        } else {
          ({ uid } = currentUser);
        }
        const newBatchedId = push(child(ref(db), `batchedAppointments/${uid}/${agendaId}/batched`)).key;
        rruleSettings.oldRuleSelectedRruleObj = oldRuleSelectedRruleObj;
        rruleSettings.newRuleSelectedRruleObj = newRuleSelectedRruleObj;
        rruleSettings.newBatchedId = newBatchedId;
      } else if (saveBatchedOption === 'all') {
        const currentDate = moment().tz('America/Sao_Paulo').add(1, 'day');
        const rule = rrulestr(editedSelectedData.selectedRrule.rrule);
        const oldRuleAfter = rule.after(datetime(currentDate.year(), currentDate.month() + 1, currentDate.date()), true);
        let oldFirstRecurrenceDate = null;
        if (oldRuleAfter) {
          oldFirstRecurrenceDate = moment(
            oldRuleAfter,
          ).utcOffset(0).format('YYYY-MM-DD');
        }
        rruleSettings.oldFirstRecurrenceDate = oldFirstRecurrenceDate;
      }
      yield call(updateBatchBlockedOnDB, {
        editedSelectedData,
        newData,
        saveBatchedOption,
        rruleSettings,
        agendaId,
      }, mainUser);
      yield put({
        type: actions.BLOCK_TIME_SUCCESS,
      });
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.BLOCK_TIME_ERROR,
      });
    }
  });
}

function persistBlockTimeOnDB(
  selectedData,
  agendaId,
  mainUser,
) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const timestamp = moment().tz('America/Sao_Paulo').format();
  const updates = {};
  const newObj = _.cloneDeep(selectedData);
  delete newObj.batchedId;
  delete newObj.start;
  delete newObj.end;
  delete newObj.oldTime;
  delete newObj.allDay;
  delete newObj.blockAll;
  delete newObj.type;
  delete newObj.logs;
  updates[`requests/${uid}/${agendaId}/confirmed/${selectedData.id}`] = {
    ...newObj,
    timestamp,
    wasBatched: true,
  };
  updates[`batchedAppointments/${uid}/${agendaId}/persisted/${selectedData.id}`] = {
    time: selectedData.time,
    batchedId: selectedData.batchedId,
  };
  // return true;
  return update(dbRef, updates);
}

export function* persistBatchedBlockedRequest() {
  yield takeEvery(actions.PERSIST_BATCHED_BLOCKED_REQUEST, function* (action) {
    try {
      yield put({ type: actions.START_BLOCKING_TIME });
      const mainUser = yield select(getMainUserFromStore);
      const agendaId = yield select(getSelectedAgendaFromStore);
      let persistedBatchedAppointments = yield select(getPersistedBatchedAppointmentsFromStore);
      let profile = yield select(getProfileFromStore);
      if (_.isEmpty(profile)) {
        yield take(profileActions.PROFILE_INFO_SUCCESS);
        profile = yield select(getProfileFromStore);
      }
      if (!persistedBatchedAppointments) {
        yield take(actions.SET_PERSISTED_BATCHED_APPOINTMENTS);
        persistedBatchedAppointments = yield select(getPersistedBatchedAppointmentsFromStore);
      }
      const alreadyPersisted = Object.values(persistedBatchedAppointments).some((obj) => {
        if (obj.time === action.payload.selectedData.time) {
          return true;
        }
        return false;
      });
      if (!alreadyPersisted) {
        const {
          selectedData,
        } = action.payload;
        yield call(
          persistBlockTimeOnDB,
          selectedData,
          agendaId,
          mainUser,
        );
        yield put({
          type: actions.BLOCK_TIME_SUCCESS,
        });
      } else {
        yield put({
          type: actions.BLOCK_TIME_ERROR,
        });
      }
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.BLOCK_TIME_ERROR,
      });
    }
  });
}

function unblockBatchDaysOnDB(
  selectedData,
  rruleSettings,
  saveBatchedOption,
  agendaId,
  mainUser,
  queryPersisted = null,
) {
  const dbRef = ref(getDatabase());
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  if (saveBatchedOption === 'only-this') {
    updates[`batchedAppointments/${uid}/${agendaId}/persisted/${selectedData.id}`] = {
      time: selectedData.time,
      batchedId: selectedData.batchedId,
      cancelled: true,
    };
  }
  if (saveBatchedOption === 'this-and-following') {
    const {
      oldRuleSelectedRruleObj,
    } = rruleSettings;
    updates[`batchedAppointments/${uid}/${agendaId}/batched/${selectedData.batchedId}/appointmentModel`] = {
      ...selectedData,
      selectedRrule: oldRuleSelectedRruleObj,
    };
    updates[`batchedAppointments/${uid}/${agendaId}/batched/${selectedData.batchedId}/rrule`] = oldRuleSelectedRruleObj.rrule;
  }
  if (saveBatchedOption === 'all') {
    updates[`batchedAppointments/${uid}/${agendaId}/batched/${selectedData.batchedId}`] = null;
    if (queryPersisted.exists()) {
      queryPersisted.forEach((el) => {
        updates[`batchedAppointments/${uid}/${agendaId}/persisted/${el.key}`] = null;
      });
    }
  }
  // return true;
  return update(dbRef, updates);
}

function getQueryPersistedFromDB(selectedData, agendaId, mainUser) {
  const db = getDatabase();
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const databaseRef = ref(db, `batchedAppointments/${uid}/${agendaId}/persisted`);
  return get(dbQuery(
    databaseRef,
    orderByChild('batchedId'),
    equalTo(selectedData.batchedId),
  ));
}

export function* unblockBatchDaysRequest() {
  yield takeEvery(actions.UNBLOCK_BATCH_EVENT_REQUEST, function* (action) {
    try {
      yield put({
        type: actions.START_UNBLOCKING_DAY,
        payload: action.payload.event.id,
      });
      yield call(sleep, 500);
      const agendaId = yield select(getSelectedAgendaFromStore);
      const mainUser = yield select(getMainUserFromStore);
      const {
        event,
        saveBatchedOption,
      } = action.payload;
      const rruleSettings = {};
      let queryPersisted = null;
      if (saveBatchedOption === 'all') {
        queryPersisted = yield call(getQueryPersistedFromDB, event, agendaId, mainUser);
      } else if (saveBatchedOption === 'this-and-following') {
        const oldRule = rrulestr(event.selectedRrule.rrule);
        const newEndDateMoment = moment(event.time, 'YYYY-MM-DD HH:mm').subtract(1, 'days');
        const newEndDate = datetime(newEndDateMoment.year(), newEndDateMoment.month() + 1, newEndDateMoment.date());
        const oldRuleWithEndDate = new RRule({
          dtstart: oldRule.options.dtstart,
          freq: oldRule.options.freq,
          interval: oldRule.options.interval,
          until: newEndDate, // new "until" date
          count: null,
          wkst: RRule.SU,
          byweekday: oldRule.options.byweekday,
          bymonthday: oldRule.options.bymonthday,
          bysetpos: oldRule.origOptions.bysetpos,
          bymonth: oldRule.origOptions.bymonth,
          byyearday: oldRule.origOptions.byyearday,
          byweekno: oldRule.origOptions.byweekno,
        });
        const currentDate = moment().tz('America/Sao_Paulo').add(1, 'day');
        const ruleAfter = oldRuleWithEndDate.after(datetime(currentDate.year(), currentDate.month() + 1, currentDate.date()), true);
        let firstRecurrenceDate = null;
        if (ruleAfter) {
          firstRecurrenceDate = moment(
            ruleAfter,
          ).utcOffset(0).format('YYYY-MM-DD');
        }
        const oldRuleWithEndDateForGoogleCalendar = new RRule({
          dtstart: oldRule.options.dtstart,
          freq: oldRule.options.freq,
          interval: oldRule.options.interval,
          until: datetime(newEndDateMoment.year(), newEndDateMoment.month() + 1, newEndDateMoment.date() + 1),
          count: null,
          wkst: RRule.SU,
          byweekday: oldRule.options.byweekday,
          bymonthday: oldRule.options.bymonthday,
          bysetpos: oldRule.origOptions.bysetpos,
          bymonth: oldRule.origOptions.bymonth,
          byyearday: oldRule.origOptions.byyearday,
          byweekno: oldRule.origOptions.byweekno,
        });
        let oldRuleQueryTimestamp = '2199-01-01';
        if (oldRuleWithEndDate.options.until) {
          oldRuleQueryTimestamp = moment.utc(oldRuleWithEndDate.options.until).format('YYYY-MM-DD');
        }
        if (oldRuleWithEndDate.options.count) {
          const last = oldRuleWithEndDate.all().pop();
          oldRuleQueryTimestamp = moment.utc(last).format('YYYY-MM-DD');
        }
        const {
          selectedRrule,
        } = event;
        const oldRuleSelectedRruleObj = {
          rrule: oldRuleWithEndDate.toString(),
          rruleFroGoogleCalendar: oldRuleWithEndDateForGoogleCalendar.toString(),
          queryTimestamp: oldRuleQueryTimestamp,
          selectedValue: selectedRrule.selectedValue,
          label: sanitizeStringForRrule(oldRuleWithEndDate.toText(gettextRrule, PORTUGUESE)),
          firstRecurrenceDate: ruleAfter ? `${firstRecurrenceDate} ${event.time.split(' ')[1]}` : null,
        };
        rruleSettings.oldRuleSelectedRruleObj = oldRuleSelectedRruleObj;
      }
      yield call(
        unblockBatchDaysOnDB,
        event,
        rruleSettings,
        saveBatchedOption,
        agendaId,
        mainUser,
        queryPersisted,
      );
      yield put({
        type: actions.UNBLOCK_DAY_SUCCESS,
      });
    } catch (error) {
      console.warn(error);
      yield put({ type: actions.UNBLOCK_DAY_ERROR });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(buildFreeSchedule),
    fork(setSelectedAgendaOnLocalStorage),
    fork(fetchAgendasRequest),
    fork(saveAgendaSettings),
    fork(deleteAgendaRequest),
    fork(getConfirmed),
    fork(getPastConfirmed),
    fork(getPending),
    fork(getRejected),
    fork(getCanceled),
    fork(getPastCanceled),
    fork(getAccepted),
    fork(confirmPendingRequest),
    fork(rejectRequest),
    fork(editAppointmentTimeRequest),
    fork(sendMessageOnAppointmentEditTimeRequest),
    fork(sendMessageOnBacthedAppointmentEditTimeRequest),
    fork(editAppointmentNotesRequest),
    fork(setManualConfirmationMessageSent),
    fork(finalizeAppointmentRequest),
    fork(patientConfirmedAppointmentRequest),
    fork(cancelAppointmentRequest),
    fork(setSurgeryRequest),
    fork(manuallySetNewAppointmentRequest),
    fork(editWholeAppointmentRequest),
    fork(blockDaysRequest),
    fork(unblockDaysRequest),
    fork(unblockMultipleDaysRequest),
    fork(patientArrivedTime),
    fork(blockTimeRequest),
    fork(messageWasRead),
    fork(patientMissedAppointment),
    fork(patientAttendanceStarted),
    fork(clearAppointmentStatusRequest),
    fork(updateAppointmentValueRequest),
    fork(getPatientAppointmentsRequest),
    fork(finalizeAppointmentWithInvoiceRequest),
    fork(getFinalizedAppointmentRequest),
    fork(watchGoogleAuthRequestChannel),
    fork(getNewEntranceModalFullWidthRequest),
    fork(setNewEntranceModalFullWidthRequest),
    fork(setSelectedLabelRequest),
    fork(saveAgendaLabelRequest),
    fork(watchWaitListChannel),
    fork(getBatchedAppointmentsRequest),
    fork(getPersistedBatchedAppointmentsRequest),
    fork(generateBatchedAppointmentsEventsRequest),
    fork(createBatchAppointmentRequest),
    fork(persistBatchedAppointmentsRequest),
    fork(updateBatchAppointmentRequest),
    fork(cancelBatchAppointmentRequest),
    fork(updateBatchBlockedRequest),
    fork(persistBatchedBlockedRequest),
    fork(unblockBatchDaysRequest),
  ]);
}
