import _ from 'lodash';
import React from 'react';
import {
  getAuth,
} from 'firebase/auth';
import {
  getDatabase,
  ref,
  get,
  update,
  push,
  child,
  // startAfter,
  limitToFirst,
  query as dbQuery,
  orderByChild,
  startAt,
  // equalTo,
  onValue,
} from 'firebase/database';
import { eventChannel, channel } from 'redux-saga';
import moment from 'moment-timezone';
import axios from 'axios';
import { customAlphabet } from 'nanoid';
import {
  all,
  takeLatest,
  put,
  call,
  fork,
  select,
  take,
  takeEvery,
} from 'redux-saga/effects';
import { Button, notification as notificationAntd } from 'antd';
import actions from './actions';
import appActions from '../app/actions';
import profileActions from '../profile/actions';
import { notification } from '../../components';
import message from '../../components/feedback/message';
import { getBirthday } from '../../helpers/utility';

const ROOT_URL = process.env.REACT_APP_CLOUD_FUNCTIONS_ROOT_URL;

function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

const getUnifiedTokenStore = (state) => state.Auth.unified;

const getSelectedAddressFromStore = (state) => state.App.selectedAddress;

const getSeectedIdFromStore = (state) => state.Contacts.seectedId;

const getMainUserFromStore = (state) => state.Auth.mainUser;

const getLastExamFromStore = (state) => state.Exams.lastExam;

const getExamsFromStore = (state) => state.Exams.examHistory;

const getUnimedDocuments = (state) => state.Exams.unimedDocuments;

const getHealthProfessionalFromStore = (state) => state.Auth.healthProfessional;

const getProfileFromStore = (state) => state.Profile.profileOnDB;

const getCustomHealthProfessionalProfileFromStore = (state) => _.cloneDeep(state.Profile.customHealthProfessionalProfile);

const notificationRequestChannel = channel();

function* watchNotificationRequestChannel() {
  while (true) {
    yield call(sleep, 500);
    const action = yield take(notificationRequestChannel);
    yield put(action);
  }
}

function finalizeExameWihtoutSecretary(payload, pushKey, currentAddress, unified = {}, mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  const timestamp = moment().tz('America/Sao_Paulo');
  const objectToupdate = {
    exam: payload.exam,
    observation: payload.observation ? payload.observation : '',
    timestamp: timestamp.format(),
    queryTimestamp: -1 * parseInt(timestamp.format('x'), 10),
    mode: payload.mode || 'exam',
  };
  if (payload.userId) {
    if (unified.id && unified.address.some((a) => a === currentAddress)) {
      updates[`unified/${unified.id}/exams/${payload.userId}/finalized/${pushKey}`] = objectToupdate;
    } else {
      updates[`users/${uid}/exams/${payload.userId}/finalized/${pushKey}`] = objectToupdate;
    }
  }
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(pushKey);
  //     }
  //     return null;
  //   });
  // });
}

function finalizeExamOnDB(payload, currentAddress, unified = {}, mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  const timestamp = moment().tz('America/Sao_Paulo');
  const objectToupdate = {
    exam: payload.data.exam,
    observation: payload.data.observation ? payload.data.observation : '',
    timestamp: timestamp.format(),
    queryTimestamp: -1 * parseInt(timestamp.format('x'), 10),
    status: payload.success ? 'finalized' : 'rejected',
    mode: payload?.data?.mode || 'exam',
  };
  updates[`exams/${uid}/${currentAddress}/requests/${payload.key}`] = null;
  if (payload.data.userId) {
    if (unified.id && unified.address.some((a) => a === currentAddress)) {
      updates[`unified/${unified.id}/exams/${payload.data.userId}/finalized/${payload.key}`] = objectToupdate;
    } else {
      updates[`users/${uid}/exams/${payload.data.userId}/finalized/${payload.key}`] = objectToupdate;
    }
  }
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

export function* finalizeExam() {
  yield takeLatest(actions.FINALIZE_EXAMS_REQUEST, function* (action) {
    const currentAddress = yield select(getSelectedAddressFromStore);
    try {
      yield put({ type: actions.FINALIZE_EXAMS_WAITING });
      const unified = yield select(getUnifiedTokenStore);
      const mainUser = yield select(getMainUserFromStore);
      yield call(
        finalizeExamOnDB,
        action.payload,
        currentAddress,
        unified,
        mainUser,
      );
      yield put({
        type: actions.FINALIZE_EXAMS_SUCCESS,
      });
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.FINALIZE_EXAMS_ERROR,
        payload: {
          address: currentAddress,
        },
      });
    }
  });
}

function generatePushKey(currentAddress, mainUser) {
  const db = getDatabase();
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  return push(child(ref(db), `exams/${uid}/${currentAddress}/exams`)).key;
}

function exameRequestOnDB(payload, pushKey, currentAddress, unified = {}, mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  if (payload.isExamRequest) {
    const cleanData = _.omit(payload, _.isNil);
    const timestamp = moment().tz('America/Sao_Paulo');
    const cleanDataAndStatus = {
      ...cleanData,
      timestamp: timestamp.format(),
      queryTimestamp: -1 * parseInt(timestamp.format('x'), 10),
      status: 'processing',
    };
    updates[`/exams/${uid}/${currentAddress}/requests/${pushKey}`] = cleanDataAndStatus;
    if (payload.userId) {
      if (unified.id && unified.address.some((a) => a === currentAddress)) {
        updates[`/unified/${unified.id}/exams/${payload.userId}/finalized/${pushKey}`] = cleanDataAndStatus;
      } else {
        updates[`/users/${uid}/exams/${payload.userId}/finalized/${pushKey}`] = cleanDataAndStatus;
      }
    }
  } else {
    updates[`/exams/${uid}/${currentAddress}/exams/${pushKey}`] = payload;
  }
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(pushKey);
  //     }
  //     return null;
  //   });
  // });
}

export function* exameRequest() {
  yield takeLatest(actions.REQUEST_EXAMS, function* (action) {
    const currentAddress = yield select(getSelectedAddressFromStore);
    try {
      yield put({
        type: actions.REQUEST_EXAMS_WAITING,
        payload: action.payload.requestSecretary ? 'secretary' : 'save',
      });
      const mainUser = yield select(getMainUserFromStore);
      const unified = yield select(getUnifiedTokenStore);
      const pushKey = generatePushKey(currentAddress, mainUser);
      if (action.payload.requestSecretary) {
        yield call(exameRequestOnDB, action.payload, pushKey, currentAddress, unified, mainUser);
      } else {
        yield call(finalizeExameWihtoutSecretary, action.payload, pushKey, currentAddress, unified, mainUser);
        // yield put({ type: actions.EXAMS_HISTORY_REQUEST });
      }
      if (action.payload.pacsweb && action.payload.exam) {
        yield put({
          type: actions.REQUEST_PACSWEB,
          payload: {
            exam: action.payload.exam,
            firstName: action.payload.firstName,
            lastName: action.payload.lastName,
            userId: action.payload.userId,
            userInfo: action.payload.userInfo,
            pushKey,
          },
        });
      }
      yield call(sleep, 500);
      if (action.payload.requestSecretary) {
        yield put({ type: actions.REQUEST_EXAMS_SUCCESS_SECREATARY });
      } else {
        yield put({ type: actions.REQUEST_EXAMS_SUCCESS });
      }
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.REQUEST_EXAMS_ERROR,
        payload: {
          address: currentAddress,
        },
      });
    }
  });
}

function createExameOnDB(payload, currentAddress, mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  const pushKey = push(child(ref(db), `exams/${uid}/${currentAddress}/exams`)).key;
  updates[`/exams/${uid}/${currentAddress}/exams/${pushKey}`] = payload;
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

export function* createExamModel() {
  yield takeLatest(actions.CREATE_EXAMS_REQUEST, function* (action) {
    const currentAddress = yield select(getSelectedAddressFromStore);
    try {
      yield put({ type: actions.EXAMS_FETCH_OR_UPDATING_WAITING });
      const mainUser = yield select(getMainUserFromStore);
      yield call(createExameOnDB, action.payload, currentAddress, mainUser);
      yield call(sleep, 500);
      yield put({ type: actions.CREATE_EXAMS_SUCCESS });
      yield put({ type: actions.EXAMS_FETCH_REQUEST });
      message.success('Modelo criado com sucesso.');
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.CREATE_EXAMS_ERROR,
        payload: {
          address: currentAddress,
        },
      });
    }
  });
}

function saveExameOnDB(value, key, currentAddress, mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  updates[`exams/${uid}/${currentAddress}/exams/${key}`] = value;
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

export function* saveExame() {
  yield takeLatest(actions.SAVE_EXAMS_REQUEST, function* (action) {
    const currentAddress = yield select(getSelectedAddressFromStore);
    try {
      yield put({ type: actions.EXAMS_FETCH_OR_UPDATING_WAITING });
      const { value, key } = action.payload;
      const mainUser = yield select(getMainUserFromStore);
      yield call(saveExameOnDB, value, key, currentAddress, mainUser);
      yield put({
        type: actions.EXAMS_FETCH_REQUEST,
      });
      message.success('Modelo salvo com sucesso.');
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.SAVE_EXAMS_ERROR,
        payload: {
          address: currentAddress,
        },
      });
    }
  });
}

function getExamesHistoryFromDB(seectedId, currentAddress, unified = {}, mainUser, lastItem) {
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const db = getDatabase();
  if (unified.id && unified.address.some((a) => a === currentAddress)) {
    if (lastItem) {
      const databaseRef = ref(db, `unified/${unified.id}/exams/${seectedId}/finalized`);
      return get(dbQuery(
        databaseRef,
        orderByChild('queryTimestamp'),
        startAt(lastItem.queryTimestamp, lastItem.id),
        limitToFirst(6),
      ));
      // return db.ref(`unified/${unified.id}/exams/${seectedId}/finalized`)
      //   .orderByChild('queryTimestamp')
      //   .startAfter(lastItem.queryTimestamp, lastItem.id)
      //   .limitToFirst(5)
      //   .once('value');
    }
    const databaseRef = ref(db, `unified/${unified.id}/exams/${seectedId}/finalized`);
    return get(dbQuery(
      databaseRef,
      orderByChild('queryTimestamp'),
      limitToFirst(5),
    ));
    // return db.ref(`unified/${unified.id}/exams/${seectedId}/finalized`)
    //   .orderByChild('queryTimestamp')
    //   .limitToFirst(5)
    //   .once('value');
  }
  if (lastItem) {
    const databaseRef = ref(db, `users/${uid}/exams/${seectedId}/finalized`);
    return get(dbQuery(
      databaseRef,
      orderByChild('queryTimestamp'),
      startAt(lastItem.queryTimestamp, lastItem.id),
      limitToFirst(6),
    ));
    // return db.ref(`users/${uid}/exams/${seectedId}/finalized`)
    //   .orderByChild('queryTimestamp')
    //   .startAfter(lastItem.queryTimestamp, lastItem.id)
    //   .limitToFirst(5)
    //   .once('value');
  }
  const databaseRef = ref(db, `users/${uid}/exams/${seectedId}/finalized`);
  return get(dbQuery(
    databaseRef,
    orderByChild('queryTimestamp'),
    limitToFirst(5),
  ));
  // return db.ref(`users/${uid}/exams/${seectedId}/finalized`)
  //   .orderByChild('queryTimestamp')
  //   .limitToFirst(5)
  //   .once('value');
}

export function* getExamesHistoryRequest() {
  yield takeLatest(actions.EXAMS_HISTORY_REQUEST, function* (action) {
    let currentAddress = yield select(getSelectedAddressFromStore);
    try {
      yield put({ type: actions.EXAMS_HISTORY_WAITING });
      if (!currentAddress) {
        yield take(appActions.SELECT_ADDRESS);
        currentAddress = yield select(getSelectedAddressFromStore);
      }
      const unified = yield select(getUnifiedTokenStore);
      const seectedId = yield select(getSeectedIdFromStore);
      if (!seectedId) {
        throw new Error('Patient ID not provided.');
      }
      const mainUser = yield select(getMainUserFromStore);
      const lastExamFromStore = yield select(getLastExamFromStore);
      const currentExams = yield select(getExamsFromStore);
      let lastExamPagination = null;
      if (lastExamFromStore[seectedId] && action?.payload?.paginate) {
        lastExamPagination = lastExamFromStore[seectedId];
      }
      const exams = yield call(
        getExamesHistoryFromDB,
        seectedId,
        currentAddress,
        unified,
        mainUser,
        lastExamPagination,
      );
      const examsArray = [];
      exams.forEach((el) => {
        examsArray.push({
          ...el.val(),
          id: el.key,
        });
      });
      const lastExam = examsArray[examsArray.length - 1];

      if (lastExamPagination && currentExams[seectedId]) {
        yield put({
          type: actions.EXAMS_HISTORY_SUCCESS,
          payload: {
            examHistory: [...currentExams[seectedId], ...examsArray],
            id: seectedId,
            lastExam,
            noNextExamPage: examsArray.length < 5,
          },
        });
      } else {
        yield put({
          type: actions.EXAMS_HISTORY_SUCCESS,
          payload: {
            examHistory: examsArray,
            id: seectedId,
            lastExam,
            noNextExamPage: examsArray.length < 5,
          },
        });
      }
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.EXAMS_HISTORY_ERROR,
      });
    }
  });
}

function getExamesFromDB(currentAddress, mainUser) {
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const db = getDatabase();
  const dbRef = ref(db);
  return get(child(dbRef, `exams/${uid}/${currentAddress}/exams`));
}

function getExamesCustomItensFromDB(currentAddress, mainUser) {
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const db = getDatabase();
  const dbRef = ref(db);
  return get(child(dbRef, `exams/${uid}/${currentAddress}/examsCustomItens`));
}

export function* getExamesRequest() {
  yield takeLatest(actions.EXAMS_FETCH_REQUEST, function* () {
    let currentAddress = yield select(getSelectedAddressFromStore);
    try {
      if (!currentAddress) {
        yield take(appActions.SELECT_ADDRESS);
        currentAddress = yield select(getSelectedAddressFromStore);
      }
      const mainUser = yield select(getMainUserFromStore);
      const exams = yield call(getExamesFromDB, currentAddress, mainUser);
      const customExamItens = yield call(getExamesCustomItensFromDB, currentAddress, mainUser);
      const customExamItensVal = customExamItens.val();
      const examsVal = exams.val();
      yield put({
        type: actions.EXAMS_FETCH_SUCCESS,
        payload: {
          exams: {
            [currentAddress]: _.isNull(examsVal) ? {} : examsVal,
          },
          customExamItens: {
            [currentAddress]: _.isNull(customExamItensVal) ? {} : customExamItensVal,
          },
        },
      });
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.EXAMS_FETCH_ERROR,
        payload: {
          address: currentAddress,
        },
      });
    }
  });
}

function removeExamesOnDB(values, currentAddress, mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  values.forEach((key) => {
    updates[`/exams/${uid}/${currentAddress}/exams/${key}`] = null;
  });
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

export function* removeExamesRequest() {
  yield takeLatest(actions.REMOVE_EXAMS_REQUEST, function* (action) {
    const currentAddress = yield select(getSelectedAddressFromStore);
    try {
      yield put({ type: actions.REMOVING_EXAMS });
      const mainUser = yield select(getMainUserFromStore);
      yield call(removeExamesOnDB, action.payload, currentAddress, mainUser);
      yield all([
        put({
          type: actions.EXAMS_FETCH_REQUEST,
        }),
        put({
          type: actions.REMOVE_EXAMS_SUCCESS,
        }),
      ]);
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.REMOVE_EXAMS_ERROR,
        payload: {
          address: currentAddress,
        },
      });
    }
  });
}

function createExamRequestListener(addressUid, mainUser) {
  const db = getDatabase();
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  // const listener = eventChannel((emit) => {
  //   db.ref(`exams/${uid}/${addressUid}/requests`).on('value', (req) => {
  //     emit(req.val() || {});
  //   });
  //   return () => db.ref(`exams/${uid}/${addressUid}/requests`).off();
  // });
  const listener = eventChannel((emit) => {
    const examsRef = ref(db, `exams/${uid}/${addressUid}/requests`);
    const unsubscribe = onValue(examsRef, (req) => (
      emit(req.val() || {})
    ));
    return () => unsubscribe();
  });
  return listener;
}

export function* getExamsListener() {
  yield takeEvery(actions.EXAMS_REQUESTS_LISTENER, function* (action) {
    try {
      const mainUser = yield select(getMainUserFromStore);
      const examsListener = yield call(createExamRequestListener, action.payload, mainUser);
      yield takeEvery(examsListener, function* (examRequests) {
        yield put({
          type: actions.EXAMS_REQUESTS_LISTENER_SUCCESS,
          payload: {
            examRequests,
          },
        });
      });
      yield take(appActions.CANCEL_LISTENERS);
      examsListener.close();
      yield all([
        put({ type: appActions.CANCEL_LISTENERS_SUCCESS }),
        put({ type: actions.RESET_EXAMS }),
      ]);
    } catch (error) {
      console.warn(error);
    }
  });
}

function getIdToken() {
  const auth = getAuth();
  const { currentUser } = auth;
  return currentUser.getIdToken();
}

function requestPacswebOnCloud(payload, pushKey, idToken) {
  const config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };
  const data = {};
  const exames = [];
  if (payload.exam) {
    payload.exam.forEach((el) => {
      const timestamp = moment().tz('America/Sao_Paulo');
      const alphabet = '0123456789abcdefghijklmnopqrstuvwxyz';
      const nanoid = customAlphabet(alphabet, 10);
      const accessionnumber = nanoid();
      const obj = {};
      // obj.accessionnumber = `${pushKey}${el.id}`;
      obj.accessionnumber = accessionnumber;
      obj.examenome = el.terminologia;
      obj.examecodigo = el.codigo;
      obj.modalidade = 'OT';
      obj.pacientenome = `${payload.firstName} ${payload.lastName}`;
      if (payload.userInfo?.birthday) {
        const birthday = getBirthday(payload.userInfo.birthday);
        obj.pacientenascimento = moment(birthday, 'DD/MM/YYYY').format('YYYYMMDD');
      }
      // obj.pacienteid = payload.userId;
      if (payload.userInfo?.cpf) {
        obj.pacienteid = payload.userInfo.cpf;
      }
      if (payload.userInfo?.gender) {
        if (payload.userInfo?.gender === 'male') {
          obj.pacientesexo = 'M';
        }
        if (payload.userInfo?.gender === 'female') {
          obj.pacientesexo = 'F';
        }
      }
      obj.atendimentoid = pushKey;
      obj.atendimentodata = timestamp.format('YYYYMMDD');
      obj.atendimentohora = timestamp.format('HHmm');
      if (payload.userInfo?.requestingDoctor) {
        obj.mediconome = payload.userInfo.requestingDoctor;
      }
      if (payload.userInfo?.requestingDoctorCrm) {
        obj.medicocrm = payload.userInfo.requestingDoctorCrm;
      }
      obj.status = 'I';
      exames.push(obj);
    });
  }
  data.exames = exames;
  const bodyParameters = {
    data,
  };
  // return true;
  return axios.post(
    `${ROOT_URL}/requestPacsweb`,
    bodyParameters,
    config,
  );
}

// function saveAccessionNumber(accessionnumber, userId, currentAddress, unified = {}, mainUser, pushKey) {
//   const db = firebase.database();
//   let uid;
//   if (mainUser) {
//     uid = mainUser;
//   } else {
//     const { currentUser } = firebase.auth();
//     ({ uid } = currentUser);
//   }
//   const updates = {};
//   if (unified.id && unified.address.some((a) => a === currentAddress)) {
//     updates[`/unified/${unified.id}/exams/${userId}/finalized/${pushKey}/accessionnumber`] = accessionnumber;
//   } else {
//     updates[`/users/${uid}/exams/${userId}/finalized/${pushKey}/accessionnumber`] = accessionnumber;
//   }
//   return new Promise((resolve, reject) => {
//     db.ref().update(updates, (error) => {
//       if (error) {
//         reject(new Error(error));
//       } else {
//         resolve(pushKey);
//       }
//       return null;
//     });
//   });
// }

export function* requestPacsweb() {
  yield takeLatest(actions.REQUEST_PACSWEB, function* (action) {
    try {
      notification('info', 'Enviando exame(s) para PACSWEB');
      // const currentAddress = yield select(getSelectedAddressFromStore);
      const idToken = yield call(getIdToken);
      // const mainUser = yield select(getMainUserFromStore);
      // const unified = yield select(getUnifiedTokenStore);
      // const alphabet = '0123456789abcdefghijklmnopqrstuvwxyz';
      // const nanoid = customAlphabet(alphabet, 10);
      // const accessionnumber = nanoid();
      yield call(
        requestPacswebOnCloud,
        action.payload,
        action.payload.pushKey,
        // accessionnumber,
        idToken,
      );
      // yield call(saveAccessionNumber, accessionnumber, action.payload.userId, currentAddress, unified, mainUser, action.payload.pushKey);
      yield call(sleep, 500);
      notification('success', 'Exame(s) enviados para PACSWEB');
    } catch (error) {
      console.warn(error);
      notification('error', 'Algo deu errado ao enviar o(s) exame(s) para PACSWEB');
    }
  });
}

function requesUnimedExams(contact, idToken, profile) {
  const config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };
  const unimed = contact.plansInfo?.find((item) => item.name === 'Unimed');
  if (!unimed) {
    // throw new Error('Paciente não tem convênio com Unimed');
    return {
      data: {
        hidden: true,
      },
    };
  }
  if (!unimed.number || unimed.number.length !== 17) {
    return { error: 'Paciente não tem carteirinha completa Unimed' };
    // throw new Error('Paciente não tem carteirinha completa Unimed');
  }
  const bodyParameters = {
    carteirinha: unimed.number,
    profile,
  };
  return axios.post(
    `${ROOT_URL}/resConsultaDadosClinicos`,
    bodyParameters,
    config,
  );
}

export function* requestUnimedExamsResults() {
  yield takeLatest(actions.REQUEST_UNIMED_EXAMS_RESULTS, function* (action) {
    try {
      const { id } = action.payload;
      yield put({
        type: actions.REQUEST_UNIMED_EXAMS_RESULTS_LOADING,
        payload: {
          id: action.payload.id,
        },
      });
      const idToken = yield call(getIdToken);
      let profile = yield select(getProfileFromStore);
      const mainUser = yield select(getMainUserFromStore);
      const healthProfessional = yield select(getHealthProfessionalFromStore);
      if (_.isEmpty(profile)) {
        yield take(profileActions.PROFILE_INFO_SUCCESS);
        profile = yield select(getProfileFromStore);
      }
      if (mainUser && healthProfessional) {
        const customHealthProfessionalProfile = yield select(getCustomHealthProfessionalProfileFromStore);
        if (customHealthProfessionalProfile) {
          profile = _.cloneDeep(customHealthProfessionalProfile);
        }
      }
      if ((mainUser && !healthProfessional) || profile.council?.type !== 'crm' || !profile.council?.value) {
        yield put({
          type: actions.REQUEST_UNIMED_EXAMS_RESULTS_SUCCESS,
          payload: {
            [id]: {
              data: {
                hidden: true,
              },
            },
            id,
          },
        });
      } else {
        if (!profile.cpf) {
          yield put({
            type: actions.UNIMED_EXAMS_MISSING_CPF,
          });
          return;
        }
        let result;
        for (let i = 0; i <= 2; i += 1) {
          try {
            result = yield call(requesUnimedExams, action.payload, idToken, profile);
            if (result.data && !_.isEmpty(result.data)) {
              break;
            }
          } catch (err) {
            if (i <= 1) {
              yield call(sleep, 1000);
            } else if (i === 2) {
              throw err;
            }
          }
        }

        const data = {
          unimedExamsResults: result.data || [],
          unimedExamsResultsError: {
            errorType: 'generic',
            customMessage: result.error || '',
          },
        };
        if (data.unimedExamsResults) {
          yield put({
            type: actions.REQUEST_UNIMED_EXAMS_RESULTS_SUCCESS,
            payload: {
              [id]: data,
              id,
            },
          });
          if (!data.hidden && data.unimedExamsResults.length > 0) {
            notificationAntd.success({
              message: (
                <>
                  Foram encontrados
                  <strong>{` ${data.unimedExamsResults.length} exame(s) `}</strong>
                  nos bancos de dados da Unimed, visualize os resultados na aba &quot;Exames&quot; do seu paciente
                </>
              ),
              description: (
                <Button
                  type="primary"
                  size="small"
                  style={{
                    marginTop: 10,
                    backgroundColor: '#52c41a',
                    color: 'white',
                    fontWeight: 500,
                  }}
                  onClick={() => {
                    notificationRequestChannel.put({ type: actions.DOCUMENT_UNIMED_BUTTON_PRESSED });
                    notificationAntd.destroy('unimed-exam-result-notification');
                  }}
                >
                  Ver exames
                </Button>
              ),
              duration: 10,
              key: 'unimed-exam-result-notification',
            });
          }
        }
      }
    } catch (error) {
      console.warn(error);
      let errorType = 'generic';
      let customMessage = 'Algo deu errado ao buscar os resultados de exames na Unimed';
      if (error?.response?.data?.error?.message === 'patient.validation.is_not_queryable') {
        customMessage = 'O paciente não está autorizado pela Unimed para busca de exames';
        errorType = 'nonAuthorized';
      }
      if (error?.response?.data?.error?.message === 'Beneficiário não encontrado') {
        customMessage = 'O beneficiário não foi encontrado no banco da Unimed';
        errorType = 'generic';
      }
      yield put({
        type: actions.REQUEST_UNIMED_EXAMS_RESULTS_SUCCESS,
        payload: {
          [action.payload.id]: {
            unimedExamsResults: [],
            unimedExamsResultsError: {
              customMessage,
              errorType,
            },
          },
          id: action.payload.id,
        },
      });
      // notification('error', 'Algo deu errado ao buscar os resultados de exames na Unimed');
    }
  });
}

function requesUnimedDocument(contact, idToken, profile, uriAnexo) {
  const config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };
  const unimed = contact.plansInfo?.find((item) => item.name === 'Unimed');
  if (!unimed.number || unimed.number.length !== 17) {
    return { error: 'Paciente não tem carteirinha completa Unimed' };
    // throw new Error('Paciente não tem carteirinha completa Unimed');
  }
  const bodyParameters = {
    carteirinha: unimed.number,
    profile,
    uri_anexo: uriAnexo,
  };
  return axios.post(
    `${ROOT_URL}/resConsultaAnexoDadosClinicos`,
    bodyParameters,
    config,
  );
}

export function* requestUnimedDocument() {
  yield takeLatest(actions.DOCUMENT_UNIMED_REQUEST, function* (action) {
    try {
      const {
        contact,
        uriAnexo,
      } = action.payload;
      const unimedDocuments = yield select(getUnimedDocuments);
      const idToken = yield call(getIdToken);
      let profile = yield select(getProfileFromStore);
      const mainUser = yield select(getMainUserFromStore);
      const healthProfessional = yield select(getHealthProfessionalFromStore);
      if (_.isEmpty(profile)) {
        yield take(profileActions.PROFILE_INFO_SUCCESS);
        profile = yield select(getProfileFromStore);
      }
      if (mainUser && healthProfessional) {
        const customHealthProfessionalProfile = yield select(getCustomHealthProfessionalProfileFromStore);
        if (customHealthProfessionalProfile) {
          profile = _.cloneDeep(customHealthProfessionalProfile);
        }
      }
      if (_.isUndefined(unimedDocuments[uriAnexo])) {
        yield put({
          type: actions.DOCUMENT_UNIMED_REQUEST_WAITING,
          payload: {
            [uriAnexo]: {
              loading: true,
              type: 'application/pdf',
              base64: true,
            },
          },
        });
        const result = yield call(requesUnimedDocument, contact, idToken, profile, uriAnexo);
        if (result) {
          yield put({
            type: actions.DOCUMENT_UNIMED_REQUEST_SUCCESS,
            payload: {
              [uriAnexo]: {
                ...result.data,
                name: result.data?.dados?.nome_anexo || 'Anexo sem nome',
                type: 'application/pdf',
                uid: uriAnexo,
                base64: `data:application/pdf;base64,${result.data?.dados?.anexo}`,
                loading: false,
              },
            },
          });
        }
      }
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.DOCUMENT_UNIMED_REQUEST_ERROR,
      });
      notification('error', 'Algo deu errado ao baixar o(s) documento(s) da UNIMED');
    }
  });
}

function updateExameCustomItensOnDB(payload, currentAddress, mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  const pushKey = payload.id || push(child(ref(db), `exams/${uid}/${currentAddress}/examsCustomItens`)).key;
  updates[`/exams/${uid}/${currentAddress}/examsCustomItens/${pushKey}`] = payload.delete ? null : { ...payload, id: pushKey };
  return update(dbRef, updates);
}

export function* createExamCustomItens() {
  yield takeLatest(actions.CREATE_CUSTOM_EXAM_ITENS, function* (action) {
    const currentAddress = yield select(getSelectedAddressFromStore);
    try {
      yield put({ type: actions.CREATE_CUSTOM_EXAM_ITENS_WAITING });
      const mainUser = yield select(getMainUserFromStore);
      yield call(updateExameCustomItensOnDB, action.payload, currentAddress, mainUser);
      yield call(sleep, 500);
      yield put({ type: actions.CREATE_CUSTOM_EXAM_ITENS_SUCCESS });
      yield put({ type: actions.EXAMS_FETCH_REQUEST });
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.CREATE_CUSTOM_EXAM_ITENS_ERROR,
      });
    }
  });
}

function duplicateModelOnDB(payload, currentAddress, mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  const pushKey = push(child(ref(db), `exams/${uid}/${currentAddress}/exams`)).key;
  updates[`/exams/${uid}/${currentAddress}/exams/${pushKey}`] = {
    ...payload,
    name: `Cópia - ${payload.name || ''}`,
  };
  return update(dbRef, updates);
}

export function* duplicateModel() {
  yield takeLatest(actions.DUPLICATE_MODEL_REQUEST, function* (action) {
    const currentAddress = yield select(getSelectedAddressFromStore);
    try {
      yield put({ type: actions.DUPLICATE_MODEL_WAITING });
      const mainUser = yield select(getMainUserFromStore);
      yield call(duplicateModelOnDB, action.payload, currentAddress, mainUser);
      yield call(sleep, 500);
      yield put({ type: actions.DUPLICATE_MODEL_SUCCESS });
      yield put({ type: actions.EXAMS_FETCH_REQUEST });
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.DUPLICATE_MODEL_ERROR,
      });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getExamesHistoryRequest),
    fork(getExamsListener),
    fork(finalizeExam),
    fork(getExamesRequest),
    fork(saveExame),
    fork(createExamModel),
    fork(removeExamesRequest),
    fork(exameRequest),
    fork(requestPacsweb),
    fork(requestUnimedExamsResults),
    fork(requestUnimedDocument),
    fork(watchNotificationRequestChannel),
    fork(createExamCustomItens),
    fork(duplicateModel),
  ]);
}
